<script setup lang="ts">
import { ref } from 'vue';
import { RouterView } from 'vue-router';

import UserGroupInfoModal from '@/components/competitive/UserGroupInfoModal.vue';

const displayModal = ref(false);
const openUserGroupInfoModal = () => {
  displayModal.value = true;
};
</script>

<template>
  <UserGroupInfoModal v-model:displayModal="displayModal" />

  <RouterView name="menu" v-slot="{ Component }">
    <component :is="Component" :open-user-group-info-modal="openUserGroupInfoModal" />
  </RouterView>
</template>
