<script setup lang="ts">
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import InfoIcon from '../icons/InfoIcon.vue';
import OnxPaper from '@/components/onx/OnxPaper.vue';
import OnxHeadline from '@/components/onx/typography/OnxHeadline.vue';
import LoaderGrid from '@/components/LoaderGrid.vue';
import DbScalingGrid from '@/components/DbScalingGrid.vue';
import OnxBlur from '@/components/onx/OnxBlur.vue';
import CsvExport from '@/components/CsvExport.vue';
import TooltipWrapper from '@/components/visual/chart/TooltipWrapper.vue';
import ImageExport from '@/components/ImageExport.vue';
import DownloadFileIcon from '@/components/onx/icons/DownloadFileIcon.vue';
import CameraIcon from '@/components/onx/icons/CameraIcon.vue';
import { CustomTooltip } from '@/components/tooltip';

type Props = {
  noData?: boolean;
  loading?: boolean;
  dbScalingUp?: boolean;
  hidden?: boolean;
  metricSource?: string | null;
  enableCsvExport?: boolean;
  enableImageExport?: boolean;
  title: string;
  subtitle?: string;
  chartTitleTooltip?: string;
  screenshotLegend?: Array<{
    rank?: number;
    color: string;
    label: string;
    // TODO: rename y to value
    y?: string | number;
    lci?: number;
    uci?: number;
  }>;
  screenshotTitle?: string;
  screenshotSubtitle?: string;
  showConfidenceIntervals?: boolean;
  imageExportOptions?: Record<string, unknown>;
  csvExportDisabled?: boolean;
  csvExportLoading?: boolean;
};
const props = withDefaults(defineProps<Props>(), {
  enableImageExport: true,
});
const canvasContainerRef = ref<HTMLElement | null>(null);

const route = useRoute();
const productName = computed(() => {
  return route.matched[0].path.substring(1);
});

const showNoData = computed(() => {
  return props.loading === false && props.noData;
});
const showTools = computed(() => {
  return !props.hidden && !props.noData && !props.loading;
});
const screenshotTitles = computed(() => {
  const title: string = props.screenshotTitle || props.title;
  const subtitle: string | undefined = props.screenshotSubtitle || props.subtitle || undefined;

  if (subtitle) {
    return [title, subtitle];
  } else {
    return [title];
  }
});
const legend = computed(() => {
  if (!props.screenshotLegend) {
    return [];
  } else {
    return props.screenshotLegend.map((item) => {
      return {
        rank: item.rank || undefined,
        hex_color: item.color,
        name_mapped: item.label,
        lci: item.lci,
        uci: item.uci,
        y: item.y,
      };
    });
  }
});
</script>

<template>
  <OnxPaper class="onx-chart-container" :class="{ remove_bottom: showTools }">
    <slot name="title" v-if="$slots.title"></slot>
    <template v-else>
      <OnxHeadline as="h3">
        {{ props.title }}
        <CustomTooltip v-if="chartTitleTooltip" :message="chartTitleTooltip" placement="top">
          <InfoIcon class="FieldGroup__tooltipIcon" />
        </CustomTooltip>
      </OnxHeadline>
      <OnxHeadline as="h4" v-if="props.subtitle">{{ props.subtitle }}</OnxHeadline>
    </template>
    <div v-if="showNoData" class="onx-chart-container__no-data">
      <OnxHeadline as="h3">No data available</OnxHeadline>
    </div>
    <div class="onx-chart-container__chart" ref="canvasContainerRef">
      <OnxBlur :hidden="props.hidden">
        <slot v-if="!props.noData"></slot>
      </OnxBlur>
    </div>
    <slot name="bottom-plugins"></slot>
    <DbScalingGrid v-if="props.loading && props.dbScalingUp && !props.hidden" overlay />
    <LoaderGrid v-else-if="props.loading && !props.hidden" overlay />
    <div v-if="showTools" class="onx-chart-container__tools">
      <slot name="tools"></slot>

      <TooltipWrapper title="Export chart as image" v-if="canvasContainerRef && enableImageExport">
        <ImageExport
          v-bind="props.imageExportOptions"
          :filename-before-extension="props.title"
          :canvas-container-node="canvasContainerRef"
          :legend-disabled="false"
          legend-title="Legend"
          :selected-point="legend"
          :product="productName"
          :content="screenshotTitles"
          :confidence-state="showConfidenceIntervals"
        >
          <CameraIcon />
        </ImageExport>
      </TooltipWrapper>
      <CsvExport v-if="props.enableCsvExport" :disabled="csvExportDisabled" @click.stop="$emit('exportToCsv')">
        <DownloadFileIcon :loading="csvExportLoading" :disabled="csvExportDisabled" />
      </CsvExport>
    </div>

    <template v-if="metricSource === 'TLD'">
      <span class="onx-chart-container__metric-source"></span>
    </template>
  </OnxPaper>
</template>

<style lang="scss">
@import 'scss/onx-breakpoints.module';

.onx-chart-container {
  position: relative;
  padding: 16px 8px 8px 8px;
  min-height: 350px;
  height: 100%;
  display: flex;
  flex-direction: column;

  &.remove_bottom {
    padding-bottom: 0;
  }

  > .onx-headline {
    margin-top: 0;
    padding-right: 12px;
  }

  @include laptop {
    padding: 16px;
  }

  &__no-data {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 100%;
    text-align: center;
  }

  &__tools {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin: 0 -16px;
    padding: 8px;
    gap: 8px;
    border-top: 1px solid var(--charcoal-100);
    color: var(--teal-500);
  }

  &__metric-source {
    position: absolute;
    top: 16px;
    right: 16px;
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--teal-600);
  }

  &__chart {
    flex: 1 1 0;
  }

  .csv-export,
  .image-export {
    border: none;
    color: var(--teal-600);
    &:hover {
      color: white;
      background-color: var(--teal-600);
    }
  }
}
</style>
