<script setup lang="ts">
import ThreeSixtyPage from '../ThreeSixtyPage.vue';
import {
  GroupedChartMetricDefinitions,
  chartMetricDefinitionsByPage,
} from '@/chart-metric-definitions/ChartMetricDefinitions';
import ChartManager from '@/chart-metric-definitions/ChartManager.vue';
import OnxSwyftMap from '@/components/onx/swyft-map/OnxSwyftMap.vue';
import useFilters from '@/composables/useFilters';
import useLocations from '@/composables/useLocations';
import useSelectableNetworkOperators from '@/composables/useSelectableNetworkOperators';
import useMapBboxFilterStore from '@/pinia-stores/useMapBboxFilterStore';
import { Dashboards } from '@/constants/dashboards';
import routes from '@/constants/routes';
import { MapEvents } from '@/components/visual/map/MapEvents';

const metrics = chartMetricDefinitionsByPage[routes.ThreeSixtyBaseStationSiteMapper] as GroupedChartMetricDefinitions;
const { aggregation } = useFilters(Dashboards.ThreeSixty);
const { selectedOperators } = useSelectableNetworkOperators(Dashboards.ThreeSixty);
const { locationId } = useLocations(Dashboards.ThreeSixty);

const mapBboxFilterStore = useMapBboxFilterStore();
</script>

<template>
  <ThreeSixtyPage class="onx-360-sitemapper">
    <div class="onx-grid">
      <OnxSwyftMap
        v-if="selectedOperators?.length"
        :key="`${locationId}_${mapBboxFilterStore.resetKey}`"
        title="Cell Site Locations"
        :network-operators="selectedOperators"
        class="onx-col-12 lg:onx-col-8"
        :geohash-level="7"
        map-endpoint="maps/sitemapper"
        :location-id="locationId"
        @[MapEvents.NewBounds]="mapBboxFilterStore.handleNewBounds"
        enable-bbox-filtering
      />
      <template v-for="metric in metrics.withOperators" :key="metric.id!">
        <ChartManager
          :dashboard="Dashboards.ThreeSixty"
          :metric="metric"
          :connection-category="metric.connectionCategory!"
          :location="locationId"
          :geohashes="[]"
          :aggregation="aggregation"
          :operators="selectedOperators"
          :bbox="mapBboxFilterStore.bboxBasedOnUpdateResults"
        />
      </template>
    </div>
    <div class="onx-grid fluid">
      <template v-for="metric in metrics.byOperator" :key="metric.metricSubtype">
        <ChartManager
          :dashboard="Dashboards.ThreeSixty"
          :metric="metric"
          :connection-category="metric.connectionCategory!"
          :location="locationId"
          :geohashes="[]"
          :aggregation="aggregation"
          :operators="selectedOperators"
          :bbox="mapBboxFilterStore.bboxBasedOnUpdateResults"
        />
      </template>
    </div>
  </ThreeSixtyPage>
</template>

<style lang="scss">
@import 'scss/onx-breakpoints.module';

.onx-360-sitemapper {
  & .chart-height {
    height: 400px;
  }

  .coverage-map__map-wrapper {
    height: 400px;

    .coverage-map__map {
      height: 100%;
    }
  }
}
</style>
