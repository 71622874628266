import { NO_DATA_COLOR } from '@/constants/constants';

export const toFixedNoZeroPad = (value: number, decimalPlaces: number) => {
  return Number(value.toFixed(decimalPlaces)).toString();
};

export const fixHexColor = (color: string | undefined): string => {
  if (!color) {
    return NO_DATA_COLOR;
  }

  if (color[0] === '#') {
    return color;
  }

  return `#${color}`;
};
