import { DataTransformerMetricsAPIResponseCallback } from '@/chart-metric-definitions/data-transformers/DataTransformerFnType';
import { MetricStructuresEnum } from '@/types/MetricStructures';
import { BreakdownMetric } from '@/types/MetricStructures';
import { cdnColorMap } from '@/constants/colorScales';
import { Operator } from '@/types/Operator';
import { ChartTrendSeriesInputData } from '@/types/Charts';
import { RankedSimpleMetric } from '@/types/MetricStructures';

const filterByOperatorTransformer = ({
  mainOperator,
}: {
  mainOperator: Operator;
}): DataTransformerMetricsAPIResponseCallback<any, MetricStructuresEnum.Breakdown> => {
  return (response) => {
    const filteredResponse = response.results.filter(
      (d) => mainOperator.canonical_network_id === d.canonical_network_id,
    );
    if (filteredResponse.length === 0) {
      return [];
    }

    const meanKeys = Object.keys(filteredResponse[0].mean); // "google", "akamai", ...
    const values = filteredResponse
      .flatMap((d: BreakdownMetric) => {
        return meanKeys.map((key) => {
          return {
            ...d,
            x: d.date,
            y: d.mean[key],
            lci: d.lci?.[key] || 0,
            uci: d.uci?.[key] || 0,
            label: key,
          };
        });
      })
      .reduce((acc: any, curr: any) => {
        if (!acc.hasOwnProperty(curr.label)) {
          acc[curr.label] = {
            label: curr.label,
            color: (cdnColorMap as any)[curr.label], // get color from operator response
            backgroundColor: 'white',
            data: [curr],
          };
        } else {
          acc[curr.label].data.push(curr);
        }

        return acc;
      }, {});

    return Object.values(values) as ChartTrendSeriesInputData<RankedSimpleMetric>[];
  };
};

export default filterByOperatorTransformer;
