import Base from './base';

export class Maps extends Base {
  constructor(token) {
    super();
    this.token = `Bearer ${token}`;
    this.polygons = 'polygon/';
    this.polygonsByParent = 'polygons_by_parent/';
    this.data = 'overview/geocoding_config/';
    this.dataByParent = 'overview/parent/';
  }

  getPolygons({ country, dashboard = this.defaultDashboard, polygon = 2 }) {
    let params = '';

    if (country) {
      params = `?country=${country}`;
    }

    return this.get(`${this.getApiVersion(dashboard)}${dashboard}/${this.polygons}${polygon}/${params}`, this.token);
  }

  getData({
    agg = '90days',
    altVersion = false,
    country,
    dashboard = this.defaultDashboard,
    date,
    location,
    metric = 'availability_lte',
    polygon = 2,
  }) {
    let params = `?operator_info=yes&metric_list=${metric}&compare_all_intervals=true`;

    if (country) {
      params += `&country=${country}`;
    }

    if (date) {
      params += `&end_date=${date.substr(0, 10)}`;
    }

    if (location) {
      params += `&location_id=${location}`;
    }

    const version = altVersion ? this.v2 : this.v1;
    return this.get(`${version}${dashboard}/${this.data}${polygon}/${agg}/${params}`, this.token);
  }

  getPolygonsByParent(parent, dashboard = this.defaultDashboard) {
    return this.get(`${this.getApiVersion(dashboard)}${dashboard}/${this.polygonsByParent}${parent}/`, this.token);
  }

  getDataByParent({ dashboard = this.defaultDashboard, date, metrics = [], parent }) {
    let params = `?operator_info=yes`;

    if (metrics.length) {
      params += `&metric_list=${metrics.join(',')}`;
    }

    if (date) {
      params += `&end_date=${date.substr(0, 10)}`;
    }

    return this.get(`${this.v1}${dashboard}/${this.dataByParent}${parent}/90days/${params}`, this.token);
  }
}
