import { MaybeRef } from 'vue';

import { usePaginatedDataset, type UsePaginatedDatasetOptions } from '@/composables/usePaginatedDataset';
import { Dashboards } from '@/constants/dashboards';

/**
 * createDatasetsApiQueryFn is a factory function used to prepare the query function for dataset ChartMetricDefinitions.
 * For metrics API, see createMetricsApiQueryFn.
 */
export const createDatasetsApiQueryFn = (
  dashboard: Dashboards,
  dataset: MaybeRef<string>,
  location: MaybeRef<string | number>,
  factoryArgs: Omit<UsePaginatedDatasetOptions, 'endDate'>,
) => {
  return (args = {}) => {
    return usePaginatedDataset(dashboard, dataset, location, {
      ...factoryArgs,
      ...args,
    });
  };
};
