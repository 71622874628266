import { computed, MaybeRef, unref } from 'vue';
import { useQuery } from '@tanstack/vue-query';

import type { PolygonResponse } from '@/types/PolygonResponse';
import osApi from '@/api/osApi';
import { Dashboards } from '@/constants/dashboards';

const usePolygons = (
  dashboard: Dashboards,
  options: { geocoding: MaybeRef<number>; countryIso3?: MaybeRef<string>; enabled?: MaybeRef<boolean> },
) => {
  const { countryIso3, enabled, geocoding } = options;
  const localEnabled = computed(() => {
    const _countryIso3 = unref(countryIso3);
    const _enabled = unref(enabled);

    if (countryIso3 && enabled) {
      return Boolean(_countryIso3) && _enabled;
    }

    if (enabled) {
      return _enabled;
    }

    return true;
  });

  const queryParams = computed(() => {
    return {
      country: unref(countryIso3),
    };
  });

  return useQuery({
    queryKey: ['polygons', geocoding, countryIso3],
    queryFn: () =>
      osApi.get<PolygonResponse>(`${dashboard}/polygon/${unref(geocoding)}/`, {
        params: queryParams.value,
      }),
    staleTime: 24 * 60 * 60 * 1000,
    enabled: localEnabled,
  });
};

export default usePolygons;
