import mapboxgl from 'mapbox-gl';

import osApi from '@/api/osApi';
import { MetricSubtypes } from '@/types/MetricSubtypes';
import { MetricResponse } from '@/types/MetricResponse';
import { MetricStructuresEnum } from '@/types/MetricStructures';

import { Dashboards } from '@/constants/dashboards';
import { type DateIntervalEnum } from '@/types/MetricRequestParameters';

import exportDatasetToCsv from '@/utils/exportDatasetToCsv';

import { toFixedNoZeroPad } from '@/utils/helpers';

const exportSiteOvershootingScoreByENodeBIDToCsv = async (
  _: any,
  title: string,
  {
    bbox,
    dateInterval,
    geohashes,
    location,
    otherRequestParams,
    transform,
  }: {
    location: number;
    otherRequestParams: { period_split: 0 | 1 };
    geohashes?: string[];
    bbox?: mapboxgl.LngLatBounds;
    dateInterval?: DateIntervalEnum;
    transform: (data: any, horizontal?: boolean) => any;
  },
) => {
  const bboxString = bbox ? [bbox.getWest(), bbox.getSouth(), bbox.getEast(), bbox.getNorth()].join(',') : undefined;

  // aggregation isn't relevant to 360 at the time of this writing
  // but it's required by the API
  // therefore we hardcode it to 90days
  const url = `/${Dashboards.ThreeSixty}/metrics/${MetricSubtypes.OvershootingScoreByENodeBIdFull}_overall/${location}/90days/`;

  const response = await osApi.get<MetricResponse<MetricStructuresEnum.OvershootingScoreByENodeBId>>(url, {
    params: {
      period_split: otherRequestParams.period_split,
      geohashes,
      bbox: bboxString,
      date_interval: dateInterval,
    },
  });

  const fullTitle = `${title} - ${otherRequestParams.period_split === 0 ? 'Previous Period' : 'Current Period'}`;

  const transformedData = transform(response.data);

  exportDatasetToCsv({
    headers: ['eNodeB ID', 'Overshooting Score', 'Site Samples', 'Date'],
    getRow: (datum: any, dataset: any, date: string) => [
      datum.enodebid,
      datum.site_overshooting_score,
      toFixedNoZeroPad(datum.site_samples, 2),
      date,
    ],
  })({ datasets: transformedData }, fullTitle);
};

export default exportSiteOvershootingScoreByENodeBIDToCsv;
