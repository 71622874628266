import { computed, unref, MaybeRef } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { format } from 'date-fns';

import type { MetricStructuresEnum } from '@/types/MetricStructures';
import type { GeocodingConfigResponse } from '@/types/GeocodingConfigResponse';
import osApi from '@/api/osApi';
import { Dashboards } from '@/constants/dashboards';
import { AvailableDeploymentType } from '@/focus/deployment-type-selector/availableDeploymentTypes';

type Options = {
  geocoding: MaybeRef<number>;
  metric: MaybeRef<string>; // can be a single metric or a comma separated list of metrics
  aggregation: MaybeRef<string>;
  endDate: MaybeRef<string | Date>;
  countryIso3?: MaybeRef<string>;
  operatorInfo?: MaybeRef<boolean>;
  compareAllIntervals?: MaybeRef<boolean>;
  deploymentType?: MaybeRef<AvailableDeploymentType | null>;
  enabled?: MaybeRef<boolean>;
  nbDays?: MaybeRef<number>;
};

/**
 * Retrieves metric data for polygons described by usePolygons.
 */
const useGeocodingConfig = <T extends MetricStructuresEnum>(dashboard: Dashboards, options: Options) => {
  const { aggregation, compareAllIntervals, countryIso3, enabled, endDate, geocoding, metric, nbDays, operatorInfo } =
    options;

  const localEnabled = computed(() => {
    const metricValue = unref(metric);
    const enabledValue = unref(enabled);

    const hasMetric = Boolean(metricValue);
    if (enabled !== undefined) {
      return enabledValue && hasMetric;
    }

    return hasMetric;
  });

  const queryParams = computed(() => {
    const deploymentType = unref(options.deploymentType);
    const endDateValue = unref(endDate);

    return {
      metric_list: unref(metric),
      aggregation_type: unref(aggregation),
      country: unref(countryIso3),
      operator_info: unref(operatorInfo),
      compare_all_intervals: unref(compareAllIntervals),
      end_date: typeof endDateValue === 'string' ? endDateValue : format(endDateValue, 'yyyy-MM-dd'),
      deployment_type: deploymentType?.value,
      nb_days: unref(nbDays),
    };
  });

  return useQuery({
    queryKey: ['geocoding_config', geocoding, queryParams],
    queryFn: () =>
      osApi.get<GeocodingConfigResponse<T>>(
        `${dashboard}/overview/geocoding_config/${unref(geocoding)}/${unref(aggregation)}/`,
        {
          params: queryParams.value,
        },
      ),
    staleTime: 24 * 60 * 60 * 1000,
    enabled: localEnabled,
  });
};

export default useGeocodingConfig;
