import { computed, unref, MaybeRef } from 'vue';

import { MetricDescriptor } from '@/types/MetricDescriptor';
import { RankedSimpleMetric } from '@/types/MetricStructures';

const useMetricMinMax = (metric: MaybeRef<MetricDescriptor>, data: MaybeRef<Array<RankedSimpleMetric>>) => {
  const minMax = computed(() => {
    const _metric = unref(metric);
    const metricUnit = _metric.units?.short;

    const defaultMinMax = {
      min: 0,
      max: 100,
    };

    if (!metricUnit) {
      return defaultMinMax;
    }

    const variableMinMaxMetrics = ['speed', 'responsiveness'];
    const isVariableMetricCategory = variableMinMaxMetrics.includes(_metric.category);

    if (isVariableMetricCategory) {
      const _data = unref(data);

      return {
        min: 0,
        max: Math.round((Math.max(..._data.map((a) => a.uci)) * 1.1) / 10) * 10 + 5,
      };
    }

    if (metricUnit === '0 - 10') {
      return { min: 0, max: 10 };
    }
    if (metricUnit === '100 - 1000') {
      return { min: 0, max: 1000 };
    }

    return defaultMinMax;
  });

  return {
    metricMinMax: minMax,
  };
};

export default useMetricMinMax;
