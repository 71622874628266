<script setup lang="ts">
import { computed } from 'vue';

import type { ListDropdownItems } from '@/types/metrics-selector/ListDropdownItems';
import type { MetricDescriptor } from '@/types/MetricDescriptor';

import CheckmarkIcon from '@/components/onx/icons/CheckmarkIcon.vue';
import OnxPaper from '@/components/onx/OnxPaper.vue';
import OnxIconPlaceholder from '@/components/onx/icons/OnxIconPlaceholder.vue';
import ChevronRightIcon from '@/components/onx/icons/ChevronRightIcon.vue';
import OnxButton from '@/components/onx/OnxButton.vue';
import { OnxList, OnxListItem, OnxListItemText, OnxListSubheader } from '@/components/onx/OnxList';
import useBreakpoints from '@/components/onx/composables/responsive/useBreakpoints';

export type Props = {
  selectedMetricLabel?: string;
  listDropdownItemCategories: string[];
  listDropdownItems: ListDropdownItems;
  disabled?: boolean;
};

const emit = defineEmits<{
  (event: 'list-item-click', metric: MetricDescriptor): void;
}>();

const props = defineProps<Props>();
const matches = useBreakpoints();

const computedSelectedMetricLabel = computed(() => {
  if (props.selectedMetricLabel) {
    return props.selectedMetricLabel;
  }

  let selectedMenuItem = null;

  for (const category of props.listDropdownItemCategories) {
    selectedMenuItem = props.listDropdownItems[category].list.find((item) => item.active);

    if (selectedMenuItem) {
      break;
    }
  }

  return selectedMenuItem?.label;
});
</script>

<template>
  <VDropdown
    :distance="6"
    :placement="matches.mobile.value && !matches.laptop.value ? 'bottom' : 'right-start'"
    :popper-hide-triggers="(triggers: string[]) => [...triggers, 'click']"
    :auto-size="!matches.laptop.value"
    auto-boundary-max-size
  >
    <OnxButton size="sm" class="onx-metrics-selector__metrics-button" :disabled="disabled">
      <span class="onx-metrics-selector__metrics-button__label">{{
        disabled ? 'Not Applicable' : computedSelectedMetricLabel
      }}</span>
      <ChevronRightIcon small class="onx-metrics-selector__metrics-button__icon" />
    </OnxButton>

    <template #popper>
      <OnxPaper :depth="3">
        <OnxList>
          <template v-for="category in listDropdownItemCategories" :key="category">
            <OnxListSubheader v-if="listDropdownItems[category].title && listDropdownItems[category].list.length">
              {{ listDropdownItems[category].title }}
            </OnxListSubheader>
            <OnxListItem
              v-for="item in listDropdownItems[category].list"
              :key="item.value"
              as="button"
              :inset="!!item.inset"
              :disabled="!!item.disabled"
              @click="emit('list-item-click', item.value)"
            >
              <template #left>
                <CheckmarkIcon v-if="item.active" />
                <OnxIconPlaceholder v-else />
              </template>
              <OnxListItemText
                class="onx-metrics-selector__label"
                :class="{ 'onx-metrics-selector__label--highlighted': item.highlighted }"
              >
                {{ item.label }}
              </OnxListItemText>
            </OnxListItem>
          </template>
        </OnxList>
      </OnxPaper>
    </template>
  </VDropdown>
</template>

<style lang="scss">
@import 'scss/onx-breakpoints.module';

.onx-metrics-selector {
  &__metrics-button {
    width: 100%;
    padding: 6px 8px;
    border-radius: 4px;

    &__label {
      text-align: left;
    }

    &__icon {
      margin-left: 4px;
      transition: transition();
      transform: rotate(90deg);

      [data-popper-shown] & {
        transform: rotate(-90deg);
      }

      @include laptop {
        transform: rotate(0) !important;
      }
    }
  }

  &__label {
    &--highlighted {
      font-weight: 600;
    }
  }
}
</style>
