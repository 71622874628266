<script setup lang="ts">
import { computed, Ref } from 'vue';

import type { MetricDescriptor } from '@/types/MetricDescriptor';

import { FieldGroup } from '@/components/menu';
import OnxRadioButton from '@/components/onx/OnxRadioButton.vue';
import InfoIcon from '@/components/onx/icons/InfoIcon.vue';
import { DEFAULT_USER_GROUP } from '@/constants/constants';
import getSpotlightUserGroups from '@/components/specialized/getSpotlightUserGroups';
import useSpotlightQueryParams from '@/components/specialized/useSpotlightQueryParams';
import useAnalytics from '@/composables/useAnalytics';

type Props = {
  openUserGroupInfoModal: () => void;
  metrics: MetricDescriptor[];
  connectionCategory?: Ref<string>;
};

const props = defineProps<Props>();

const { track } = useAnalytics();
const queryParams = useSpotlightQueryParams({
  userGroup: DEFAULT_USER_GROUP,
});

const emit = defineEmits({
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  change: (newUserGroup: string) => true,
});

const userGroups = computed(() => {
  return getSpotlightUserGroups(props.metrics, props.connectionCategory?.value);
});

const onUserGroupChange = (value: string) => {
  queryParams.userGroup.onChange(value);
  emit('change', value);

  track('user group change', { newUserGroup: value });
};
</script>

<template>
  <FieldGroup label="User Groups" v-if="userGroups.length">
    <template #tooltip>
      <InfoIcon class="FieldGroup__tooltipIcon" @click="openUserGroupInfoModal()" />
    </template>
    <OnxRadioButton
      v-for="userGroup in userGroups"
      name="user-groups-radio"
      :key="userGroup.value"
      :model-value="queryParams.userGroup.selectedValue.value"
      @update:model-value="onUserGroupChange"
      :value="userGroup.value"
      :label="userGroup.label"
    />
  </FieldGroup>
</template>
