import mp from 'mixpanel-browser';
import { useRoute } from 'vue-router';

import useUser from '@/components/onx/composables/useUser';
import auth0 from '@/auth0';

const useAnalytics = () => {
  const { user } = useUser();
  const route = useRoute();

  const track = (event: string, properties: Record<string, unknown> = {}) => {
    if (!user.value) {
      return;
    }

    const payload = {
      username: user.value.id,
      organization: user.value.organization,
      path: route.fullPath,
      email: auth0.user.value.email,
      ...properties,
    };

    if (import.meta.env.VITE_ENV_LOCATION !== 'prod') {
      console.log('[Analytics]', event, payload);
    }

    mp.track(event, payload);
    const dataLayer = (window as any).dataLayer || [];
    function gtag(...args: any[]) {
      dataLayer.push(args);
    }
    gtag('event', event, payload);
  };

  return { track };
};

export default useAnalytics;
