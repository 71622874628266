<script setup lang="ts">
import { toRefs } from 'vue';

import type { BinnedCdnMetric } from '@/types/MetricStructures';
import OnxDistChart from '@/components/onx/charts/OnxDistChart.vue';
import IntersectionObserver from '@/components/IntersectionObserver.vue';
import useAvailableCDNsByMetricStructure from '@/composables/useAvailableCDNsByMetricStructure';
import { TitlePlaceholders, usePlaceholderTitle } from '@/composables/usePlaceholderTitle';
import { OnxChartBaseProps } from '@/components/onx/charts/OnxChartBaseProps';

export type Props = OnxChartBaseProps & {
  geohashes: string[];
  titleReplacements: TitlePlaceholders;
};

const props = defineProps<Props>();
const {
  aggregation,
  chartSubtitle,
  chartTitle,
  chartTitleTooltip,
  deploymentType,
  endDate,
  geohashes,
  location,
  metric,
} = toRefs(props);

const query = props.queryFn({ nbDays: 0, endDate });
const {
  query: { data: response, status },
} = query;

const { availableCDNs } = useAvailableCDNsByMetricStructure(metric, response, status);

const filterByCdn = (endpoint: string) => {
  return (data: BinnedCdnMetric[]) => {
    return data.map((op: BinnedCdnMetric) => {
      return {
        ...op,
        frequency:
          op.cdn.find((cdnData) => {
            return cdnData.key.toLowerCase() === endpoint;
          })?.frequency || [],
      };
    });
  };
};
const replaceCDNInTitle = (cdn: string) => {
  const { titleWithoutPlaceholders } = usePlaceholderTitle(chartTitle, {
    ...props.titleReplacements,
    cdn,
    deploymentType: deploymentType.value,
  });
  return titleWithoutPlaceholders.value;
};
</script>

<template>
  <template v-for="cdn in availableCDNs" :key="cdn">
    <IntersectionObserver>
      <template #default="{ visible }">
        <OnxDistChart
          :dashboard="dashboard"
          :metric="metric"
          :location="location"
          :aggregation="aggregation"
          :end-date="endDate"
          :geohashes="geohashes"
          :enabled="visible"
          :transform="filterByCdn(cdn)"
          :chart-title="replaceCDNInTitle(cdn)"
          :chart-subtitle="chartSubtitle"
          :chart-title-tooltip="chartTitleTooltip"
          :screenshot-title="screenshotTitle"
          :screenshot-subtitle="screenshotSubtitle"
          show-cumulative-line
          :deployment-type="deploymentType"
          :query-fn="queryFn"
        />
      </template>
    </IntersectionObserver>
  </template>
</template>

<style lang="scss"></style>
