import { AxiosResponse } from 'axios';
import { computed, ComputedRef, Ref } from 'vue';
import { format } from 'sql-formatter';
import { MetricResponse } from '@/types/MetricResponse';
import { PaginatedMetricResponse } from '@/types/PaginatedMetricResponse';

export type MetricSQL = {
  sql?: ComputedRef<string | undefined>;
};

export const useMetricSQL = (
  response: Ref<AxiosResponse<MetricResponse<any> | PaginatedMetricResponse<any>>> | Ref<undefined>,
  isSuccess: Ref<boolean>,
): MetricSQL => {
  const sql = computed<string | undefined>(() => {
    if (!isSuccess.value || !response.value) {
      return undefined;
    }
    if (!response.value.data.sql) {
      return undefined;
    }

    try {
      return format(response.value.data.sql, {
        language: 'postgresql',
      });
    } catch (e) {
      console.error(`Error parsing SQL: ${e}`);
      return undefined;
    }
  });

  return {
    sql,
  };
};
