import type {
  MetricsAPIDataTransformerConstructor,
  DataTransformerFnOptions,
} from '@/chart-metric-definitions/data-transformers/DataTransformerFnType';
import type { MetricStructuresEnum } from '@/types/MetricStructures';
import { cdnColorMap } from '@/constants/colorScales';
import { CDNS } from '@/constants/constants';

const transformCdnIpDownload360Trend: MetricsAPIDataTransformerConstructor<
  MetricStructuresEnum.CdnIp,
  Required<Pick<DataTransformerFnOptions, 'mainOperator'>>
> =
  ({ mainOperator }) =>
  (response) => {
    const dataFilteredByMainOperator = response.results.filter((datum) => {
      return datum.canonical_network_id === mainOperator.canonical_network_id;
    });

    const allIps = Object.values(
      dataFilteredByMainOperator.reduce(
        (acc, datum) => {
          const cdns = Object.keys(datum.mean);
          const ips = cdns.flatMap((cdn) =>
            Object.keys(datum.mean[cdn]).map((ip) => ({
              ip,
              cdn,
            })),
          );

          ips.forEach((ip) => {
            if (!acc[ip.ip]) {
              acc[ip.ip] = ip;
            }
          });

          return acc;
        },
        {} as Record<string, { ip: string; cdn: string }>,
      ),
    );
    const ipsByCdn = allIps.reduce(
      (acc, ip) => {
        if (!acc[ip.cdn]) {
          acc[ip.cdn] = [];
        }

        acc[ip.cdn].push(ip.ip);
        return acc;
      },
      {} as Record<string, string[]>,
    );

    const data = dataFilteredByMainOperator.flatMap((datum) => {
      const cdns = Object.keys(datum.mean);

      return cdns.flatMap((cdn) => {
        const ips = Object.keys(datum.mean[cdn]);
        const difference = ipsByCdn[cdn].filter((ip) => !ips.includes(ip));

        return [...ips, ...difference].map((ip) => {
          return {
            ...datum,
            x: datum.date,
            y: datum.mean[cdn]?.[ip] ?? null,
            label: ip,
            cdn,
            ip,
          };
        });
      });
    });

    if (data.length === 0) {
      return [];
    }

    const dataGroupedByIp = data.reduce(
      (acc, item) => {
        if (!acc[item.label]) {
          const color = cdnColorMap[item.cdn as keyof typeof cdnColorMap];
          acc[item.label] = {
            label: item.label,
            color,
            backgroundColor: 'white',
            data: [item],
            type: 'line',
            meta: {
              imageExportLegend: {
                color,
                label: `${item.ip} (${CDNS[item.cdn as keyof typeof CDNS]})`,
              },
            },
          };

          return acc;
        }

        acc[item.label].data.push(item);
        return acc;
      },
      {} as { [ip: string]: any },
    );

    return Object.values(dataGroupedByIp);
  };

export default transformCdnIpDownload360Trend;
