import axios, { AxiosError } from 'axios';

/**
 * When the swyft db is scaling up, the backend will return a 503 error
 *  with `grace_period` variable in a json payload, e.g. `{...., grace_period: 10000, .....}`
 */

type DbScalingUpError = AxiosError<{
  grace_period: number;
}>;

export const isDbScalingUpError = (error: Error | null) => {
  if (axios.isAxiosError(error) && error?.response?.status == 503 && error?.response?.data?.grace_period > 0) {
    return true;
  }
  return false;
};

export const getDbScalingRetry = (failureCount: number, error: Error) => {
  if (isDbScalingUpError(error)) {
    return true;
  }

  return failureCount < 1;
};

export const getDbScalingRetryDelay = (retryAttempt: number, error: Error) => {
  if (isDbScalingUpError(error)) {
    const dbScalingError = error as DbScalingUpError;
    const gracePeriod = dbScalingError.response?.data?.grace_period;
    if (gracePeriod) {
      return gracePeriod;
    }
  }

  return Math.min(1000 * 2 ** retryAttempt, 30000);
};
