import coreHealthByCdnOperatorChartMetricDefinitions from './focus/coreHealthByOperatorCdnChartMetricDefinitions';
import { ChartMetricDefinition } from '@/chart-metric-definitions/MetricDefinition';
import Routes from '@/constants/routes';
import qoeDetailsChartMetricDefinitions from '@/chart-metric-definitions/focus/qoeDetailsChartMetricDefinitions';
import coreHealthByCdnChartMetricDefinitions from '@/chart-metric-definitions/focus/coreHealthByCdnChartMetricDefinitions';
import onx360NetworkCoverageChartMetricDefinitions from '@/chart-metric-definitions/360/onx360NetworkCoverageChartMetricDefinitions';
import {
  onx360NetworkCoverageAvailabilityMetricDefinitions,
  onx360NetworkCoverageAvailability5GMetricDefinitions,
} from '@/chart-metric-definitions/360/onx360NetworkCoverageAvailabilityMetricDefinitions';
import onx360CoreEndpointIPSpeedMetricDefinitions from '@/chart-metric-definitions/360/onx360CoreEndpointIPSpeedMetricDefinitions';
import onx360CoreEndpointIPGamesAndVoiceMetricDefinitions from '@/chart-metric-definitions/360/onx360CoreEndpointIPGamesAndVoiceMetricDefinitions';
import onx360CoreEndpointIPVideoLiveMetricDefinitions from '@/chart-metric-definitions/360/onx360CoreEndpointIPVideoLiveMetricDefinitions';
import onx360CoreEndpointIPVideoABRMetricDefinitions from '@/chart-metric-definitions/360/onx360CoreEndpointIPVideoABRMetricDefinitions';
import onx360CoreEndpointIPEcqCcqMetricDefinitions from '@/chart-metric-definitions/360/onx360CoreEndpointIPEcqCcqMetricDefinitions';
import onx360BaseStationSiteMapperChartMetricDefinitions from '@/chart-metric-definitions/360/onx360BaseStationSiteMapperChartMetricDefinitions';
import onx360BaseStationOvershootingChartMetricDefinitions from '@/chart-metric-definitions/360/onx360BaseStationOvershootingChartMetricDefinitions';
import onx360BaseStationCongestionChartMetricDefinitions from '@/chart-metric-definitions/360/onx360BaseStationCongestionChartMetricDefinitions';

export type GroupedChartMetricDefinitions = {
  [key: string]: ChartMetricDefinition[];
};

export const chartMetricDefinitionsByPage: any = {
  [Routes.FocusQoEDetails]: qoeDetailsChartMetricDefinitions,
  [Routes.FocusCoreHealthByCDN]: coreHealthByCdnChartMetricDefinitions,
  [Routes.FocusCoreHealthByOperatorCDN]: coreHealthByCdnOperatorChartMetricDefinitions,

  [Routes.ThreeSixtyCoverageNetworkCoverage]: onx360NetworkCoverageChartMetricDefinitions,
  [Routes.ThreeSixtyCoverageAvailability]: onx360NetworkCoverageAvailabilityMetricDefinitions,
  [Routes.ThreeSixtyCoverageAvailability5G]: onx360NetworkCoverageAvailability5GMetricDefinitions,

  [Routes.ThreeSixtyCoreEndpointIPSpeed]: onx360CoreEndpointIPSpeedMetricDefinitions,
  [Routes.ThreeSixtyCoreEndpointIPGamesAndVoice]: onx360CoreEndpointIPGamesAndVoiceMetricDefinitions,
  [Routes.ThreeSixtyCoreEndpointIPVideoLive]: onx360CoreEndpointIPVideoLiveMetricDefinitions,
  [Routes.ThreeSixtyCoreEndpointIPVideoABR]: onx360CoreEndpointIPVideoABRMetricDefinitions,
  [Routes.ThreeSixtyCoreEndpointIPEcqCcq]: onx360CoreEndpointIPEcqCcqMetricDefinitions,

  [Routes.ThreeSixtyBaseStationSiteMapper]: onx360BaseStationSiteMapperChartMetricDefinitions,
  [Routes.ThreeSixtyBaseStationOvershooting]: onx360BaseStationOvershootingChartMetricDefinitions,
  [Routes.ThreeSixtyBaseStationCellCongestion]: onx360BaseStationCongestionChartMetricDefinitions,
};

function checkMinimumChartMetricDefinitionProperties(context: { [key: string]: any }) {
  return (
    (context.hasOwnProperty('metricSubtype') || context.hasOwnProperty('dataset')) &&
    context.hasOwnProperty('chartType')
  );
}

export function isChartMetricDefinitionList(
  context: GroupedChartMetricDefinitions | ChartMetricDefinition[],
): context is ChartMetricDefinition[] {
  return Array.isArray(context) && context.length > 0 && checkMinimumChartMetricDefinitionProperties(context[0]);
}

export function isChartMetricDefinitionsObject(
  context: GroupedChartMetricDefinitions | ChartMetricDefinition[],
): context is GroupedChartMetricDefinitions {
  const firstKey = Object.keys(context)[0];

  if (context && typeof context === 'object' && !Array.isArray(context)) {
    return context.hasOwnProperty(firstKey) && checkMinimumChartMetricDefinitionProperties(context[firstKey][0]);
  }

  return false;
}
