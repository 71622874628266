import { CoreEndpointDatasetRow } from './commonColumns';
import { DatasetTransformFilterArgs } from '@/chart-metric-definitions/data-transformers/datasetTransformFilterArgs';

export const commonDatasetTransformer = (params: DatasetTransformFilterArgs) => {
  return (rows: any[]) => {
    return rows.map((row): CoreEndpointDatasetRow => {
      const operator = params.operators.find((operator) => operator.canonical_network_id === row.canonical_network_id);

      if (operator) {
        return {
          ...row,
          operator_name: operator.name_mapped,
        };
      } else {
        return row;
      }
    });
  };
};
