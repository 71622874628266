import { watch, MaybeRef, unref } from 'vue';

import type { FeatureCollection } from 'geojson';
import type { GeoJSONSource } from 'mapbox-gl';

import { NO_DATA_COLOR, PolygonFillsLayerName, PolygonSourceName } from '@/constants/constants';

const useMapPolygonLayer = () => {
  const setupPolygonLayer = (map: mapboxgl.Map, geoJson: MaybeRef<FeatureCollection>) => {
    map.addSource(PolygonSourceName, {
      type: 'geojson',
      data: unref(geoJson),
    });

    map.addLayer({
      id: PolygonFillsLayerName,
      type: 'fill',
      source: PolygonSourceName,
      layout: {},
      paint: {
        'fill-color': [
          'case',
          ['all', ['==', ['get', 'geohash'], true], ['==', ['get', 'selected'], true]],
          '#66D4D0',
          ['==', ['get', 'color'], null],
          NO_DATA_COLOR,
          ['get', 'color'],
        ],
      },
    });

    map.setPaintProperty(PolygonFillsLayerName, 'fill-opacity', [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      0.9,
      ['boolean', ['feature-state', 'selected'], false],
      0.9,
      0.5,
    ]);

    map.addLayer({
      id: 'polygon-borders',
      type: 'line',
      source: PolygonSourceName,
      layout: {},
      paint: {
        'line-color': '#004247',
        'line-width': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          3,
          ['boolean', ['feature-state', 'selected'], false],
          2,
          1,
        ],
      },
    });

    watch(geoJson, (newGeoJson) => {
      const source = map.getSource<GeoJSONSource>(PolygonSourceName);

      if (!source) {
        return;
      }

      source.setData(unref(newGeoJson));
    });
  };

  return { setupPolygonLayer, PolygonFillsLayerName, PolygonSourceName };
};

export default useMapPolygonLayer;
