import { computed } from 'vue';
import { useRoute } from 'vue-router';

import useCheckIfMetricSubtypeOrDatasetExists from '@/composables/useCheckIfMetricSubtypeOrDatasetExists';
import { SelectableMetrics } from '@/types/metrics-selector/SelectableMetrics';
import getAvailableChartMetricsForSelector from '@/focus/metric-selector/getAvailableChartMetricsForSelector';

const useAvailableChartMetricsForSelector = () => {
  const route = useRoute();
  const { checkIfMetricSubtypeOrDatasetExists } = useCheckIfMetricSubtypeOrDatasetExists();

  return computed(() => {
    const metricSubtype = route.query.metricSubtype as SelectableMetrics;
    return getAvailableChartMetricsForSelector(
      metricSubtype,
      route.name as string,
      checkIfMetricSubtypeOrDatasetExists,
    );
  });
};

export default useAvailableChartMetricsForSelector;
