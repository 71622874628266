<script setup lang="ts">
import { computed } from 'vue';
import { formatNumber } from '@/utils/format';

type PaginationControl = {
  /**
   * Starts at 1. It can handle 0 for situations where there are no
   * pages or not enough data, such as when data is still loading or
   * there is an error.
   */
  page: number;

  /**
   * Max page is inclusive. maxPage = 10 => there are ten pages, from 1 to 10.
   * If 0 is passed, it renders a disabled paginator with only the first page,
   * for cases where there is no data.
   */
  maxPage: number;

  totalCount: number;

  /** All pagination controls delegate to gotoPage */
  gotoPage?: (page: number) => void;

  /** Disables all buttons and styles the component accordingly */
  disabled?: boolean;
};

const maxNumberOfPageButtons = 6;
const props = defineProps<PaginationControl>();

const hasFirst = computed(() => props.page > 1 && !props.disabled);
const hasPrevious = computed(() => props.page > 1 && !props.disabled);
const hasNext = computed(() => props.page < props.maxPage && !props.disabled);
const hasLast = computed(() => props.page < props.maxPage && !props.disabled);
const hasLeftEllipses = computed(() => props.page > 3);
const hasRightEllipses = computed(() => props.page < props.maxPage - 4);

const gotoFirst = () => {
  if (hasFirst.value && props.gotoPage) {
    props.gotoPage(1);
  }
};

const gotoPrevious = () => {
  if (hasPrevious.value && props.gotoPage) {
    props.gotoPage(props.page - 1);
  }
};

const gotoNext = () => {
  if (hasNext.value && props.gotoPage) {
    props.gotoPage(props.page + 1);
  }
};

const gotoLast = () => {
  if (hasLast.value && props.gotoPage) {
    props.gotoPage(props.maxPage);
  }
};

// generate the buttons for the paginator
// I'm told there should be 6 buttons: 2 before the current, three after,
// unless we're at either end of the list. Some examples:
// [1] 2 3 4 5 6 ...
// 1 [2] 3 4 5 6 ...
// 1 2 [3] 4 5 6 ...
// ... 2 3 [4] 5 6 7 ...
// ... 4 5 [6] 7 8 9 ...
// ... 5 6 7 8 [9] 10
// ... 5 6 7 8 9 [10]
const pageButtons = computed(() => {
  const buttons = [];
  let start;
  let end;
  if (props.maxPage < maxNumberOfPageButtons) {
    start = 1;
    end = props.maxPage;
  } else {
    // there are enough pages that we might need some ellipses
    // the start of the window should be the current page - 2
    start = Math.max(1, props.page - 2);

    // the end of the window should be the start + maxWindowSize, unless that's too big
    end = Math.min(start + maxNumberOfPageButtons, props.maxPage);

    // if the end of the window is the max page, the start of the window should be the max page - maxWindowSize
    start = Math.max(1, end - maxNumberOfPageButtons);
  }
  for (let i = start; i <= end; i++) {
    if (i === props.page) {
      buttons.push({
        number: i,
        isActive: true,
        onClick: undefined,
      });
    } else {
      buttons.push({
        number: i,
        isActive: false,
        onClick: () => {
          if (props.gotoPage) {
            props.gotoPage(i);
          }
        },
      });
    }
  }
  return buttons;
});
</script>
<template>
  <div class="onx-paginator">
    <button :disabled="!hasFirst" class="onx-paginator__button" @click.stop="gotoFirst" title="Go to first page">
      « First
    </button>
    <button :disabled="!hasPrevious" class="onx-paginator__button" @click="gotoPrevious" title="Go to previous page">
      ‹ Previous
    </button>
    <div class="onx-paginator__ellipses" :class="{ hidden: !hasLeftEllipses }">...</div>
    <template v-for="button in pageButtons" :key="`${button.number}-${button.isActive}`">
      <button
        :title="`Go to page ${button.number}`"
        :class="{ 'onx-paginator__button': true, active: button.isActive }"
        :disabled="disabled"
        @click="button.onClick"
      >
        {{ formatNumber(button.number) }}
      </button>
    </template>
    <button
      v-if="pageButtons.length === 0"
      :title="`Go to page 1`"
      class="onx-paginator__button active"
      :disabled="true"
    >
      {{ 1 }}
    </button>
    <div class="onx-paginator__ellipses" :class="{ hidden: !hasRightEllipses }">...</div>
    <button :disabled="!hasNext" class="onx-paginator__button" @click="gotoNext" title="Go to next page">Next ›</button>
    <button :disabled="!hasLast" class="onx-paginator__button" @click="gotoLast" title="Go to last page">Last »</button>
  </div>
</template>
<style scoped="true" lang="scss">
.onx-paginator {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;

  > .onx-paginator__button {
    background: none;
    border-radius: 4px;
    border: none;
    color: var(--onx-btn-secondary-text-color);
    cursor: pointer;
    font-size: 0.75rem;
    min-width: 1.5rem;
    padding: 4px 6px;
    text-align: center;
    user-select: none;
    transition:
      background-color 0.1s ease-out,
      color 0.2s ease-out;

    &:hover,
    &.active {
      background-color: var(--onx-btn-primary-bg-color);
      color: white;
    }

    &:disabled {
      cursor: not-allowed;
      color: var(--onx-btn-primary-disabled-text-color);
      background: none;
    }

    &:disabled.active {
      background-color: var(--onx-btn-primary-disabled-bg-color);
    }
  }

  > .onx-paginator__ellipses.hidden {
    visibility: hidden;
  }
}
</style>
