import { type ChartData } from 'chart.js';

import type {
  MetricsAPIDataTransformerConstructor,
  DataTransformerFnOptions,
} from '@/chart-metric-definitions/data-transformers/DataTransformerFnType';
import type { MetricStructuresEnum } from '@/types/MetricStructures';
import { getDatumValue } from '@/utils/viewHelpers';

const transformSimpleStructurePerOperatorForGaugeChart: (
  gaugeSegmentValues: number[],
) => MetricsAPIDataTransformerConstructor<
  MetricStructuresEnum.RankedSimple | MetricStructuresEnum.PercentageSimple,
  Required<Pick<DataTransformerFnOptions, 'mainOperator'>>,
  ChartData<'doughnut'> | null
> =
  (gaugeSegmentValues) =>
  ({ mainOperator }) =>
  (response) => {
    const datum = response.results.find((datum) => datum.canonical_network_id === mainOperator.canonical_network_id);

    if (!datum) {
      return null;
    }

    const defaultRed = getComputedStyle(document.documentElement).getPropertyValue('--error-500');
    const defaultYellow = getComputedStyle(document.documentElement).getPropertyValue('--marigold-500');
    const defaultGreen = getComputedStyle(document.documentElement).getPropertyValue('--success-300');

    return {
      datasets: [
        {
          data: gaugeSegmentValues,
          backgroundColor: [defaultRed, defaultYellow, defaultGreen],
          value: getDatumValue(datum),
        },
      ],
    };
  };

export default transformSimpleStructurePerOperatorForGaugeChart;
