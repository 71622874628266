import { useQuery } from '@tanstack/vue-query';
import osApi from '@/api/osApi';

export type SpotlightPulse = {
  report_date: string;
  metric_subtype: string;
  connectivity_type: string;

  /** Country ID */
  location_id: number;
  country_iso3: string;
  canonical_network_id: number;
  aggregation_type: string;
  city_contribution_locations: number[];
  region_contribution_locations: number[];
  reason: string;
  is_read: boolean;
};

/**
 * Used to GET spotlight pulse information
 * Example URL: https://platform-test.opensignal.com/api/api/v2/spotlight/spotlight_pulses
 * See response format above
 */
const useSpotlightPulse = () => {
  const url = `/spotlight/spotlight_pulses`;

  const data = useQuery({
    queryKey: ['spotlight_pulses'],
    queryFn: async () => osApi.get<SpotlightPulse[]>(url),
    staleTime: 1 * 60 * 60, // 1 hour
    refetchOnWindowFocus: false,
    retry: 1,
  });

  const markOneRead = async (item: SpotlightPulse) => {
    if (item.is_read) {
      return;
    }

    return osApi.post('spotlight/spotlight_pulses/update_state/', item);
  };

  const markMultipleRead = async (items: SpotlightPulse[]) => {
    const itemsToMark = items.filter((item) => !item.is_read);
    if (itemsToMark.length === 0) {
      return;
    }

    return osApi.post('spotlight/spotlight_pulses/update_state/', itemsToMark);
  };

  return {
    ...data,
    markOneRead,
    markMultipleRead,
  };
};

export default useSpotlightPulse;
