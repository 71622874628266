import { getTooltipState } from '@/utils/charts';

const scorePerformanceDriversTooltip =
  ({ horizontal = false }: { horizontal?: boolean }) =>
  (context: any, tooltip: any, chartData: any) => {
    const dataAxis = horizontal ? 'x' : 'y';
    const tt = context.tooltip;
    const tooltipState = getTooltipState(context.chart, tt);

    const currentDataset = chartData.value.datasets[chartData.value.labels.indexOf(tt.title[0])];
    const thresholdDataset = chartData.value.datasets.find((dataset: any) => dataset.type === 'line');

    tooltip.value = {
      ...tooltip.value,
      ...tooltipState,
      datapoints: [
        {
          ...currentDataset,
          label: currentDataset.tooltipLabel,
          value: currentDataset.data[0][dataAxis],
        },
        {
          ...thresholdDataset,
          value: thresholdDataset.data[0],
        },
      ],
      title: tt.title[0],
    };
  };

export default scorePerformanceDriversTooltip;
