import useMetric, { type UseMetricArgs } from '@/composables/useMetric';
import { Dashboards } from '@/constants/dashboards';

type Args = Omit<UseMetricArgs, 'nbDays' | 'dateInterval' | 'otherRequestParams' | 'endDate'>;

/**
 * createMetricsApiQueryFn is a factory function used to prepare the query function for dataset ChartMetricDefinitions.
 * For datasets API, see createDatasetsApiQueryFn.
 */
export const createMetricsApiQueryFn = (dashboard: Dashboards, factoryArgs: Args) => {
  return (args = {}) => {
    return useMetric(dashboard, {
      ...factoryArgs,
      ...args,
    });
  };
};
