import capitalize from 'lodash/capitalize';

import type { MetricDescriptor } from '@/types/MetricDescriptor';

import menuItem from '@/utils/menuItem';

const identity = <T>(x: T | undefined | null): x is T => x != undefined;

const findMetricBySelectedMetricType = (metrics: MetricDescriptor[], selectedMetric: MetricDescriptor) => {
  return metrics.find((metric) => metric.key === selectedMetric.key) || metrics[0];
};

const createMenuListItem = (
  metrics: MetricDescriptor[],
  selectedMetric: MetricDescriptor,
  name: string,
  findMetric: (
    metrics: MetricDescriptor[],
    selectedMetric: MetricDescriptor,
  ) => MetricDescriptor | undefined = findMetricBySelectedMetricType,
) => {
  if (!metrics) {
    return null;
  }

  const correspondingMetric = findMetric(metrics, selectedMetric);

  if (!correspondingMetric) {
    return null;
  }

  return menuItem({
    label: name || correspondingMetric.name,
    value: correspondingMetric,
    active: correspondingMetric.key === selectedMetric.key,
  });
};

const include = (condition: boolean, menuListItem: ReturnType<typeof createMenuListItem>) => {
  if (condition) {
    return menuListItem;
  }
};

/*
 *  metrics {Object}  {[], []} Object of arrays of metrics grouped by subtype
 *  @return {Array}  [{}, {}] array of objects for configuring the menu
 */
export function menuListItems(
  metrics: Record<string, MetricDescriptor[]>,
  selectedMetric: MetricDescriptor,
  exclude: string[] = [],
) {
  return {
    overall: {
      title: 'Overall',
      list: [
        include(
          !exclude.includes('videoexperience'),
          createMenuListItem(metrics.videoexperience, selectedMetric, 'Video Experience'),
        ),
        include(
          !exclude.includes('videoliveexperience'),
          createMenuListItem(metrics.videoliveexperience, selectedMetric, 'Live Video Experience'),
        ),
        include(
          !exclude.includes('gamesexperience'),
          createMenuListItem(metrics.gamesexperience, selectedMetric, 'Games Experience'),
        ),
        include(
          !exclude.includes('voiceexperience'),
          createMenuListItem(metrics.voiceexperience, selectedMetric, 'Voice Experience'),
        ),
        include(
          !exclude.includes('download'),
          createMenuListItem(metrics.download, selectedMetric, 'Download Speed Experience'),
        ),
        include(
          !exclude.includes('upload'),
          createMenuListItem(metrics.upload, selectedMetric, 'Upload Speed Experience'),
        ),
      ].filter(identity),
    },
    consistency: {
      title: 'Consistency',
      list: [
        include(
          !exclude.includes('ecq'),
          createMenuListItem(metrics.ecq, selectedMetric, 'Excellent Consistent Quality'),
        ),
        include(!exclude.includes('ccq'), createMenuListItem(metrics.ccq, selectedMetric, 'Core Consistent Quality')),
        include(
          !exclude.includes('consistentquality'),
          createMenuListItem(metrics.consistentquality, selectedMetric, 'Consistent Quality'),
        ),
      ].filter(identity),
    },
    other: {
      title: 'Other',
      list: [
        include(
          !exclude.includes('extent'),
          createMenuListItem(
            metrics.extent,
            selectedMetric,
            'Reach',
            () => metrics.extent.find((metric) => metric.type === selectedMetric.type) || metrics.extent[0],
          ),
        ),
        include(
          !exclude.includes('onxcoveragesim'),
          createMenuListItem(metrics.onxcoveragesim, selectedMetric, 'Coverage'),
        ),
        include(
          !exclude.includes('availability'),
          createMenuListItem(
            (metrics.availability || []).filter((m) => m.type !== 'nosignal' && m.type !== 'wifi'),
            selectedMetric,
            'Availability',
          ),
        ),
        include(
          !exclude.includes('reliability'),
          createMenuListItem(metrics.reliability, selectedMetric, 'Reliability'),
        ),
        include(
          !exclude.includes('availability'),
          createMenuListItem(metrics.availability, selectedMetric, 'No Signal Availability', () =>
            metrics.availability.find((metric) => metric.type === 'nosignal'),
          ),
        ),
        include(
          !exclude.includes('availability'),
          createMenuListItem(metrics.availability, selectedMetric, 'Time on WiFi', () =>
            metrics.availability.find((metric) => metric.type === 'wifi'),
          ),
        ),
      ].filter(identity),
    },
  };
}

export function geocodingListItems(geocodings: any[], selectedMetricId: number) {
  const displayGeocoding = geocodings.find((l) => l.id === selectedMetricId);
  return [
    {
      label: capitalize(displayGeocoding.name),
      children: geocodings
        .filter((l) => l.id !== '1')
        .map((g) => ({
          label: capitalize(g.name),
          active: g.id === selectedMetricId,
          value: g.id,
        })),
    },
  ];
}
