type MetricBase = {
  canonical_network_id: number;
  location: number;
  date: string;
  previous?: {
    '7days'?: MetricBase;
    '30days'?: MetricBase;
    '90days'?: MetricBase;
    lpr?: MetricBase;
  };
  rank?: number;
};

export type CompareToKeys = '7days' | '30days' | '90days' | 'lpr';

type DatasetDatapoint<TDatapoint extends Record<string, unknown>> = {
  location: number;
  canonical_network_id: number;
  date: string;
} & TDatapoint;

export type RankedSimpleMetric = MetricBase & {
  mean: number;
  lci: number;
  uci: number;
  rank: number;
};

export type SimpleCountsMetric = MetricBase & {
  counts: number;
};

export type CountsByBandMetric = MetricBase & {
  counts: { [band: string]: number };
};

export type ConfidenceCounts = MetricBase & {
  counts: { [ci: string]: number };
};

// mean: { "google": { "<ip>": 20 } }
export type CdnIpMetricData = {
  mean: { [cdn: string]: { [ip: string]: number } };
  counts: { [cdn: string]: { [ip: string]: number } };
  percentage: { [cdn: string]: { [ip: string]: number } };
};

export type CdnIpMetric = MetricBase & CdnIpMetricData;

// mean: { "google": { "<ip>": 20 } }
export type CdnIpWithMinMetric = MetricBase & {
  mean: { [cdn: string]: { [ip: string]: number } };
  min: { [cdn: string]: { [ip: string]: number } };
  counts: { [cdn: string]: { [ip: string]: number } };
  percentage: { [cdn: string]: { [ip: string]: number } };
};

// mean: { "google": { "<ip>": { "1080": 20 } } }
export type CdnIpVideoTimeOnResMetric = MetricBase & {
  mean: { [cdn: string]: { [ip: string]: { [res: string]: number } } };
  counts: { [cdn: string]: { [ip: string]: number } };
  percentage: { [cdn: string]: { [ip: string]: number } };
};

export type BreakdownMetric = MetricBase & {
  mean: { [key: string]: number };
  lci: { [key: string]: number };
  uci: { [key: string]: number };
  rank?: number;
};

export type PercentageBreakdownMetric = MetricBase & {
  percentage: { [key: string]: number };
  lci: { [key: string]: number };
  uci: { [key: string]: number };
  rank?: number;
};

export type BinnedCdnMetric = MetricBase & {
  cdn: { key: string; frequency: { key: string; value: number }[] }[];
};

export type BinnedMetric = MetricBase & {
  frequency: { key: string; value: number }[];
};

export type PercentageSimpleMetric = MetricBase & {
  percentage: number;
  lci: number;
  uci: number;
  rank: number;
};

type ResolutionBreakdown = {
  '360': number;
  '480': number;
  '720': number;
  '1080': number;
  low: number;
  high: number;
};

export type ResolutionBreakdownMetric = MetricBase & {
  mean: { [key: string]: ResolutionBreakdown };
  lci: { [key: string]: ResolutionBreakdown };
  uci: { [key: string]: ResolutionBreakdown };
};

type DownloadThroughputFailureBreakdown = {
  connection_config_error: number;
  dns_error: number;
  error: number;
  invalid_byte_count: number;
  read_exception: number;
  remote_connection_failure: number;
  socket_open_failure: number;
  ssl_init_failure: number;
  timeout: number;
  timeout_connecting_to_remote: number;
};

export type DownloadThroughputFailureBreakdownMetric = MetricBase & {
  counts: DownloadThroughputFailureBreakdown;
  percentage: DownloadThroughputFailureBreakdown;
};

type ServerResponseTestFailureBreakdown = {
  dns_error: number;
  error: number;
  read_packet_error: number;
  read_packet_timeout: number;
  send_packet_error: number;
  timeout: number;
  socket_open_failure: number;
};

export type ServerResponseTestFailureBreakdownMetric = MetricBase & {
  counts: ServerResponseTestFailureBreakdown;
  percentage: ServerResponseTestFailureBreakdown;
};

export type CountsBreakdownMetric = MetricBase & {
  counts: {
    [key: string]: number;
  };
  percentage: {
    [key: string]: number;
  };
};

export type CoverageGeohashCountsByConnectionCategory = CountsBreakdownMetric;

export type OvershootingScoreByENodeBId = MetricBase & {
  enodebid: number;
  site_overshooting_score: number;
  site_samples: number;
};

export type OvershootingScoreForTable = MetricBase & {
  enodebid: number;
  connection_band: string;
  max_score: number;
  site_overshooting_score: number;
  site_samples: number;
};

export type CongestionUnderutilizedCells = MetricBase & {
  connection_enodebid: number;
  connection_cid: number;
  capacity_score: number | null;
  connection_category: string;
  download_throughput: number | null;
  upload_throughput: number | null;
  signal_strength: number | null;
  packet_loss_and_discard: number | null;
};

export type CongestionMostCongestedCells = MetricBase & {
  connection_enodebid: number;
  connection_cid: number;
  connection_category: string;
  congestion_score: number;
  downlink_score: number;
  uplink_score: number;
  dl_ul_delta: number;
  download_throughput: number;
  upload_throughput: number;
  signal_strength: number;
  packet_loss_and_discard: number;
};

export type CongestionAllCells = MetricBase & {
  connection_enodebid: number;
  connection_cid: number;
  connection_category: string;
  congestion_score: number;
  downlink_score: number;
  uplink_score: number;
  dl_ul_delta: number;
  download_throughput: number;
  upload_throughput: number;
  signal_strength: number;
  packet_loss_and_discard: number;
};

export enum MetricStructuresEnum {
  BandCounts = 'band_counts',
  Binnedcdn = 'binnedcdn',
  Breakdown = 'breakdown',
  CountsBreakdownMetric = 'counts_breakdown',
  CdnIp = 'cdn_ip',
  CdnIpVideoTimeOnRes = 'cdn_ip_video_time_on_res',
  CdnIpWithMin = 'cdn_ip_with_min',
  ConfidenceCounts = 'confidence_counts',
  CountsSimple = 'counts_simple',
  CoverageGeohashCountsByCategory = 'coverage_geohash_counts_by_connection_category',
  DownloadThroughputFailureBreakdown = 'download_throughput_failure_breakdown',
  MedianSimple = 'median_simple',
  PercentageBreakdown = 'percentage_breakdown',
  PercentageSimple = 'percentage_simple',
  RankedSimple = 'ranked_simple',
  ResolutionBreakdown = 'resolution_breakdown',
  ServerResponseTestFailureBreakdown = 'server_response_test_failure_breakdown',
  OvershootingScoreByENodeBId = 'overshooting_score_by_enodebid',
  OvershootingScoreForTable = 'overshooting_score_for_table',
  CongestionUnderutilizedCells = 'congestion_underutilized_cells',
  CongestionMostCongestedCells = 'congestion_most_congested_cells',
  CongestionAllCells = 'congestion_all_cells',
}

export type MetricStructures = {
  band_counts: CountsByBandMetric;
  binnedcdn: BinnedCdnMetric;
  breakdown: BreakdownMetric;
  counts_breakdown: CountsBreakdownMetric;
  cdn_ip_video_time_on_res: CdnIpVideoTimeOnResMetric;
  cdn_ip_with_min: CdnIpWithMinMetric;
  cdn_ip: CdnIpMetric;
  confidence_counts: ConfidenceCounts;
  counts_simple: SimpleCountsMetric;
  coverage_geohash_counts_by_connection_category: CoverageGeohashCountsByConnectionCategory;
  download_throughput_failure_breakdown: DownloadThroughputFailureBreakdownMetric;
  median_simple: never;
  percentage_breakdown: PercentageBreakdownMetric;
  percentage_simple: PercentageSimpleMetric;
  ranked_simple: RankedSimpleMetric;
  resolution_breakdown: ResolutionBreakdownMetric;
  server_response_test_failure_breakdown: ServerResponseTestFailureBreakdownMetric;
  overshooting_score_by_enodebid: OvershootingScoreByENodeBId;
  overshooting_score_for_table: OvershootingScoreForTable;
  congestion_underutilized_cells: CongestionUnderutilizedCells;
  congestion_most_congested_cells: CongestionMostCongestedCells;
  congestion_all_cells: CongestionAllCells;
};

export type EcqScorePerformanceDrivers = DatasetDatapoint<{
  ecq: number;
  est_ecq_download_met: number;
  est_ecq_upload_met: number;
  est_ecq_latency_met: number;
  est_ecq_icmp_latency_met: number;
  est_ecq_jitter_met: number;
  est_ecq_packet_loss_met: number;
  est_ecq_ttff_met: number;
}>;

export type CcqScorePerformanceDrivers = DatasetDatapoint<{
  ccq: number;
  est_ccq_download_met: number;
  est_ccq_upload_met: number;
  est_ccq_latency_met: number;
  est_ccq_icmp_latency_met: number;
  est_ccq_jitter_met: number;
  est_ccq_packet_loss_met: number;
  est_ccq_ttff_met: number;
}>;

export enum DatasetsAPIDataStructuresEnum {
  EcqScorePerformanceDrivers = 'ecq_score_performance_drivers',
  CcqScorePerformanceDrivers = 'ccq_score_performance_drivers',
}

export type DatasetsAPIDataStructures = {
  ecq_score_performance_drivers: EcqScorePerformanceDrivers;
  ccq_score_performance_drivers: CcqScorePerformanceDrivers;
};
