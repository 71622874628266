import {
  MetricsAPIDataTransformerConstructor,
  DataTransformerFnOptions,
  DataTransformerReturnedItem,
} from '@/chart-metric-definitions/data-transformers/DataTransformerFnType';
import { METRIC_STRUCTURE_TO_VALUE_FIELD_MAP } from '@/constants/constants';
import { getCdnBars } from '@/utils/viewHelpers';

const cdnBarSeriesTransform: MetricsAPIDataTransformerConstructor<
  any,
  Required<Pick<DataTransformerFnOptions, 'operators' | 'mainOperator'>>,
  { datasets: DataTransformerReturnedItem[]; labels: string[] }
> =
  ({ operators }) =>
  (response, horizontal, { cdns } = {}) => {
    const dataProperty = horizontal ? 'x' : 'y';
    const operatorProperty = horizontal ? 'y' : 'x';

    if (!response?.operators) {
      return { datasets: [], labels: [] };
    }

    const filteredOperators = Object.values(response.operators).filter((operator) => {
      if (operators.length === 0) {
        return true;
      }

      return operators.map((operator) => operator.canonical_network_id).includes(operator.canonical_network_id);
    });

    const accessor =
      METRIC_STRUCTURE_TO_VALUE_FIELD_MAP[response.structure as keyof typeof METRIC_STRUCTURE_TO_VALUE_FIELD_MAP];

    const groupedBars = getCdnBars(
      filteredOperators,
      response.end_date,
      response.results,
      cdns,
      accessor,
      dataProperty,
      operatorProperty,
    );

    return groupedBars;
  };

export default cdnBarSeriesTransform;
