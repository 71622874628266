// TODO move to filters - they are filters
import includes from 'lodash/includes';

const metricKeyToUnit = (metricKey) => {
  const m = (metricKey || '').toLowerCase();
  if (includes(m, 'latency')) {
    return 'ms';
  } else if (includes(m, 'availability')) {
    return '%';
  } else if (includes(m, 'download') || includes(m, 'upload')) {
    return 'Mbps';
  } else {
    return '';
  }
};

const commaNumberFormat = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 2,
});
/**
 * @returns a number as a string with commas breaking up every 3 digits
 */
const formatNumber = (n) => {
  return commaNumberFormat.format(n);
};

export { metricKeyToUnit, formatNumber };
