<script setup lang="ts">
import { computed, inject, type Ref, type Component } from 'vue';

export type Props = {
  id?: string | number;
  disabled?: boolean;
  value?: any;
  as?: string | Component;
};

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  value: null,
  as: 'div',
});

const selectedTab = inject<Ref<string>>('tabs:selectedTab');
const small = inject<Ref<boolean>>('tabs:small');
const largerActiveTab = inject<Ref<boolean>>('tabs:largerActiveTab');
const grow = inject<Ref<boolean>>('tabs:grow');

const classes = computed(() => {
  const selected = selectedTab?.value === props.id;

  return {
    'onx-tabs__tab': true,
    'onx-tabs__tab--disabled': props.disabled,
    'onx-tabs__tab--small': small?.value,
    'onx-tabs__tab--grow': grow?.value,
    'onx-tabs__tab--active': selected,
    'onx-tabs__tab--active--large': selected && largerActiveTab?.value,
  };
});

type OnTabChangeFn = (id?: string | number, value?: any) => void;
const injectedOnTabChange = inject<OnTabChangeFn>('tabs:onTabChange');
const onTabChange: OnTabChangeFn = (id, value) => {
  if (typeof injectedOnTabChange === 'function') {
    injectedOnTabChange(id, value);
    return;
  }

  console.warn('No onTabChange function provided');
};
</script>

<template>
  <component :is="as" :class="classes" @click="onTabChange(id, value)">
    <slot></slot>
  </component>
</template>

<style lang="scss">
@import 'scss/mixins';

.onx-tabs__tab {
  color: var(--onx-tab-default-color);
  border-bottom: 1px solid var(--onx-tab-default-border-color);
  cursor: pointer;
  white-space: nowrap;
  background: var(--white);

  height: 38px;
  padding: 12px 16px;
  font-size: 14px;
  text-align: center;

  &--active {
    color: var(--onx-tab-active-color);
    border-bottom: 2px solid var(--onx-tab-active-color);

    &--large {
      padding-left: 10%;
      padding-right: 10%;
    }
  }

  &:hover {
    background-color: var(--onx-tab-hover-bg-color);
  }

  &--disabled {
    color: var(--onx-tab-disabled-color);
  }

  &--small {
    height: 28px;
    font-size: 12px;
  }

  &--grow {
    flex-grow: 1;
  }

  transition: transition(background-color);
}
</style>
