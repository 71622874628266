import { computed } from 'vue';
import { useQuery } from '@tanstack/vue-query';

import type { DashboardInfoResponse, ThreeSixtyDashboardInfoResponse } from '@/types/DashboardInfoResponse';
import { Dashboards } from '@/constants/dashboards';
import osApi from '@/api/osApi';

type Responses = {
  [Dashboards.Focus]: DashboardInfoResponse;
  [Dashboards.Spotlight]: DashboardInfoResponse;
  [Dashboards.ThreeSixty]: ThreeSixtyDashboardInfoResponse;
};

const useDashboardInfo = <T extends Dashboards>(dashboard: T) => {
  const query = useQuery({
    queryKey: ['dashboardInfo', dashboard],
    queryFn: () => osApi.get<Responses[T]>(`/${dashboard}/info/`),
    staleTime: 24 * 60 * 60 * 1000, // 24 hours
  });

  const homeNetworks = computed(() => query.data.value?.data.home_networks || []);

  return {
    ...query,
    homeNetworks,
  };
};

export default useDashboardInfo;
