import { USER_GROUPS } from '@/constants/constants';
import { MetricDescriptor } from '@/types/MetricDescriptor';

type UserGroup = (typeof USER_GROUPS)[number];

const getSpotlightUserGroups = (
  metrics: MetricDescriptor[],
  selectedConnectionCategory?: string,
  selectedMetric?: MetricDescriptor,
) => {
  const isGroupAvailable = (group: UserGroup) => {
    return metrics.some((m) => {
      const targetAndMetricUserGroupsMatch = m.subcategory === group.value;

      if (!selectedConnectionCategory) {
        return targetAndMetricUserGroupsMatch;
      }

      return targetAndMetricUserGroupsMatch && m.type === selectedConnectionCategory;
    });
  };

  return USER_GROUPS.filter(isGroupAvailable).map((group) => {
    return {
      ...group,
      active: group.value === selectedMetric?.subcategory,
    };
  });
};

export default getSpotlightUserGroups;
