<script setup lang="ts">
import CloseIcon from '../onx/icons/CloseIcon.vue';

defineProps({
  displayModal: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:displayModal']);

function confirm() {
  emit('update:displayModal', false);
}
</script>

<template>
  <div v-show="displayModal" class="modal">
    <div class="modal__content">
      <div class="modal__title">Operator Type</div>
      <p style="margin-bottom: 0">Operator Types:</p>

      <ul>
        <li><strong>MNO:</strong> Mobile Network Operator.</li>
        <li><strong>MVNO:</strong> Mobile Virtual Network Operator and Operator sub-brands.</li>
      </ul>

      <div class="modal__close" @click="confirm()">
        <CloseIcon />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use 'scss/variables.module' as *;

.modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
  background: rgba(80, 100, 130, 0.5);
}

.modal__content {
  box-shadow: 0px 28px 56px 0px rgba(var(--charcoal-500), 0.12);
  width: 525px;
  max-width: 80%;
  max-height: 80%;
  overflow: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  background: #fff;
  border-radius: 2px;
  padding: 64px 48px;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  font-size: 14px;
  line-height: 160%;
  color: var(--charcoal-400);

  @media screen and (max-width: $ci-breakpoint-tablet) {
    max-width: 90%;
    padding: 20px;
    max-height: calc(100vh - 40px);
  }

  ul {
    margin: 0;
    padding: 0 0 0 20px;
  }

  p {
    margin: 0 0 1em;
  }

  strong {
    color: var(--dark-teal-400);
  }
}

.modal__title {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: var(--charcoal-500);
  margin-bottom: 16px;
}

.modal__close {
  position: absolute;
  top: 22px;
  right: 22px;
  cursor: pointer;
  color: var(--charcoal-300);
}
</style>
