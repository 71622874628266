import { computed } from 'vue';
import useDashboardInfo from '@/composables/useDashboardInfo';
import { MetricStructures, MetricStructuresEnum } from '@/types/MetricStructures';
import { fixHexColor } from '@/utils/helpers';
import useCurrentDashboardName from '@/composables/useCurrentDashboardName';
import { PolygonsWithMetricsType } from '@/composables/usePolygonsWithMetrics';

export const useGeoJsonWithColors = (polygonsWithMetrics: PolygonsWithMetricsType) => {
  const currentDashboardName = useCurrentDashboardName();
  const { data: dashboardInfoResponse, homeNetworks } = useDashboardInfo(currentDashboardName.value);

  const homeNetworkColorsById = computed(() => {
    if (!dashboardInfoResponse.value) {
      return null;
    }

    return homeNetworks.value.reduce(
      (acc, network) => {
        acc[network.canonical_network_id] = fixHexColor(network.hex_color);
        return acc;
      },
      {} as Record<number, string>,
    );
  });

  const geoJsonWithColors = computed(() => {
    if (!polygonsWithMetrics.value) {
      return null;
    }

    const homeNetworkIds = homeNetworks.value.map((n) => n.canonical_network_id);
    return {
      type: polygonsWithMetrics.value.type,
      bbox: polygonsWithMetrics.value.bbox,
      features: polygonsWithMetrics.value.features.map((feature) => {
        if (!feature.properties || !homeNetworkColorsById.value) {
          return feature;
        }

        const featureData = feature.properties.data as MetricStructures[MetricStructuresEnum.RankedSimple][];
        const homeNetworkFeatureData = featureData.find((d) => homeNetworkIds.includes(d.canonical_network_id));

        if (!homeNetworkFeatureData) {
          return feature;
        }

        const color = homeNetworkColorsById.value[homeNetworkFeatureData.canonical_network_id];
        return {
          ...feature,
          properties: {
            ...feature.properties,
            homeNetworkData: homeNetworkFeatureData,
            color,
          },
        };
      }),
    };
  });

  return {
    geoJsonWithColors,
  };
};
