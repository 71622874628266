import { DatasetTransformerFactory, DatasetTransformFilterArgs } from './datasetTransformFilterArgs';

export type CongestionUnderutilizedCellsRow = {
  operatorName: string;
  enodebid: number;
  cellId: number;
  connectionCategory: string;
  capacityScore: number | null;
  downloadThroughput: number | null;
  uploadThroughput: number | null;
  signalStrength: number | null;
  packetLossAndDiscard: number | null;
};

export const prepareCongestionUnderutilizedCellsForTable: DatasetTransformerFactory<CongestionUnderutilizedCellsRow> = (
  filters: DatasetTransformFilterArgs,
) => {
  return (rows: any[]): CongestionUnderutilizedCellsRow[] => {
    const { operators } = filters;
    const canonical_network_ids = operators.map((operator) => operator.canonical_network_id);
    return rows
      .filter((row: any) => canonical_network_ids.includes(row.canonical_network_id))
      .map((row: any) => ({
        operatorName: operators.find((operator) => operator.canonical_network_id === row.canonical_network_id)!
          .name_mapped,
        enodebid: row.connection_enodebid,
        cellId: row.connection_cid,
        connectionCategory: row.connection_category,
        capacityScore: row.capacity_score,
        downloadThroughput: row.download_throughput,
        uploadThroughput: row.upload_throughput,
        signalStrength: row.signal_strength,
        packetLossAndDiscard: row.packet_loss_and_discard,
      }));
  };
};
