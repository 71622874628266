import type {
  MetricsAPIDataTransformerConstructor,
  DataTransformerFnOptions,
} from '@/chart-metric-definitions/data-transformers/DataTransformerFnType';
import type { MetricStructuresEnum } from '@/types/MetricStructures';
import { videoResColorMap } from '@/constants/colorScales';
import { CDN_LABELS } from '@/constants/constants';

/**
 * Assuming nb_days=0 (i.e only one day of data)
 */
const transformCdnIpVideoTimeOnRes360ByIPByOperator: MetricsAPIDataTransformerConstructor<
  MetricStructuresEnum.CdnIpVideoTimeOnRes,
  Required<Pick<DataTransformerFnOptions, 'mainOperator'>>
> =
  ({ mainOperator }) =>
  (response, horizontal?: boolean) => {
    if (!mainOperator) {
      return [];
    }

    const dataAxis = horizontal ? 'x' : 'y';
    const labelAxis = horizontal ? 'y' : 'x';

    const dataFilteredByMainOperator = response.results.filter((datum) => {
      return datum.canonical_network_id === mainOperator.canonical_network_id;
    });

    if (dataFilteredByMainOperator.length === 0) {
      return [];
    }

    const ipsByCdn = dataFilteredByMainOperator[0].mean;
    const ips = Object.keys(ipsByCdn).flatMap((cdn) => {
      const ipData = Object.entries(ipsByCdn[cdn]);
      return ipData.map(([ip, resolutions]) => ({
        ip,
        cdn,
        resolutions,
      }));
    });
    const resolutionPercentagesByIP = Object.assign({}, ...Object.values(dataFilteredByMainOperator[0].mean)) as {
      [key: string]: { [key: string]: number };
    };

    const resolutions = Object.keys(Object.values(resolutionPercentagesByIP)[0]);

    return resolutions.map((resolution) => {
      const color = (videoResColorMap as any)[resolution];

      return {
        data: ips.map((ipDatum) => {
          return {
            ...ipDatum,
            [dataAxis]: ipDatum.resolutions[resolution],
            [labelAxis]: `${ipDatum.ip} (${CDN_LABELS[ipDatum.cdn as keyof typeof CDN_LABELS]})`,
            operator: mainOperator,
            resolution,
          };
        }),
        type: 'bar',
        backgroundColor: color,
        color,
        label: resolution,
        yAxisID: 'y',
        xAxisID: 'x',
        dataAxis,
        labelAxis,
        meta: {
          imageExportLegend: {
            color,
            label: resolution,
          },
        },
      };
    });
  };

export default transformCdnIpVideoTimeOnRes360ByIPByOperator;
