<script setup lang="ts">
import { onMounted, onUpdated, ref } from 'vue';
import { useActions } from 'vuex-composition-helpers';
import { useRouter } from 'vue-router';
import LoaderGrid from '@/components/LoaderGrid.vue';
import PerformanceLayout from '@/components/performance/PerformanceLayout.vue';
import { Dashboards } from '@/constants/dashboards';
import useUser from '@/components/onx/composables/useUser';
import ThreeSixtyBreadcrumbs from '@/360/ThreeSixtyBreadcrumbs.vue';
import OnxSidebar from '@/components/onx/OnxSidebar.vue';
import ThreeSixtyMenu from '@/360/ThreeSixtyMenu.vue';
import useBreakpoints from '@/components/onx/composables/responsive/useBreakpoints';
import PossibleConnectionCategoriesProvider from '@/connection-categories-provider/PossibleConnectionCategoriesProvider.vue';
import allPossible360ConnectionCategories from '@/360/constants/allPossible360ConnectionCategories';
import use360TabAvailability from '@/360/use360TabAvailability';
import useAnalytics from '@/composables/useAnalytics';

const matches = useBreakpoints();
const { setUser, setUserSettings, user } = useUser();
const { checkDisclaimer } = useActions(['checkDisclaimer']);
const { track } = useAnalytics();

const ready = ref(false);
const router = useRouter();
const { firstAvailableTabSubtabForAll, tabAvailability } = use360TabAvailability();

onMounted(async () => {
  if (!user.value.id) {
    const res = await setUser();

    if (res) {
      checkDisclaimer();
    }
  }

  await setUserSettings(Dashboards.ThreeSixty);

  if (!tabAvailability.value[router.currentRoute.value.name as string]) {
    await router.push({
      ...router.currentRoute.value,
      name: firstAvailableTabSubtabForAll.value,
    });

    ready.value = true;
  } else {
    ready.value = true;
  }

  track('onx360');
});

onUpdated(async () => {
  if (!tabAvailability.value[router.currentRoute.value.name as string]) {
    await router.push({
      name: firstAvailableTabSubtabForAll.value,
    });
  }
});
</script>

<template>
  <PossibleConnectionCategoriesProvider :value="allPossible360ConnectionCategories">
    <PerformanceLayout v-if="ready" class="three-sixty">
      <template v-if="matches.laptop.value" #sidebar>
        <OnxSidebar>
          <ThreeSixtyMenu />
        </OnxSidebar>
      </template>

      <template #header>
        <router-view name="header" />
      </template>

      <template #breadcrumbs>
        <ThreeSixtyBreadcrumbs />
      </template>

      <template #content>
        <router-view />
      </template>
    </PerformanceLayout>

    <LoaderGrid v-else />
  </PossibleConnectionCategoriesProvider>
</template>

<style lang="scss">
@import 'scss/onx-breakpoints.module';

.three-sixty {
  .PerformanceLayout__main {
    padding-bottom: 0px;
  }

  &__charts-container {
    padding: 8px;

    @include tablet() {
      padding: 16px;
    }

    .onx-table-container {
      padding: 16px 8px 8px 8px;
      margin-bottom: 16px;

      > .onx-headline {
        margin-top: 0;
      }
    }

    & .onx-grid.fluid {
      @include desktop {
        --onx-grid-fluid-item-default-min-width: 560px;
      }
    }
  }
}

iframe {
  border: 0;
  height: 100%;
}

.onx-360-coverage-availability {
  & .chart-height {
    height: 575px;
  }

  .coverage-map__map {
    height: 100%;
  }

  .coverage-map__map-wrapper {
    height: calc(100% - 42px);
  }
}
</style>
