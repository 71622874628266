<script setup lang="ts">
import { computed } from 'vue';

import { FieldGroup } from '@/components/menu';
import OnxRadioButton from '@/components/onx/OnxRadioButton.vue';
import { AGGREGATIONS, DEFAULT_AGGREGATION } from '@/constants/constants';
import { TOOLTIP_MESSAGES } from '@/constants/tooltips';
import useSpotlightQueryParams from '@/components/specialized/useSpotlightQueryParams';
import useAnalytics from '@/composables/useAnalytics';
import useCurrentDashboardName from '@/composables/useCurrentDashboardName';
import useDashboardInfo from '@/composables/useDashboardInfo';

const { track } = useAnalytics();
const queryParams = useSpotlightQueryParams({
  agg: DEFAULT_AGGREGATION,
});

const dashboard = useCurrentDashboardName();
const { data: dashboardInfo } = useDashboardInfo(dashboard.value);

const aggregationOptions = computed(() => {
  const aggregationTypesEnabled = dashboardInfo.value?.data.aggregation_types_enabled ?? [];
  return AGGREGATIONS.filter((agg) => aggregationTypesEnabled.includes(agg.value));
});

const onAggregationChange = (value: string) => {
  queryParams.agg.onChange(value);
  track('aggregation change', { newAggregation: value });
};
</script>

<template>
  <FieldGroup :tooltip="TOOLTIP_MESSAGES.aggregation" label="Aggregation">
    <OnxRadioButton
      v-for="option in aggregationOptions"
      name="aggregation-radio"
      :key="option.value"
      :model-value="queryParams.agg.selectedValue.value"
      @update:model-value="onAggregationChange"
      :value="option.value"
      :label="option.label"
    />
  </FieldGroup>
</template>
