import ROUTE_GROUPS from '@/constants/routeGroups';
import onx360Logo from '@/assets/onx/logo/onx360.svg';
import onxdataLogo from '@/assets/onx/logo/onxdata.svg';
import onxfocusLogo from '@/assets/onx/logo/onxfocus.svg';
import onxspotlightLogo from '@/assets/onx/logo/onxspotlight.svg';

const types = {
  GET_LAST_DATE_AVAILABLE: 'auth/getLastDateAvailable',
  GET_ONX_PRODUCTS: 'auth/getOnxProducts',
  GET_SUPPORTED_DASHBOARDS: 'auth/getSupportedDashboards',
  IS_LOGIN_ERROR: 'auth/isLoginError',
  IS_LOGIN_PENDING: 'auth/isLoginPending',
  IS_USER_PENDING: 'auth/isUserPending',
  LOAD_USER_ASYNC_FAILURE: 'auth/LOAD_USER_ASYNC_FAILURE',
  LOAD_USER_ASYNC_PENDING: 'auth/LOAD_USER_ASYNC_PENDING',
  LOAD_USER_ASYNC_SUCCESS: 'auth/LOAD_USER_ASYNC_SUCCESS',
  LOGIN_ASYNC_FAILURE: 'auth/LOGIN_ASYNC_FAILURE',
  LOGIN_ASYNC_PENDING: 'auth/LOGIN_ASYNC_PENDING',
  LOGIN_ASYNC_SUCCESS: 'auth/LOGIN_ASYNC_SUCCESS',
};

const state = () => ({
  loginError: null,
  loginPending: false,
  userError: null,
  userPending: false,
});

const getters = {
  [types.IS_LOGIN_ERROR]: (state) => state.loginError,
  [types.IS_LOGIN_PENDING]: (state) => state.loginPending,
  [types.IS_USER_PENDING]: (state) => state.userPending,
  [types.GET_LAST_DATE_AVAILABLE]: (state, getters, rootState, rootGetters) => {
    return rootGetters.dashboardInfo.last_date_available;
  },
  [types.GET_SUPPORTED_DASHBOARDS]: (state, getters, rootState, rootGetters) => {
    return rootGetters.user.dashboards || [];
  },
  [types.GET_ONX_PRODUCTS]: (state, getters, rootState, rootGetters) => {
    const supportedDashboards = getters[types.GET_SUPPORTED_DASHBOARDS];
    const user = rootGetters.user;
    const onxConfig = user.onx_config || {};

    let products = [
      {
        name: 'spotlight',
        description:
          "Understand your users' experience and your network’s competitive position in an ever changing market.",
        route: 'competitive',
        matchingRoutes: [...ROUTE_GROUPS.Spotlight, ...ROUTE_GROUPS.IntlSpotlight],
        keys: ['spotlight', 'competitive'],
        logo: onxspotlightLogo,
      },
      {
        name: 'focus',
        description:
          "Identify and prioritise what impacts the end to end network experience of your own and your competitors' users.",
        route: 'focus',
        matchingRoutes: ROUTE_GROUPS.Focus,
        keys: ['focus'],
        enabled: true,
        logo: onxfocusLogo,
      },
      {
        name: '360',
        description:
          'Visualise, explore and identify clear actions on the Core & Radio Access Network to improve user experience.',
        route: 'onx360',
        matchingRoutes: ROUTE_GROUPS.ThreeSixty,
        keys: ['onx360'],
        logo: onx360Logo,
      },
      {
        name: 'data',
        description: 'Unleash the power of Opensignal data to create additional bespoke use cases and analytics.',
        route: onxConfig['onxdata']?.url,
        keys: ['onxdata'],
        logo: onxdataLogo,
      },
    ].map((product) => ({
      ...product,
      enabled: product.keys.some((k) => supportedDashboards.includes(k)),
    }));

    if (!supportedDashboards.includes('performance')) {
      products = products.filter((product) => product.name !== 'pi2023');
    }

    return products;
  },
};

const actions = {};

const mutations = {
  [types.LOAD_USER_ASYNC_PENDING]: (state) => {
    state.userPending = true;
    state.userError = false;
  },
  [types.LOAD_USER_ASYNC_SUCCESS]: (state) => {
    state.userPending = false;
    state.userError = false;
  },
  [types.LOAD_USER_ASYNC_FAILURE]: (state) => {
    state.userPending = false;
    state.userError = true;
  },
  [types.LOGIN_ASYNC_PENDING]: (state) => {
    state.loginPending = true;
  },
  [types.LOGIN_ASYNC_SUCCESS]: (state) => {
    state.loginPending = false;
    state.loginError = false;
  },
  [types.LOGIN_ASYNC_FAILURE]: (state, err) => {
    state.loginPending = false;
    state.loginError = err;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
