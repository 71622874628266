<template>
  <div class="LocationSelector">
    <!-- Countries label when there's no other countries -->
    <div v-if="countries.length === 1" class="location-selector__single-country">
      {{ countries[0].name }}
    </div>

    <!-- Countries dropdown when you can choose other countries -->
    <OnxLocationDropdown
      v-else
      v-model="currentCountry"
      :render-item="(item) => item.name"
      :options="countries"
      @update:modelValue="(country) => navigate(country)"
    />

    <template v-if="!countriesOnly">
      <!-- Opensignal groupings are default sets of location groups, usually cities/metro areas and provinces/states/territories/etc. -->
      <template v-for="grouping in groupingsOS" :key="grouping.id">
        <OnxLocationDropdown
          v-model="selected[grouping.id]"
          :render-item="(item) => item.name"
          :placeholder="grouping.name"
          :options="grouping.locations"
          @update:modelValue="(grouping) => navigate(grouping)"
          combobox
          :search-input-override="enableBboxFiltering ? '' : undefined"
        >
          <template #icon>
            <SearchIcon />
          </template>
        </OnxLocationDropdown>
      </template>

      <!-- Custom groupings are made by the customer, usually polygons or something? -->
      <div v-if="groupingsCustom.length">
        <div v-for="grouping in groupingsCustom" :key="grouping.id">
          <OnxLocationDropdown
            v-if="Array.isArray(grouping.locations) && grouping.locations.length > 0"
            v-model="selected[grouping.id]"
            :render-item="(item) => item.name"
            :placeholder="grouping.name"
            :options="grouping.locations"
            @update:modelValue="(granularity) => navigate(granularity)"
            combobox
            :search-input-override="enableBboxFiltering ? '' : undefined"
          >
            <template #icon>
              <OperatorAvatar
                :name="homeNetwork.name_mapped"
                :background-color="'#' + homeNetwork.hex_color"
                :background-style="homeNetwork.is_mvno ? 'outline' : 'fill'"
                size="sm"
              />
            </template>
          </OnxLocationDropdown>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import OperatorAvatar from '../OperatorAvatar';
import SearchIcon from '@/components/onx/icons/SearchIcon';
import OnxLocationDropdown from '@/components/onx/OnxLocationDropdown';
import { customGroupingsFilter } from '@/utils/groupings';
import { OS_GEOCODINGS } from '@/constants/constants';
import useAnalytics from '@/composables/useAnalytics';

export default {
  name: 'LocationSelector',
  components: {
    OperatorAvatar,
    OnxLocationDropdown,
    SearchIcon,
  },
  setup() {
    const { track } = useAnalytics();

    return {
      track,
    };
  },
  props: {
    displayTopLocationsOnly: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: '',
    },
    countriesOnly: {
      type: Boolean,
      default: false,
    },
    hiddenGeocodings: {
      type: Array,
      default: () => [],
    },
    enableBboxFiltering: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      countries: 'location/countries',
      currentCountry: 'location/currentCountry',
      currentLocation: 'location/currentLocation',
      groupings: 'location/byGroupings',
      homeNetwork: 'charts/homeNetwork',
    }),
    groupingsOS() {
      return this.groupings
        .filter((group) => group.id === OS_GEOCODINGS.regions || group.id === OS_GEOCODINGS.cities)
        .filter((group) => !this.hiddenGeocodings.includes(group.id));
    },
    groupingsCustom() {
      return this.groupings.filter(customGroupingsFilter);
    },
    selected() {
      return {
        [Number(this.currentLocation.granularityId)]: this.currentLocation,
      };
    },
  },
  methods: {
    navigate(location) {
      this.$emit('navigate', location);
      this.track('location change', {
        name: location.name,
        key: location.key,
        granularity: location.granularity,
        granularityId: location.granularityId,
        iso3: location.iso3,
      });
    },
  },
};
</script>

<style lang="scss">
@import 'scss/mixins';

.location-selector__single-country {
  display: flex;
  align-items: center;

  font-size: pxToRem(12);
  width: 100%;
  color: var(--white);
  height: 28px;
}
</style>
