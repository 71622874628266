<script setup>
import { computed } from 'vue';
import CheckedIcon from './CheckedIcon.vue';
import UncheckedIcon from './UncheckedIcon.vue';
import MinusIcon from './MinusIcon.vue';
import CustomTooltip from '@/components/tooltip/CustomTooltip.vue';
import InfoIcon from '../icons/InfoIcon.vue';

const props = defineProps({
  dark: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: '',
  },
  modelValue: {
    type: String, // 'checked' | 'unchecked' | 'indeterminate'
    required: true,
  },
  tooltip: {
    type: String,
    default: '',
  },
});

// check is fired if the checkbox is currently not checked (either unchecked or indeterminate)
// uncheck is fired only if the checkbox is checked when clicked
// The parent component decides what to do in each case
const emit = defineEmits(['check', 'uncheck']);
const onClick = () => {
  if (props.disabled){
    return;
  } else if (props.modelValue === 'checked') {
    emit('uncheck');
  } else {
    emit('check');
  }
};

const classObject = computed(() => {
  return {
    'onx-tristatebox': true,
    'onx-tristatebox--disabled': props.disabled,
    'onx-tristatebox--dark': props.dark,
  };
});

</script>

<template>
  <label :class="classObject" @click.prevent="onClick">
    <span class="onx-tristatebox__icon">
      <CheckedIcon v-if="modelValue === 'checked'" />
      <MinusIcon v-else-if="modelValue === 'indeterminate'" />
      <UncheckedIcon v-else />
    </span>

    <slot>
      <span v-if="label" class="onx-tristatebox__label">
        {{ label }}
      </span>
      <CustomTooltip v-if="tooltip" :message="tooltip" placement="bottom">
        <InfoIcon class="onx-tristatebox__tooltipIcon" />
      </CustomTooltip>
    </slot>
  </label>
</template>

<style scoped lang="scss">
@import 'scss/mixins';

.onx-tristatebox {
  display: inline-flex;
  align-items: center;
  position: relative;
  font-size: pxToRem(12);
  line-height: pxToRem(14);
  z-index: 1;
  cursor: pointer;

  &__label {
    color: white;
  }

  &__icon {
    position: relative;

    margin-right: 10px;
    width: 12px;
    height: 12px;

    transition: transition();
    color: var(--onx-checkbox-on-color);

    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;

      content: '';
      width: 95%;
      height: 95%;
      border-width: 0;
      border-radius: 6px;
      border-style: solid;
      border-color: var(--onx-checkbox-hover-shadow-light-color);
      box-sizing: content-box;
    }
  }

  .onx-tristatebox__tooltipIcon {
    color: white;
  }

  &--dark {
    .onx-tristatebox__icon {
      border-color: var(--onx-checkbox-hover-shadow-dark-color);
    }
  }

  &--disabled {
    pointer-events: none;
    cursor: not-allowed;

    .onx-tristatebox__icon {
      color: var(--onx-checkbox-disabled-dark-color);
    }
  }
}
</style>
