import { getMeanIPMinMax } from '../data-transformers/getMinMax';
import { ChartMetricDefinition, InitialSortDirection } from '@/chart-metric-definitions/MetricDefinition';

import { ChartTypesEnum } from '@/types/Charts';
import { DateIntervalEnum } from '@/types/MetricRequestParameters';
import { MetricSubtypes } from '@/types/MetricSubtypes';

import transformCdnIpDownload360Trend from '@/chart-metric-definitions/data-transformers/transformCdnIpDownload360Trend';

import { gamesAndVoiceColumns } from '@/360/core/table-columns/gamesAndVoiceColumns';
import exportCdnIpSpeedTrendToCsv from '@/360/csv-export-overrides/exportCdnIpSpeedTrendToCsv';
import exportCdnIpTestServersToCsv from '@/360/csv-export-overrides/exportCdnIpTestServersToCsv';
import transformCdnIP360WeeklyStackPerOperator from '@/chart-metric-definitions/data-transformers/transformCdnIP360WeeklyStackPerOperator';
import coreCdnIpSpeedTooltipPlugin from '@/chart-metric-definitions/tooltips/coreCdnIpSpeedTooltip';

import { commonDatasetTransformer } from '@/360/core/table-columns/commonDatasetTransformer';
import lineChartLegendCdnListPlugin from '@/components/visual/chart/line-chart-plugins/lineChartLegendCdnListPlugin';

const cdnIpTrendChartAttrs = {
  lineChartAttrs: {
    spanGaps: false,
    useCustomLegend: true,
    plugins: [lineChartLegendCdnListPlugin()],
  },
};

const testServersTooltipPlugin = coreCdnIpSpeedTooltipPlugin({
  valueFn: ({ dataAxis, datapoint }) =>
    datapoint.dataset.data.find((point: any) => point.date === datapoint.raw.date)[dataAxis] + '%',
  secondaryValueFn: ({ datapoint }) =>
    datapoint.dataset.data.find((point: any) => point.date === datapoint.raw.date).secondaryValue,
});

const onx360CoreEndpointIPGamesAndVoiceMetricDefinitions: Record<string, ChartMetricDefinition[]> = {
  withOperators: [
    {
      dataset: 'onx360_cdnip_packet_loss_delay_jitter',
      chartType: ChartTypesEnum.DatasetTable,
      chartContainerClass: 'onx-grid-item__full-width',
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory Games and Voice Performance Tests by IP',
      },
      columns: gamesAndVoiceColumns,
      initialSortColumnKey: 'percentage',
      initialSortDirection: InitialSortDirection.Desc,
      transformData: commonDatasetTransformer,
    },
  ],
  byOperator: [
    {
      metricSubtype: MetricSubtypes.CdnIpJitter360Trend,
      chartType: ChartTypesEnum.Trend,
      transformData: transformCdnIpDownload360Trend,
      calculateBounds: getMeanIPMinMax,
      onExportCsv: exportCdnIpSpeedTrendToCsv,
      chartAttrs: {
        ...cdnIpTrendChartAttrs,
        chartTitlePlaceholders: ':connectionCategory Jitter (ms) Trend by IP for :operator',
      },
    },
    {
      metricSubtype: MetricSubtypes.CdnIpPacketLoss360Trend,
      chartType: ChartTypesEnum.Trend,
      transformData: transformCdnIpDownload360Trend,
      calculateBounds: getMeanIPMinMax,
      onExportCsv: exportCdnIpSpeedTrendToCsv,
      chartAttrs: {
        ...cdnIpTrendChartAttrs,
        chartTitlePlaceholders: ':connectionCategory Packet Loss Lost (%) Trend by IP for :operator',
      },
    },
    {
      metricSubtype: MetricSubtypes.CdnIpPacketLossLost360Trend,
      chartType: ChartTypesEnum.Trend,
      transformData: transformCdnIpDownload360Trend,
      calculateBounds: getMeanIPMinMax,
      onExportCsv: exportCdnIpSpeedTrendToCsv,
      chartAttrs: {
        ...cdnIpTrendChartAttrs,
        chartTitlePlaceholders: ':connectionCategory Packet Loss Discard (%) Trend by IP for :operator',
      },
    },
    {
      metricSubtype: MetricSubtypes.CdnIpUdpTestServers360Stack,
      chartType: ChartTypesEnum.BarChart,
      chartContainerClass: 'onx-grid-item__full-width',
      transformData: transformCdnIP360WeeklyStackPerOperator('percentage', 'counts'),
      onExportCsv: exportCdnIpTestServersToCsv,
      tooltipPlugin: testServersTooltipPlugin,
      chartAttrs: {
        chartTooltipShowSecondaryValue: true,
        max: 100,
        dateInterval: DateIntervalEnum.Week,
        chartTitlePlaceholders: ':connectionCategory Games and Voice Test Servers (%) by IP for :operator',
      },
    },
  ],
};

export default onx360CoreEndpointIPGamesAndVoiceMetricDefinitions;
