import { computed } from 'vue';

import useLocations from '@/composables/useLocations';
import useDashboardInfo from '@/composables/useDashboardInfo';
import useCurrentDashboardName from '@/composables/useCurrentDashboardName';

const useDefaultLocationId = () => {
  const dashboard = useCurrentDashboardName();
  const { currentLocation } = useLocations(dashboard.value);
  const { data: dashboardInfoResponse } = useDashboardInfo(dashboard.value);

  const defaultCountry = computed(() => {
    return dashboardInfoResponse.value?.data.default_country;
  });

  const countriesVisibleFull = computed(() => {
    return dashboardInfoResponse.value?.data.countries_visible_full;
  });

  const defaultLocation = computed(() => {
    return currentLocation.value?.key || defaultCountry.value || countriesVisibleFull.value?.[0]?.id;
  });

  return defaultLocation;
};

export default useDefaultLocationId;
