<template>
  <OnxMapboxBase :geo-json="processedGeoJson" :selected-feature="selected" @mapReady="onMapReady">
    <dynamic-colour-scale :ranges="compareScale" />
  </OnxMapboxBase>
</template>

<script>
import chroma from 'chroma-js';
import get from 'lodash/get';
import { mapGetters } from 'vuex';
import DynamicColourScale from './DynamicColourScale';
import { refineGeoJsonToHandle180thMeridian } from './helpers';
import router from '@/router';
import { sortByMetricMeanAndRank } from '@/utils/data';
import OnxMapboxBase from '@/components/visual/map/OnxMapboxBase.vue';

const NoDataColor = '#DEE4EC';
const PolygonSourceName = 'polygons-layer';
const PolygonFillsLayerName = 'polygon-fills';

export default {
  name: 'RankingMap',
  components: {
    OnxMapboxBase,
    DynamicColourScale,
  },
  mapObject: null,
  props: {
    geoJson: {
      type: Object,
      default: () => ({}),
    },
    choroplethData: {
      type: Array,
      default: () => [],
    },
    networks: {
      type: Array,
      default: () => [],
    },
    modelValue: {
      type: Number,
      default: 0,
    },
    markers: {
      type: Boolean,
      default: false,
    },
    zoomed: {
      type: Boolean,
      default: false,
    },
    displayRank: {
      type: Boolean,
      default: false,
    },
    locationsWithRank: {
      type: Object,
      default: () => ({}),
    },
    currentCountry: {
      type: Number,
      default: null,
    },
    showFeatures: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      noDataColor: 'DEE4EC',
      noRankColor: 'E4D5BC',
      map: null,
      activeLocation: null,
      mainCluster: null,
      citiesCluster: null,
    };
  },
  computed: {
    ...mapGetters({
      availableNetworks: 'charts/ci_networks',
      currentMetric: 'metrics/primaryMetric',
      homeNetwork: 'charts/homeNetwork',
      country: 'location/currentCountry',
      currentLocation: 'location/currentLocation',
    }),
    otherDrawColor() {
      // hard coded Japan colors to avoid overlap with softbank
      if (this.currentCountry === 101) {
        return '04bf33';
      }
      return 'ABBECE';
    },
    homeDrawColor() {
      // hard coded Japan colors to avoid overlap with softbank
      if (this.currentCountry === 101) {
        return '04b5ba';
      }
      return this.homeNetwork.hex_color;
    },
    factoredData() {
      const operatorsByLocation = this.choroplethData.reduce((ac, el) => {
        if (ac[el.location]) {
          ac[el.location].push(el);
        } else {
          ac[el.location] = [el];
        }
        return ac;
      }, {});

      return Object.values(operatorsByLocation)
        .map((arr) => {
          if (arr.length === 1) {
            return {
              location: arr[0].location,
              networks: [arr[0].canonical_network_id],
            };
          }

          return arr.reduce(
            (elm, c) => {
              elm.networks.push(c.canonical_network_id);
              return elm;
            },
            {
              location: arr[0].location,
              networks: [],
            },
          );
        })
        .map((item) => {
          item.colors = item.networks.map((n) => {
            const network = this.networks.find((i) => i.canonical_network_id === n);
            return (network && network.hex_color) || 'ccc';
          });

          return item;
        });
    },
    tooltipData() {
      if (this.activeLocation) {
        const feature = [...get(this.processedGeoJson, 'features', [])].find(
          (feature) => feature.id === this.activeLocation,
        );

        return {
          name: feature.properties.name,
          data: sortByMetricMeanAndRank(
            feature.item.filter((i) => this.networks.find((l) => l.name_mapped === i.label)),
            this.currentMetric.bigger_is_better,
          ),
        };
      }

      return {
        name: '',
        data: [],
      };
    },
    processedGeoJson() {
      return refineGeoJsonToHandle180thMeridian(this.geoJson);
    },
    geoJsonData() {
      const features = this.showFeatures
        ? this.processedGeoJson.features.map((f) => {
            return {
              ...f,
              properties: {
                ...f.properties,
                ...this.getFeatureStyle(f),
              },
            };
          })
        : [];
      return {
        geoJson: {
          ...this.processedGeoJson,
          features: features,
        },
        data: this.factoredData,
      };
    },
    compareScale() {
      const scale = this.availableNetworks
        .filter((network) => !network.is_mvno)
        .map((network) => {
          return {
            label: network.name_mapped,
            color: `#${network.hex_color}`,
            type: 'operator',
          };
        });

      scale.map((network, i, scale) => {
        if (network.label === this.homeNetwork.name_mapped) {
          scale.splice(i, 1);
          scale.unshift(network);
        }
      });

      scale.push({
        label: 'draws inc. ' + this.homeNetwork.name_mapped,
        color: chroma(this.getColor(`#${this.homeDrawColor}`))
          .alpha(0.5)
          .hex(),
        type: 'nonoperator-svg',
      });

      scale.push({
        label: 'other draws',
        color: `#${this.otherDrawColor}`,
        type: 'nonoperator-svg',
      });

      scale.push({
        label: 'no ranks',
        color: `#${this.noRankColor}`,
        type: 'nonoperator-svg',
      });

      scale.push({
        label: 'unavailable',
        color: `#${this.noDataColor}`,
        type: 'nonoperator',
      });

      return scale;
    },
    selected() {
      return [...get(this.processedGeoJson, 'features', [])].find((feature) => feature.id === this.modelValue);
    },
  },
  watch: {
    selected(newSelected, oldSelected) {
      if (oldSelected) {
        this.map.setFeatureState({ source: PolygonSourceName, id: oldSelected.id }, { selected: false });
      }

      if (newSelected) {
        this.map.setFeatureState({ source: PolygonSourceName, id: newSelected.id }, { selected: true });
      }

      this.setPolygonFillOpacityRules();
    },
  },
  methods: {
    setPolygonFillOpacityRules() {
      this.map.setPaintProperty(PolygonFillsLayerName, 'fill-opacity', [
        'case',
        ['boolean', ['feature-state', 'selected'], false],
        1,
        ['boolean', ['feature-state', 'hover'], false],
        0.9,
        this.selected ? 0.25 : 0.75,
      ]);
    },
    onMapReady(map) {
      this.map = map;

      this.map.addSource(PolygonSourceName, {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          ...this.geoJsonData.geoJson,
        },
      });

      this.map.addLayer({
        id: PolygonFillsLayerName,
        type: 'fill',
        source: PolygonSourceName,
        layout: {},
        paint: {
          'fill-color': ['case', ['==', ['get', 'color'], null], NoDataColor, ['get', 'color']],
        },
      });

      this.setPolygonFillOpacityRules();

      this.map.addLayer({
        id: 'polygon-borders',
        type: 'line',
        source: PolygonSourceName,
        layout: {},
        paint: {
          'line-color': '#004247',
          'line-width': [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            3,
            ['boolean', ['feature-state', 'selected'], false],
            2,
            1,
          ],
        },
      });

      // When the user moves their mouse over the PolygonFillsLayerName layer, we'll update the
      // feature state for the feature under the mouse.
      let hoveredPolygonId = null;
      this.map.on('mousemove', PolygonFillsLayerName, (e) => {
        if (!e.features.length) {
          return;
        }

        const feature = e.features[0];
        if (hoveredPolygonId != null) {
          this.map.setFeatureState({ source: PolygonSourceName, id: hoveredPolygonId }, { hover: false });
        }

        hoveredPolygonId = feature.id || feature.properties?.id;
        this.map.setFeatureState({ source: PolygonSourceName, id: hoveredPolygonId }, { hover: true });

        this.activeLocation = feature.properties.id;
        this.$emit('location-mouseover', {
          locationName: feature.properties.name,
          locationData: this.getDataForLocation(hoveredPolygonId),
        });
      });

      // When the mouse leaves the PolygonFillsLayerName layer, update the feature state of the
      // previously hovered feature.
      this.map.on('mouseleave', PolygonFillsLayerName, () => {
        if (hoveredPolygonId !== null) {
          this.map.setFeatureState({ source: PolygonSourceName, id: hoveredPolygonId }, { hover: false });
        }
        hoveredPolygonId = null;

        this.activeLocation = null;
        this.$emit('location-mouseout');
      });

      this.map.on('click', PolygonFillsLayerName, (e) => {
        if (!e.features.length) {
          return;
        }

        const targetFeature = e.features[0];
        this.selectPostcode(targetFeature.properties.id);
      });

      if (this.selected) {
        this.map.setFeatureState({ source: PolygonSourceName, id: this.modelValue }, { selected: true });
      }
    },
    getColor(color) {
      if (color === '#null') {
        return `#${this.noDataColor}`;
      }
      return color;
    },
    getFeatureStyle(feature) {
      if (router.currentRoute.value.query.geocoding === '1') {
        return {
          fillOpacity: 0,
        };
      }

      const item = this.getDataForLocation(feature.id);
      if (!item) {
        return {
          color: '#' + this.noDataColor,
        };
      }

      if (!this.locationsWithRank[feature.id]) {
        return {
          color: `#${this.noRankColor}`,
        };
      }

      return {
        color: this.elementColor(item),
      };
    },
    getDataForLocation(id) {
      return this.factoredData.find((x) => x.location === id);
    },
    selectPostcode(id) {
      if (id === this.modelValue) {
        this.$emit('update:modelValue', parseInt(this.country.key));
      } else {
        this.$emit('update:modelValue', id);
      }
    },
    elementColor(item) {
      const isSelectedLocation = !this.zoomed || item.location === this.modelValue;

      if (item.colors.length === 1) {
        return !isSelectedLocation ? chroma(`#${item.colors[0]}`).css() : `#${item.colors[0]}`;
      }

      if (item.networks.includes(this.homeNetwork.canonical_network_id)) {
        return chroma(this.getColor(`#${this.homeDrawColor}`)).css();
      }

      return !isSelectedLocation ? chroma(`#${this.otherDrawColor}`).css() : `#${this.otherDrawColor}`;
    },
  },
};
</script>
<style lang="scss">
@use 'scss/variables.module' as *;
@import 'scss/components';
@import 'scss/onx-breakpoints.module';

.cluster {
  margin: -0.5rem;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  line-height: 2rem;
  text-align: center;
  background-color: $color-nav-bg;
  color: $color-nav-item-text;
  cursor: pointer;
}

.Details__mapView {
  position: relative;

  .RankingTableTooltip {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    padding: 0;
  }

  .DynamicColourScale {
    z-index: 1;
    position: absolute;
    bottom: 26px;
    right: 16px;
    top: auto;

    @include tablet {
      top: 16px;
      bottom: auto;
      right: 16px;
    }

    &__value {
      text-align: left;
    }
  }

  .map-greyscale-control {
    left: 16px;
    bottom: 16px;
    top: auto;
  }
}
</style>
