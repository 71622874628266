import { computed } from 'vue';

import useMetrics from '@/components/onx/composables/useMetrics';

const useMetricConnectionCategories = () => {
  const { metrics } = useMetrics();

  const connectionCategories = computed(() => {
    const categoriesBySubtypeAndUserGroup: Record<string, string[]> = {};

    metrics.value.forEach((metric) => {
      const [metricSubtypeAndUserGroup] = metric.key.split('_');

      if (!categoriesBySubtypeAndUserGroup[metricSubtypeAndUserGroup]) {
        categoriesBySubtypeAndUserGroup[metricSubtypeAndUserGroup] = [];
      }

      if (!categoriesBySubtypeAndUserGroup[metricSubtypeAndUserGroup].includes(metric.type)) {
        categoriesBySubtypeAndUserGroup[metricSubtypeAndUserGroup].push(metric.type);
      }
    });

    return categoriesBySubtypeAndUserGroup;
  });

  return {
    connectionCategoriesBySubtypeAndUserGroup: connectionCategories,
  };
};

export default useMetricConnectionCategories;
