import { format } from 'date-fns';

import { getSafeDate } from '@/utils/date';

export const getPeriodString = (startDate: string, endDate: string) => {
  const utcStartDate = getSafeDate(startDate);
  const utcEndDate = getSafeDate(endDate);

  if (!utcStartDate || !utcEndDate) {
    return '';
  }

  const formattedStartDate = format(utcStartDate, 'dd/MM/yyyy');
  const formattedEndDate = format(utcEndDate, 'dd/MM/yyyy');

  return `${formattedStartDate} - ${formattedEndDate}`;
};
