<script setup>
import { useRoute } from 'vue-router';
import { Dashboards } from '@/constants/dashboards';
import { OnxPermissionTopics } from '@/composables/useOnxLicencePermission';
import { OnxTabsContext, OnxTabs, OnxTab } from '@/components/onx/tabs';
import OnxPermissionGuard from '@/components/onx/OnxPermissionGuard.vue';
import ROUTES from '@/constants/routes';
import { FocusHeader, useFocusHeaderTabChange } from '@/focus/focus-header';

const route = useRoute();
const onTabChange = useFocusHeaderTabChange();
</script>

<template>
  <FocusHeader>
    <template #tabs>
      <OnxTabsContext :selected-tab="route.matched?.[2]?.name">
        <OnxTabs @change="onTabChange">
          <OnxPermissionGuard :dashboard="Dashboards.Focus" :topic="OnxPermissionTopics.RANHealthCoverage">
            <OnxTab :id="ROUTES.FocusRANHealthCoverage">Coverage</OnxTab>
          </OnxPermissionGuard>
          <OnxPermissionGuard :dashboard="Dashboards.Focus" :topic="OnxPermissionTopics.RANHealthNetworkRollout">
            <OnxTab :id="ROUTES.FocusRANHealthNetworkRollout">Network rollout</OnxTab>
          </OnxPermissionGuard>
          <OnxPermissionGuard :dashboard="Dashboards.Focus" :topic="OnxPermissionTopics.RANHealthDeployedSpectrum">
            <OnxTab :id="ROUTES.FocusRANHealthDeployedSpectrum">Deployed Spectrum</OnxTab>
          </OnxPermissionGuard>
          <OnxPermissionGuard :dashboard="Dashboards.Focus" :topic="OnxPermissionTopics.RANHealthSiteMapper">
            <OnxTab :id="ROUTES.FocusRANHealthSiteMapper">Site Mapper</OnxTab>
          </OnxPermissionGuard>
        </OnxTabs>
      </OnxTabsContext>
    </template>
  </FocusHeader>
</template>
