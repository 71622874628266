<script setup lang="ts">
import { computed } from 'vue';

import OnxRadioButtonListDropdown from '@/components/onx/OnxRadioButtonListDropdown.vue';
import useDeploymentTypes from '@/focus/deployment-type-selector/useDeploymentTypes';

type Props = {
  queryParam: string;
};

const props = defineProps<Props>();

const { availableDeploymentTypesList, onDeploymentTypeChange, selectedDeploymentType } = useDeploymentTypes(
  props.queryParam,
);

const items = computed(() => {
  return availableDeploymentTypesList.map((deploymentType) => ({
    keyAccessor: deploymentType.value,
    label: deploymentType.label,
    value: deploymentType.value,
    checked: selectedDeploymentType.value?.value === deploymentType.value,
  }));
});
</script>

<template>
  <OnxRadioButtonListDropdown
    label="Deployment Types:"
    :selectedLabel="selectedDeploymentType?.label"
    :items="items"
    @list-item-click="onDeploymentTypeChange"
  />
</template>
