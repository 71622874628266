import { useQuery } from '@tanstack/vue-query';

import osApi from '@/api/osApi';
import useCurrentDashboardName from '@/composables/useCurrentDashboardName';

import type { DatasetDescriptor } from '@/types/DatasetDescriptor';

export const useDatasetDescriptors = () => {
  const dashboard = useCurrentDashboardName();

  const { data, isLoading } = useQuery({
    queryKey: ['datasetApiDefinitions', dashboard],
    queryFn: async () => {
      return osApi.get<DatasetDescriptor[]>(`/${dashboard.value}/datasets/`);
    },
    select: (response) => {
      return response.data.reduce<{ keys: string[]; byKey: Record<string, DatasetDescriptor> }>(
        (acc, dataset) => {
          acc.keys.push(dataset.dataset_key);
          acc.byKey[dataset.dataset_key] = dataset;

          return acc;
        },
        { keys: [], byKey: {} },
      );
    },
  });

  return { datasets: data, isLoading };
};
