import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import type { DatasetDescriptor } from '@/types/DatasetDescriptor';
import type { MetricDescriptor } from '@/types/MetricDescriptor';

import { SelectableMetrics } from '@/types/metrics-selector/SelectableMetrics';
import getAvailableChartMetrics from '@/focus/metric-selector/getAvailableChartMetrics';
import {
  isChartMetricDefinitionWithSingleSubtype,
  isDatasetChartMetricDefinition,
} from '@/chart-metric-definitions/MetricDefinition';
import { useDatasetDescriptors } from '@/composables/useDatasetDescriptors';

/**
 * Returns a list of unique connection categories available to the user based on
 * a frontend defined metric definition subset (i.e ChartMetricDefinitions.ts at this time), picked by metricSubtype and cross-referenced
 * against the /metrics API response, which only provides metrics the user has access to.
 */
const useAvailableConnectionCategories = () => {
  const router = useRouter();
  const store = useStore();
  const metricDescriptorsBySubtype = store.getters['metrics/subtypes'];
  const { datasets } = useDatasetDescriptors();

  const connectionCategories = computed<string[]>(() => {
    const metricSubtype = router.currentRoute.value.query.metricSubtype as SelectableMetrics;
    const availableChartMetrics = getAvailableChartMetrics(metricSubtype, router.currentRoute.value.name as string);

    return Array.from(
      new Set(
        availableChartMetrics
          .flatMap<MetricDescriptor | DatasetDescriptor>((metricDefinition) => {
            if (isChartMetricDefinitionWithSingleSubtype(metricDefinition)) {
              return metricDescriptorsBySubtype[metricDefinition.metricSubtype] ?? [];
            } else if (isDatasetChartMetricDefinition(metricDefinition)) {
              return datasets.value?.byKey[metricDefinition.dataset] ?? [];
            }

            return [];
          })
          .flatMap((metric) => {
            if ('dataset_key' in metric) {
              return metric.available_connection_categories.map((category) => {
                if (category.network_frequency) {
                  return category.network_generation + category.network_frequency;
                }

                if (category.network_generation === '4g') {
                  return 'lte';
                }

                return category.network_generation;
              });
            }

            return metric.type;
          }),
      ),
    );
  });

  return connectionCategories;
};

export default useAvailableConnectionCategories;
