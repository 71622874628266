import startCase from 'lodash/startCase';
import { Operator } from '@/types/Operator';
import { MetricStructuresEnum } from '@/types/MetricStructures';
import { videoResColorMap, failureTestColorMap, noDataColor } from '@/constants/colorScales';

import type { DataTransformerMetricsAPIResponseCallback } from '@/chart-metric-definitions/data-transformers/DataTransformerFnType';

const useQoEDetails = () => {
  const transformVideoResTime = (
    operators: Operator[],
  ): DataTransformerMetricsAPIResponseCallback<any, MetricStructuresEnum.ResolutionBreakdown> => {
    return (response) => {
      const metricsFilteredByOperator = response.results.filter((datum) => {
        return operators.some((operator) => operator.canonical_network_id === datum.canonical_network_id);
      });

      if (metricsFilteredByOperator.length === 0) {
        return [];
      }

      const dates = metricsFilteredByOperator.map((datum) => datum.date.substring(0, 10));
      dates.sort();
      const maxDate = dates[dates.length - 1];

      const pivotedMetrics = ['low', '360', '480', '720', '1080', 'high'].map((resolution) => {
        const data = operators
          .map((operator) => {
            const datum = metricsFilteredByOperator.find((datum) => {
              return (
                datum.canonical_network_id === operator.canonical_network_id && datum.date.substring(0, 10) === maxDate
              );
            });

            if (!datum) {
              return undefined;
            }

            return {
              ...datum,
              lci: datum.lci ? (datum.lci as any)[resolution] : undefined,
              uci: datum.uci ? (datum.uci as any)[resolution] : undefined,
              y: datum.mean ? (datum.mean as any)[resolution] : undefined,
              mean: datum.mean ? (datum.mean as any)[resolution] : undefined,
              x: operator.name_mapped,
            };
          })
          .filter(Boolean);

        const color = (videoResColorMap as any)[resolution];

        return {
          data,
          type: 'bar',
          backgroundColor: color,
          color: color,
          label: resolution,
          yAxisID: 'y',
          meta: {
            imageExportLegend: {
              color,
              label: resolution,
            },
          },
        };
      });

      return pivotedMetrics;
    };
  };

  const transformFailureBreakdownMetrics = <
    T extends
      | MetricStructuresEnum.DownloadThroughputFailureBreakdown
      | MetricStructuresEnum.ServerResponseTestFailureBreakdown,
  >(
    operators: Operator[],
  ): DataTransformerMetricsAPIResponseCallback<any, T> => {
    return (response) => {
      const metricsFilteredByOperator = response.results.filter((datum) => {
        return operators.some((operator) => operator.canonical_network_id === datum.canonical_network_id);
      });

      if (metricsFilteredByOperator.length === 0) {
        return [];
      }

      const failureTypes = Object.keys(metricsFilteredByOperator[0].percentage);
      const metrics = failureTypes.map((failureType) => {
        const data = operators
          .map((operator) => {
            const datum = metricsFilteredByOperator.find((datum) => {
              return datum.canonical_network_id === operator.canonical_network_id;
            });

            if (!datum) {
              return undefined;
            }

            return {
              ...datum,
              // @ts-ignore
              y: datum.percentage[failureType],
              x: operator.name_mapped,
            };
          })
          .filter(Boolean);

        // @ts-ignore
        const color = failureTestColorMap[failureType] || `#${noDataColor}`;
        const label = startCase(failureType);

        return {
          data,
          type: 'bar',
          backgroundColor: color,
          color,
          label,
          yAxisID: 'y',
          meta: {
            imageExportLegend: {
              color,
              label,
            },
          },
        };
      });

      return metrics;
    };
  };

  return {
    transformVideoResTime,
    transformFailureBreakdownMetrics,
  };
};

export default useQoEDetails;
