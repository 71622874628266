<script setup lang="ts">
import { computed, ref, unref } from 'vue';
import OnxPulseItem from './OnxPulseItem.vue';
import LoaderGrid from '@/components/LoaderGrid.vue';
import OnxBellFullIcon from '@/components/onx/icons/BellFullIcon.vue';
import OnxBellIcon from '@/components/onx/icons/BellIcon.vue';
import CloseIcon from '@/components/onx/icons/CloseIcon.vue';
import OnxPaper from '@/components/onx/OnxPaper.vue';
import OnxTag from '@/components/onx/tags/OnxTag.vue';
import useSpotlightPulse, { SpotlightPulse } from '@/composables/useSpotlightPulse';
import useLocations from '@/composables/useLocations';
import { Dashboards } from '@/constants/dashboards';

const maxNewItemsToShow = 100;

const {
  data,
  isFetching,
  isLoading,
  isRefetching,
  markMultipleRead: _markMultipleRead,
  markOneRead: _markOneRead,
} = useSpotlightPulse();
const { currentCountry } = useLocations(Dashboards.Spotlight);

/**
 * Whether we're currently marking all items as read
 */
const isMarkingAllRead = ref(false);

/**
 * Whether anything is loading or saving at the moment
 */
const anyLoading = computed(() => {
  return unref(isFetching) || unref(isLoading) || unref(isRefetching) || unref(isMarkingAllRead);
});

/**
 * We need to filter the items that belong to the current country.
 */
const pulseItems = computed(() => {
  const unreffedData = unref(data);
  const unreffedCountry = unref(currentCountry);
  if (unreffedData && unreffedCountry) {
    return unreffedData.data.filter((pulse) => unreffedCountry.iso3 === pulse.country_iso3);
  } else {
    return [];
  }
});

/**
 * For marking single items as read, without reloading the whole list and
 * losing those items in the ui.
 */
const itemsRecentlyMarkedRead = ref<SpotlightPulse[]>([]);

/**
 * Keeps track of items that the server thinks aren't viewed yet, and that the UI hasn't marked as viewed yet.
 * Note that this is still the list of pulse items belonging to this country, from useLocations.
 */
const unreadItems = computed(() => {
  return pulseItems.value.filter((item) => {
    return item.is_read === false && !itemsRecentlyMarkedRead.value.includes(item);
  });
});

/**
 * We don't want to notify the user that there's 7 billion or whatever new unread pulses.
 * We'll either display 0 to 99, or 99+. So this function limits the result to 100 and the UI
 * draws 99+ in that case.
 */
const notificationCount = computed(() => {
  const unreadItemsUnreffed = unref(unreadItems);
  return Math.min(100, unreadItemsUnreffed.length);
});

/**
 * Send an API request to mark these pulse items as read.
 */
const markAllRead = async () => {
  const itemsToMark = unreadItems.value;
  if (anyLoading.value || itemsToMark.length === 0) {
    return;
  }

  itemsRecentlyMarkedRead.value.push(...itemsToMark);
  isMarkingAllRead.value = true;
  try {
    await _markMultipleRead(itemsToMark);

    // there is the possibility that we might prematurely mark an item as viewed locally before
    // the server returns an error code, but refreshing the list will always return the true set
    // of unread items.
  } finally {
    isMarkingAllRead.value = false;
  }
};

const markOneRead = async (item: SpotlightPulse) => {
  if (anyLoading.value || item.is_read || itemsRecentlyMarkedRead.value.includes(item)) {
    return;
  }

  try {
    await _markOneRead(item);
  } finally {
    itemsRecentlyMarkedRead.value.push(item);
  }
};
</script>

<template>
  <VDropdown :distance="6" placement="bottom-right">
    <div class="onx-spotlight-pulse__icon">
      <OnxBellIcon v-if="notificationCount === 0 || anyLoading" />
      <OnxBellFullIcon v-else />
      <OnxTag v-if="notificationCount > 0" class="onx-spotlight-pulse__notification-icon">
        {{ notificationCount > 99 ? '99+' : notificationCount }}
      </OnxTag>
    </div>
    <template #popper="{ hide }">
      <OnxPaper class="spotlight-pulse__container" :depth="3">
        <div class="spotlight-pulse__header">
          <div class="spotlight-pulse__header__icon">
            <OnxBellIcon v-if="notificationCount === 0 || anyLoading" />
            <OnxBellFullIcon v-else />
          </div>
          <div class="spotlight-pulse__header__text">
            <div class="spotlight-pulse__header__title">Spotlight Pulse</div>
            <div v-if="currentCountry.name" class="spotlight-pulse__header__country">
              {{ currentCountry.name }}
            </div>
          </div>
          <button
            v-if="unreadItems.length > 0 && pulseItems.length > 0"
            class="spotlight-pulse__header__mark-all-read"
            :class="{ is_marking_read: isMarkingAllRead }"
            @click.stop="markAllRead"
          >
            Mark all read
          </button>
          <div v-if="unreadItems.length === 0 && pulseItems.length > 0" class="spotlight-pulse__header__mark-all-read">
            No unread items
          </div>
          <button class="spotlight-pulse__header__close">
            <CloseIcon @click="hide" />
          </button>
        </div>
        <div class="spotlight-pulse__items" :class="{ 'show-loading-grid': anyLoading }">
          <template v-if="pulseItems.length > 0">
            <OnxPulseItem
              v-for="item in pulseItems.slice(0, maxNewItemsToShow)"
              :key="JSON.stringify(item)"
              :item="item"
              :isNew="!itemsRecentlyMarkedRead.includes(item)"
              @mark-read="markOneRead(item)"
              @navigate="hide"
            />
          </template>
          <div class="spotlight-pulse__no-items" v-else>No items</div>
          <LoaderGrid v-if="anyLoading" overlay="true" />
        </div>
      </OnxPaper>
    </template>
  </VDropdown>
</template>

<style lang="scss">
@use 'scss/variables.module' as *;
@import 'scss/onx-breakpoints.module';

.onx-spotlight-pulse__icon {
  height: 48px;
  width: 48px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: var(--light);
  }

  .onx-tag {
    position: absolute;
    top: 6px;
    right: 2px;
    background-color: red;
  }
}

.spotlight-pulse__container {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: auto;
  margin-left: 0;
  margin-right: 0;
  box-shadow:
    0 10px 20px rgba(0, 0, 0, 0.19),
    0 6px 6px rgba(0, 0, 0, 0.23);

  @include tablet() {
    width: 640px;
  }
}

.spotlight-pulse__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid var(--charcoal-100);
  height: 54px;
  padding-left: 1rem;
  height: 70px;

  @include tablet() {
    padding-left: 0;
    height: 70px;
  }

  &__icon {
    // Don't show the icon when the header is too narrow, to save space
    display: none;
    width: 3rem;
    height: 2rem;
    align-items: center;
    justify-content: center;

    @include tablet() {
      display: flex;
    }
  }

  &__text {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 0.5rem;
    align-items: center;
  }

  &__title {
    font-weight: bold;

    // save space with a smaller header title on smaller screens
    font-size: 18px;
    @include tablet() {
      font-size: 24px;
    }
  }

  &__country {
    font-size: 12px;
    color: var(--charcoal-200);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;
    flex-shrink: 1;
    display: none;

    @include phablet() {
      display: block;
      position: relative;
      top: -0.5em;
    }
  }

  &__mark-all-read {
    white-space: nowrap;
    background: white;
    border: none;
    color: var(--charcoal-300);
    cursor: pointer;
    font-size: small;
    height: 2rem;
    line-height: 2rem;
    padding: 0 0.5rem;

    &.is_marking_read {
      color: var(--charcoal-200);
      cursor: not-allowed;
    }

    &:hover {
      background: var(--light);
    }

    &:active {
      background: var(--charcoal-100);
    }
  }

  &__close {
    align-items: center;
    background: white;
    border: none;
    color: var(--charcoal-300);
    cursor: pointer;
    display: flex;
    height: 2rem;
    justify-content: center;
    margin-right: 1rem;
    width: 2rem;

    &:hover {
      background: var(--light);
    }

    &:active {
      background: var(--charcoal-100);
    }
  }
}

.spotlight-pulse__controls {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-right: 1rem;
  padding-left: 0.5rem;
  height: 3rem;
  border-bottom: 1px solid var(--charcoal-100);

  &__filters {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    &__icon {
      width: 2rem;
      height: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &:hover {
        background: var(--light);
      }
    }
  }
}

.spotlight-pulse__items {
  display: flex;
  flex-direction: column;
  position: relative;
  max-height: 550px;
  overflow-y: scroll;
  gap: 1px;

  /* Bottom borders, but not the last item */
  > .spotlight-pulse__item {
    border-bottom: 1px solid var(--charcoal-100);

    &:last-child {
      border-bottom: none;
    }
  }

  /* Make sure there's enough room to show the loading icon even when there's no items yet */
  &.show-loading-grid {
    min-height: 300px;
  }
}

.spotlight-pulse__no-items {
  font-size: normal;
  color: var(--charcoal-200);
  padding: 1rem;
}
</style>
