/**
 * Creates a metric key of the format <metricSubtype><userGroup>_<connectionCategory>
 *
 * @param metricSubtype e.g videoexperience
 * @param userGroup e.g main, 5gusers, 4gusers
 * @param connectionCategory e.g 5g, lte, 3g
 *
 * Given videoexperience, main, 5g, it will return videoexperience_5g. "main" user group is ignored
 * Given videoexperience, 5gusers, 5g, it will return videoexperience5gusers_5g
 */
const createMetricKey = (metricSubtype: string, userGroup: string, connectionCategory: string) => {
  const parsedUserGroup = userGroup === 'main' ? '' : userGroup;

  return `${metricSubtype}${parsedUserGroup}_${connectionCategory}`;
};

export default createMetricKey;
