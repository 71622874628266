<script setup lang="ts">
import useMetricMinMax from '@/components/onx/composables/useMetricMinMax';

import type { MetricDescriptor } from '@/types/MetricDescriptor';
import type { IntlSpotlightOverviewDatum } from '@/intl-spotlight/types';

import IntlRankingTable from '@/intl-spotlight/overview-tiles/IntlRankingTable.vue';
// import ScoreChangeTable from '@/components/comparison/ScoreChangeTable.vue';
import OnxPaper from '@/components/onx/OnxPaper.vue';

type Props = {
  metric: MetricDescriptor;
  data: Array<IntlSpotlightOverviewDatum>;
  showScoreChange?: boolean;
  hideContent?: boolean;
  hideActions?: boolean;
};

const props = defineProps<Props>();
const emit = defineEmits(['ranking-table:click', 'header:click']);

const { metricMinMax } = useMetricMinMax(props.metric, props.data);
</script>

<template>
  <OnxPaper class="onx-metric-box">
    <div
      v-if="$slots.header"
      class="onx-metric-box__header"
      :class="{
        'onx-metric-box__header--no-border': !data.length || hideContent,
      }"
      @click="emit('header:click')"
    >
      <slot name="header"></slot>
    </div>

    <slot v-if="data.length && !hideContent">
      <div class="onx-metric-box__content">
        <IntlRankingTable
          v-if="!showScoreChange"
          :bigger-better="metric.bigger_is_better"
          :data="props.data"
          :min="metricMinMax.min"
          :max="metricMinMax.max"
          @click="emit('ranking-table:click', $event)"
        />
        <!-- <ScoreChangeTable v-else :data="props.data" /> -->
      </div>
    </slot>

    <div v-if="$slots.footer && !hideActions" class="onx-metric-box__footer">
      <slot name="footer"></slot>
    </div>
  </OnxPaper>
</template>
