import { AxiosResponse } from 'axios';
import { Ref, computed } from 'vue';
import { MetricResponse } from '@/types/MetricResponse';
import { PaginatedMetricResponse } from '@/types/PaginatedMetricResponse';

const useMetricSource = (
  metricResponse: Ref<AxiosResponse<MetricResponse<any> | PaginatedMetricResponse<any>>> | Ref<undefined>,
) => {
  const metricSource = computed(() => {
    return metricResponse.value?.data.source;
  });

  return {
    metricSource,
  };
};

export default useMetricSource;
