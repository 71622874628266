import type { MetricDescriptor } from '@/types/MetricDescriptor';

const menuItem = (options: {
  label: string;
  value: MetricDescriptor;
  active: boolean;
  connectionCategory?: string;
  disabled?: boolean;
  highlighted?: boolean;
  inset?: boolean;
}) => {
  const { active, disabled = false, highlighted = false, inset = false, label, value } = options;

  return {
    label,
    value,
    active,
    disabled,
    highlighted,
    inset,
  };
};

export default menuItem;
