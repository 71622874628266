import mapMarkerSvg from '@/intl-spotlight/mapMarker.svg?raw';
import noRankSvg from '@/intl-spotlight/noRank.svg?raw';

const createMarker = (rank: number | null | undefined) => {
  const marker = document.createElement('div');
  marker.innerHTML = mapMarkerSvg.trim();

  if (rank) {
    const text = document.createElement('div');
    text.classList.add('intl-spotlight-by-geography__map-marker__text');
    text.textContent = rank.toString();
    marker.appendChild(text);
  } else {
    const noRankIcon = noRankSvg.trim();

    const noRankContainer = document.createElement('div');
    noRankContainer.classList.add('intl-spotlight-by-geography__map-marker__text');
    noRankContainer.innerHTML = noRankIcon;
    marker.appendChild(noRankContainer);
  }

  return marker;
};

export default createMarker;
