import { computed, MaybeRef, unref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { format } from 'date-fns';

import type { MetricStructuresEnum } from '@/types/MetricStructures';
import type { GeocodingConfigResponse } from '@/types/GeocodingConfigResponse';
import osApi from '@/api/osApi';
import { Dashboards } from '@/constants/dashboards';

type Options = {
  locationId: MaybeRef<number | undefined>;
  metric: MaybeRef<string>;
  aggregation: MaybeRef<string>;
  endDate: MaybeRef<string | Date>;
  operatorInfo?: MaybeRef<boolean>;
  compareAllIntervals?: MaybeRef<boolean>;
  compareInterval?: MaybeRef<string>;
  comparison?: MaybeRef<boolean>;
  enabled?: MaybeRef<boolean>;
  mnosOnly?: MaybeRef<boolean>;
};

/**
 * Retrieves metric data for a polygon by locationId
 */
const useLocationOverview = <T extends MetricStructuresEnum>(dashboard: Dashboards, options: Options) => {
  const {
    aggregation,
    compareAllIntervals,
    compareInterval,
    comparison,
    enabled,
    endDate,
    locationId,
    metric,
    mnosOnly,
    operatorInfo,
  } = options;

  const localEnabled = computed(() => {
    const hasMetricAndLocation = Boolean(unref(metric)) && Boolean(unref(locationId));
    if (enabled !== undefined) {
      return unref(enabled) && hasMetricAndLocation;
    }

    return hasMetricAndLocation;
  });

  const queryParams = computed(() => {
    const _endDate = unref(endDate);

    return {
      metric_list: unref(metric),
      aggregation_type: unref(aggregation),
      operator_info: unref(operatorInfo),
      compare_all_intervals: unref(compareAllIntervals),
      comparison: unref(comparison),
      compare_interval: unref(compareInterval),
      end_date: typeof _endDate === 'string' ? _endDate : format(_endDate, 'yyyy-MM-dd'),
      mnos_only: unref(mnosOnly),
    };
  });

  return useQuery({
    queryKey: ['location-overview', locationId, metric, aggregation, compareAllIntervals, operatorInfo, endDate],
    queryFn: () =>
      osApi.get<GeocodingConfigResponse<T>>(
        `${dashboard}/overview/location/${unref(locationId)}/${unref(aggregation)}/`,
        {
          params: queryParams.value,
        },
      ),
    staleTime: 24 * 60 * 60 * 1000,
    enabled: localEnabled,
  });
};

export default useLocationOverview;
