import { computed } from 'vue';
import { useRoute, RouteLocationNormalized } from 'vue-router';
import ROUTE_GROUPS from '@/constants/routeGroups';

export enum PRODUCT {
  FOCUS,
  SPOTLIGHT,
  THREE_SIXTY,
}

const routeMatchesGroup = (route: RouteLocationNormalized, group: string[]) => {
  return route.matched.some((record) => record.name && group.includes(record.name as string));
};

/**
 * Given the current route, returns info about the current product.
 */
export const useProduct = () => {
  const route = useRoute();

  const product = computed(() => {
    if (routeMatchesGroup(route, ROUTE_GROUPS.Focus)) {
      return PRODUCT.FOCUS;
    }

    if (routeMatchesGroup(route, [...ROUTE_GROUPS.Spotlight, ...ROUTE_GROUPS.IntlSpotlight])) {
      return PRODUCT.SPOTLIGHT;
    }

    if (routeMatchesGroup(route, ROUTE_GROUPS.ThreeSixty)) {
      return PRODUCT.THREE_SIXTY;
    }

    return null;
  });

  return {
    product,
  };
};
