import { inject, onUnmounted, Ref, watch } from 'vue';
import { SharedBoundsInjectType, sharedBoundsKey } from '.';
import { MinMax } from '@/chart-metric-definitions/data-transformers/getMinMax';

type Args = {
  minMax: Ref<MinMax | undefined>;
  key: Ref<string>;
};

export const useSharedBounds = ({ key, minMax }: Args) => {
  const sharedBounds = inject<SharedBoundsInjectType | undefined>(sharedBoundsKey, undefined);
  watch(minMax, (newMinMax, oldMinMax) => {
    if (sharedBounds) {
      if (!newMinMax) {
        sharedBounds.value.removeBounds(key.value);
      } else if (!oldMinMax || newMinMax.min !== oldMinMax.min || newMinMax.max !== oldMinMax.max) {
        sharedBounds.value.addBounds({
          key: key.value,
          min: newMinMax.min,
          max: newMinMax.max,
        });
      }
    }
  });
  onUnmounted(() => {
    sharedBounds?.value.removeBounds(key.value);
  });

  return sharedBounds;
};
