<script setup lang="ts">
import { toRefs, computed } from 'vue';

import { type ChartRendererProps } from '@/chart-metric-definitions/ChartRendererProps';
import { isDatasetChartMetricDefinition } from '@/chart-metric-definitions/MetricDefinition';
import useCurrentDashboardName from '@/composables/useCurrentDashboardName';
import ChartRenderer from '@/chart-metric-definitions/ChartRenderer.vue';
import { createDatasetsApiQueryFn } from '@/chart-metric-definitions/createDatasetsApiQueryFn';

type Props = Omit<ChartRendererProps, 'queryFn'>;

const props = defineProps<Props>();
const { aggregation, connectionCategory, enabled, geohashes, location, operators } = toRefs(props);

const dashboard = useCurrentDashboardName();
const metricDefinition = props.metric;

if (!isDatasetChartMetricDefinition(metricDefinition)) {
  throw new Error('Missing dataset in metric definition for DatasetsAPIChartRenderer');
}

const connectionCategories = computed(() => {
  if (connectionCategory.value) {
    return [connectionCategory.value];
  }

  return undefined;
});

const canonicalNetworkIDs = computed(() => {
  return operators.value.map((operator) => operator.canonical_network_id);
});

const queryEnabled = computed(() => {
  return canonicalNetworkIDs.value.length > 0 && enabled.value;
});

const queryFn = createDatasetsApiQueryFn(dashboard.value, metricDefinition.dataset, location, {
  enabled: queryEnabled,
  canonicalNetworkIDs,
  connectionCategories,
  aggregation,
  geohashes,
  // Looks cool when the data doesn't disappear when loading next, and it's better than the "no data" and title flicker issues,
  // but the charts keep growing in height ¯\_(ツ)_/¯
  keepPreviousData: false,
  ...(metricDefinition.requestParams || {}),
});
</script>

<template>
  <ChartRenderer v-bind="props" :query-fn="queryFn" />
</template>
