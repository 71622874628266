import osApi from '@/api/osApi';
import { MetricSubtypes } from '@/types/MetricSubtypes';
import { MetricResponse } from '@/types/MetricResponse';
import { MetricStructuresEnum } from '@/types/MetricStructures';

import { Dashboards } from '@/constants/dashboards';

import { exportToCsv } from '@/utils/files';

import { type OvershootingScoreTableRow } from '@/chart-metric-definitions/data-transformers/prepareOvershootingScoreForTable';
import { overshootingScoreColumns } from '@/360/base-station/table-columns/overshootingScoreColumns';

const exportOvershootingScoreTable = async (
  _: any,
  title: string,
  {
    location,
    otherRequestParams,
    transform,
  }: {
    location: number;
    otherRequestParams: { period_split: 0 | 1 };
    transform: (data: any) => any;
  },
) => {
  const url = `/${Dashboards.ThreeSixty}/metrics/${MetricSubtypes.OvershootingScoreTableFull}_overall/${location}/90days/`;

  const response = await osApi.get<MetricResponse<MetricStructuresEnum.OvershootingScoreForTable>>(url, {
    params: {
      period_split: otherRequestParams.period_split,
    },
  });

  const fullTitle = `${title} - ${otherRequestParams.period_split === 0 ? 'Previous Period' : 'Current Period'}`;

  const transformedData = transform(response.data) as OvershootingScoreTableRow[];
  const rows = transformedData.map((row) => overshootingScoreColumns.map((column) => column.value(row)));
  const headers = overshootingScoreColumns.map((column) => column.header);

  exportToCsv(`${fullTitle}.csv`, [headers, ...rows]);
};

export default exportOvershootingScoreTable;
