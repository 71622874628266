import type { Feature } from 'geojson';

type Callbacks = {
  onEnter?: (feature: Feature) => void;
  onLeave?: (feature: Feature) => void;
};

const useMapPolygonHoverEffect = (polygonSourceName: string, polygonFillsLayerName: string, callbacks?: Callbacks) => {
  const setupHoverEffect = (map: mapboxgl.Map) => {
    // When the user moves their mouse over the fills layer, we'll update the
    // feature state for the feature under the mouse.
    let hoveredPolygonId: number = -1;
    let hoveredFeature: Feature | null = null;
    map.on('mouseenter', polygonFillsLayerName, (e) => {
      if (!e.features?.length) {
        return;
      }

      const feature = e.features?.[0];

      if (!feature) {
        return;
      }

      if (hoveredPolygonId != null) {
        map.setFeatureState({ source: polygonSourceName, id: hoveredPolygonId }, { hover: false });
      }

      hoveredPolygonId = feature.id || feature.properties?.id;
      hoveredFeature = feature;
      map.setFeatureState({ source: polygonSourceName, id: hoveredPolygonId }, { hover: true });

      if (typeof callbacks?.onEnter === 'function') {
        callbacks.onEnter(feature);
      }
    });

    // When the mouse leaves the fills layer, update the feature state of the
    // previously hovered feature.
    map.on('mouseleave', polygonFillsLayerName, () => {
      if (hoveredPolygonId !== null) {
        map.setFeatureState({ source: polygonSourceName, id: hoveredPolygonId }, { hover: false });
      }

      hoveredPolygonId = -1;

      if (typeof callbacks?.onLeave === 'function' && hoveredFeature) {
        callbacks.onLeave(hoveredFeature);
        hoveredFeature = null;
      }
    });
  };

  return { setupHoverEffect };
};

export default useMapPolygonHoverEffect;
