<script setup lang="ts">
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { RouterLink } from 'vue-router';

/** Just like RouterLink, but includes query params. */

export type Props = {
  to: string | { name: string };
};

const props = defineProps<Props>();
const route = useRoute();
const router = useRouter();

const actualTo = computed(() => {
  const { fullPath, path } = route;

  if (typeof props.to === 'string') {
    return fullPath.replace(path, props.to);
  }

  const resolvedRoute = router.resolve({ name: props.to.name });
  return fullPath.replace(path, resolvedRoute.path);
});
</script>
<template>
  <RouterLink :to="actualTo">
    <slot></slot>
  </RouterLink>
</template>
