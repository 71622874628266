import { computed } from 'vue';

import { SPOTLIGHT_METRICS, OS_GEOCODINGS } from '@/constants/constants';

import useMetrics from '@/components/onx/composables/useMetrics';
import useGeocodingConfig from '@/composables/useGeocodingConfig';
import useSpotlightQueryParams from '@/components/specialized/useSpotlightQueryParams';
import useCurrentDashboardName from '@/composables/useCurrentDashboardName';
import useEndDate from '@/composables/useEndDate';

import type { MetricStructuresEnum } from '@/types/MetricStructures';

const useIntlSpotlightOverviewMetrics = () => {
  const dashboard = useCurrentDashboardName();
  const { metrics } = useMetrics();
  const queryParams = useSpotlightQueryParams();
  const { currentEndDate } = useEndDate(dashboard.value);

  // Create a list of metric keys to request from the API
  const metricsToRequest = computed(() => {
    const metricKeys = SPOTLIGHT_METRICS.map((pill) => {
      // Find a metric by kind (video, voice etc.), type (== connection category) and user group
      const descriptor = metrics.value.find(
        (m) =>
          m.kind === pill.key &&
          m.type === queryParams.connectionCategory.toValue() &&
          m.subcategory === queryParams.userGroup.toValue(),
      );

      return descriptor?.key;
    });

    return metricKeys.filter((m) => m).join(',');
  });

  const query = useGeocodingConfig<MetricStructuresEnum.RankedSimple>(dashboard.value, {
    metric: metricsToRequest,
    geocoding: OS_GEOCODINGS.countries,
    aggregation: queryParams.agg.selectedValue,
    endDate: currentEndDate,
    operatorInfo: true,
    compareAllIntervals: true,
  });

  return query;
};

export default useIntlSpotlightOverviewMetrics;
