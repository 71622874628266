import transformSimpleStructureSubmetricsByOperator from '@/chart-metric-definitions/data-transformers/transformSimpleStructureSubmetricsByOperator';
import simpleStructureSubmetricByOperatorTooltip from '@/chart-metric-definitions/tooltips/simpleStructureSubmetricByOperatorTooltip';
import { ChartMetricDefinition } from '@/chart-metric-definitions/MetricDefinition';
import { ChartTypesEnum } from '@/types/Charts';
import { MetricSubtypes } from '@/types/MetricSubtypes';
import { METRIC_TYPES } from '@/constants/constants';
import exportDatasetToCsv from '@/utils/exportDatasetToCsv';

const focusRANHealthSiteMapperCharts: ChartMetricDefinition[] = [
  {
    metricSubtype: MetricSubtypes.ENodeBCount,
    chartType: ChartTypesEnum.BarChart,
    connectionCategory: METRIC_TYPES.Overall,
    chartAttrs: {
      horizontal: true,
      nbDays: 0,
      chartTitlePlaceholders: 'eNodeB Count by Operator',
    },
    chartContainerClass: 'onx-col-12 lg:onx-col-6',
    get tooltipPlugin() {
      return simpleStructureSubmetricByOperatorTooltip({ horizontal: !!this.chartAttrs?.horizontal });
    },
    transformData: transformSimpleStructureSubmetricsByOperator,
    onExportCsv: exportDatasetToCsv({
      headers: ['Operator', 'Count', 'Date'],
      getRow: (datum, dataset, date) => {
        return [dataset.label, datum[dataset.dataAxis].toFixed(2), date];
      },
    }),
  },
  {
    metricSubtype: MetricSubtypes.FirstRecordDateAvgCellSiteCount,
    chartType: ChartTypesEnum.Trend,
    connectionCategory: METRIC_TYPES.Overall,
    chartAttrs: {
      horizontal: true,
      chartTooltipShowSecondaryValue: true,
      chartTitlePlaceholders: 'Cell Site Counts Over Time',
    },
    chartContainerClass: 'onx-col-12 lg:onx-col-6',
    onExportCsv: exportDatasetToCsv({
      headers: ['Operator', 'Date', 'Count'],
      getRow: (datum, dataset, date) => {
        return [dataset.label, date, datum.y];
      },
    }),
  },
];

export default focusRANHealthSiteMapperCharts;
