<script setup lang="ts">
import OnxTab, { Props } from './OnxTab.vue';

const props = defineProps<Props>();
</script>

<template>
  <OnxTab v-bind="props" class="onx-header-tab">
    <slot></slot>
  </OnxTab>
</template>

<style lang="scss">
.onx-header-tab {
  background: var(--charcoal-0);
  border-bottom: none;
  border-top: 2px solid transparent;

  &.onx-tabs__tab--active {
    background: var(--white);
    color: var(--onx-tab-active-color);
    border-top: 2px solid var(--onx-tab-active-color);
  }
}
</style>
