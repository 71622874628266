import type {
  MetricsAPIDataTransformerConstructor,
  DataTransformerFnOptions,
  DataTransformerReturnedItem,
} from '@/chart-metric-definitions/data-transformers/DataTransformerFnType';
import type { ConfidenceCounts, MetricStructuresEnum } from '@/types/MetricStructures';
import { allConfidenceIntervals } from '@/360/constants/allConfidenceIntervals';

const transformConfidenceIntervalsByOperator: MetricsAPIDataTransformerConstructor<
  MetricStructuresEnum.ConfidenceCounts,
  Required<Pick<DataTransformerFnOptions, 'mainOperator'>>
> =
  ({ mainOperator }) =>
  (response, horizontal?: boolean): DataTransformerReturnedItem[] => {
    const dataAxis = horizontal ? 'x' : 'y';
    const labelAxis = horizontal ? 'y' : 'x';

    const confidenceCounts: ConfidenceCounts | undefined = response.results.find((datum) => {
      // Sometimes when using LoopOver the main operator isn't yet ready, or something
      return mainOperator?.canonical_network_id === datum.canonical_network_id;
    });

    if (!confidenceCounts) {
      return [];
    }

    const color = `#${mainOperator.hex_color}`;

    return [
      {
        data: allConfidenceIntervals.map((ci) => ({
          date: confidenceCounts.date, // string
          [dataAxis]: confidenceCounts.counts[ci], // number
          [labelAxis]: ci, // string
        })),
        type: 'bar',
        backgroundColor: color,
        color,
        label: mainOperator.name_mapped,
        yAxisID: 'y',
        xAxisID: 'x',
        dataAxis,
        labelAxis,
        meta: {
          imageExportLegend: {
            color,
            label: mainOperator.name_mapped,
          },
        },
      },
    ];
  };

export default transformConfidenceIntervalsByOperator;
