import { computed } from 'vue';
import { useStore } from 'vuex';

import {
  type ChartMetricDefinition,
  isDatasetChartMetricDefinition,
} from '@/chart-metric-definitions/MetricDefinition';
import { type AvailableConnectionCategory } from '@/constants/allPossibleConnectionCategories';
import { useDatasetDescriptors } from '@/composables/useDatasetDescriptors';

const useCheckIfMetricOrDatasetKeyExists = () => {
  const store = useStore();
  const { datasets, isLoading } = useDatasetDescriptors();

  function checkIfMetricOrDatasetKeyExists(
    metricDefinition: ChartMetricDefinition,
    connectionCategory?: AvailableConnectionCategory['categoryValue'],
  ): boolean;
  function checkIfMetricOrDatasetKeyExists(metricKeyOrDatasetKey: string): boolean;
  function checkIfMetricOrDatasetKeyExists(
    metricKeyOrDatasetKeyOrDefinition: string | ChartMetricDefinition,
    connectionCategory?: AvailableConnectionCategory['categoryValue'],
  ): boolean {
    const metricsByIdentifier = store.getters['metrics/byIdentifier'];

    if (typeof metricKeyOrDatasetKeyOrDefinition === 'string') {
      return (
        !!metricsByIdentifier[metricKeyOrDatasetKeyOrDefinition] ||
        datasets.value?.byKey[metricKeyOrDatasetKeyOrDefinition] !== undefined
      );
    }

    if (isDatasetChartMetricDefinition(metricKeyOrDatasetKeyOrDefinition)) {
      return Boolean(datasets.value?.byKey[metricKeyOrDatasetKeyOrDefinition.dataset]);
    }

    if (!connectionCategory) {
      throw new Error('Connection category is required for metric definition with metricSubtype');
    }

    return !!metricsByIdentifier[`${metricKeyOrDatasetKeyOrDefinition.metricSubtype}_${connectionCategory}`];
  }

  const ready = computed(() => {
    const existingMetricSubtypes = store.getters['metrics/subtypes'];
    return Object.keys(existingMetricSubtypes).length > 0 && !isLoading.value;
  });

  return { checkIfMetricOrDatasetKeyExists, ready };
};

export default useCheckIfMetricOrDatasetKeyExists;
