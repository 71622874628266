<script setup lang="ts">
import { computed } from 'vue';

import { MenuTitle } from '@/components/menu';

import SpotlightMenuConnectionCategory from '@/intl-spotlight/SpotlightMenuConnectionCategory.vue';
import SpotlightMenuEndDate from '@/intl-spotlight/SpotlightMenuEndDate.vue';
import SpotlightMenuAggregation from '@/intl-spotlight/SpotlightMenuAggregation.vue';
import SpotlightMenuUserGroup from '@/intl-spotlight/SpotlightMenuUserGroup.vue';

import useSpotlightQueryParams from '@/components/specialized/useSpotlightQueryParams';
import useMetrics from '@/components/onx/composables/useMetrics';

import getSpotlightOverviewConnectionCategories from '@/components/specialized/getSpotlightOverviewConnectionCategories';

type Props = {
  openUserGroupInfoModal: () => void;
};

defineProps<Props>();

const queryParams = useSpotlightQueryParams();

const { metrics } = useMetrics();
const connectionCategories = computed(() => {
  return getSpotlightOverviewConnectionCategories(metrics.value, queryParams.userGroup.toValue());
});
</script>
<template>
  <div class="CompetitiveMenu__content">
    <MenuTitle label="Parameters" theme="ci" />

    <SpotlightMenuEndDate />
    <SpotlightMenuAggregation />

    <div class="ParametersLayout">
      <div class="ParametersLayout__grid">
        <SpotlightMenuConnectionCategory :connection-categories="connectionCategories" />
        <SpotlightMenuUserGroup
          :open-user-group-info-modal="openUserGroupInfoModal"
          :connection-category="queryParams.connectionCategory.selectedValue"
          :metrics="metrics"
        />
      </div>
    </div>
  </div>
</template>
