<script setup lang="ts">
import { toRefs } from 'vue';
import type { ChartRendererProps } from '@/chart-metric-definitions/ChartRendererProps';

import useCurrentDashboardName from '@/composables/useCurrentDashboardName';

import { createMetricsApiQueryFn } from '@/chart-metric-definitions/createMetricsApiQueryFn';
import ChartRenderer from '@/chart-metric-definitions/ChartRenderer.vue';

type Props = Omit<ChartRendererProps, 'queryFn'>;

const props = withDefaults(defineProps<Props>(), {
  enabled: true,
});

const dashboard = useCurrentDashboardName();

const { aggregation, bbox, connectionCategory, deploymentType, enabled, geohashes, location, metric } = toRefs(props);

const queryFn = createMetricsApiQueryFn(dashboard.value, {
  metric: `${metric.value.metricSubtype}_${connectionCategory.value}`,
  location,
  aggregation,
  geohashes,
  bbox,
  deploymentType,
  enabled,
});
</script>

<template>
  <ChartRenderer v-bind="props" :query-fn="queryFn" />
</template>
