import omit from 'lodash/omit';

import type {
  DatasetsAPIDataTransformerConstructor,
  DataTransformerFnOptions,
  DataTransformerReturnedItem,
} from '@/chart-metric-definitions/data-transformers/DataTransformerFnType';
import type { DatasetsAPIDataStructuresEnum } from '@/types/MetricStructures';

const thresholdByCqType = {
  ecq: {
    download: '5 Mbps',
    upload: '1.5 Mbps',
    latency: '50ms',
    icmp_latency: '50ms',
    jitter: '12ms',
    packet_loss: '1%',
    ttfb: '0.8s',
  },
  ccq: {
    download: '1.5 Mbps',
    upload: '500 Kbps',
    latency: '100ms',
    icmp_latency: '100ms',
    jitter: '20ms',
    packet_loss: '4%',
    ttfb: '1.2s',
  },
};

// This is exported to be used in the ticks callback function for ecq_score_performance_driver and ccq_score_performance_driver,
// where the labels are split into arrays. ChartJS treats array labels as multi-line labels. This is done because labels don't fit on one line,
// and, by default, ChartJS rotates them when that happens.
export const ttfbMeetingThresholdLabel = 'Time to First Byte Meeting Threshold';
export const createThresholdConfigs = (cq: 'ecq' | 'ccq') => {
  const cqLabel = cq.toUpperCase();
  return {
    [`est_${cq}_download_met`]: {
      label: 'Download Meeting Threshold',
      tooltipLabel: `${cqLabel} if Download Threshold met (${thresholdByCqType[cq].download})`,
      color: '#003D40',
    },
    [`est_${cq}_upload_met`]: {
      label: 'Upload Meeting Threshold',
      tooltipLabel: `${cqLabel} if Upload Threshold met (${thresholdByCqType[cq].upload})`,
      color: '#1C5356',
    },
    [`est_${cq}_latency_met`]: {
      label: 'Latency Meeting Threshold',
      tooltipLabel: `${cqLabel} if Latency Threshold met (${thresholdByCqType[cq].latency})`,
      color: '#797D7F',
    },
    [`est_${cq}_icmp_latency_met`]: {
      label: 'ICMP Latency Meeting Threshold',
      tooltipLabel: `${cqLabel} if ICMP Latency Threshold met (${thresholdByCqType[cq].icmp_latency})`,
      color: '#797D7F',
    },
    [`est_${cq}_jitter_met`]: {
      label: 'Jitter Meeting Threshold',
      tooltipLabel: `${cqLabel} if Jitter Threshold met (${thresholdByCqType[cq].jitter})`,
      color: '#797D7F',
    },
    [`est_${cq}_packet_loss_met`]: {
      label: 'Packet Discard Meeting Threshold',
      tooltipLabel: `${cqLabel} if Packet Discard Threshold met (${thresholdByCqType[cq].packet_loss})`,
      color: '#797D7F',
    },
    // Apparently this ttff is mislabeled, and is instead TTFB.
    // TTFF is not meant to be here, only TTFB.
    // These are duplicated because to hopefully prevent the chart breaking when est_ecq_ttff_met changes to est_ecq_ttfb_met.
    // Please remove `est_${cq}_ttff_met` when the change is made.
    [`est_${cq}_ttff_met`]: {
      label: ttfbMeetingThresholdLabel,
      tooltipLabel: `${cqLabel} if Time to First Byte Threshold met (${thresholdByCqType[cq].ttfb})`,
      color: '#797D7F',
    },
    [`est_${cq}_ttfb_met`]: {
      label: ttfbMeetingThresholdLabel,
      tooltipLabel: `${cqLabel} if Time to First Byte Threshold met (${thresholdByCqType[cq].ttfb})`,
      color: '#797D7F',
    },
  };
};

const thresholdLineColor = '#CC8816';

const transformScorePerformanceDrivers = (
  cq: 'ecq' | 'ccq',
): DatasetsAPIDataTransformerConstructor<
  DatasetsAPIDataStructuresEnum.EcqScorePerformanceDrivers | DatasetsAPIDataStructuresEnum.CcqScorePerformanceDrivers,
  Pick<DataTransformerFnOptions, 'mainOperator' | 'operators'>,
  { datasets: DataTransformerReturnedItem[]; labels: string[] }
> => {
  return ({ mainOperator, operators }) => {
    return (response, horizontal?: boolean) => {
      const dataAxis = horizontal ? 'x' : 'y';
      const labelAxis = horizontal ? 'y' : 'x';

      if (!mainOperator || !operators?.length) {
        return { datasets: [], labels: [], min: 0 };
      }

      /**
       * Parse data for all operators. This will help determine min / max bounds.
       */
      const bars = operators.reduce(
        (acc, operator) => {
          const datum = response.data.find((datum) => datum.canonical_network_id === operator.canonical_network_id);

          if (!datum) {
            return acc;
          }

          const values = omit(datum, ['canonical_network_id', 'location', 'date', cq]);
          const valueEntries = Object.entries(values);
          const thresholdConfigs = createThresholdConfigs(cq);

          acc[operator.name_mapped] = valueEntries.map(([key, value]) => {
            const thresholdConfig = thresholdConfigs[key];

            return {
              type: 'bar',
              data: [
                {
                  ...datum,
                  operatorName: operator.name_mapped,
                  [dataAxis]: value,
                  [labelAxis]: thresholdConfig.label,
                },
              ],
              backgroundColor: thresholdConfig.color,
              color: thresholdConfig.color,
              label: thresholdConfig.label,
              tooltipLabel: thresholdConfig.tooltipLabel,
              yAxisID: 'y',
              xAxisID: 'x',
              dataAxis,
              labelAxis,
              order: 1,
              meta: {
                imageExportLegend: {
                  color: thresholdConfig.color,
                  label: thresholdConfig.label,
                  y: value,
                },
              },
            };
          });

          const threshold = 'ecq' in datum ? datum.ecq : datum.ccq; // line value
          const thresholdLine = {
            type: 'line',
            data: acc[operator.name_mapped].map(() => threshold),
            backgroundColor: thresholdLineColor,
            borderColor: thresholdLineColor,
            color: thresholdLineColor,
            label: `${cq.toUpperCase()} Score (current)`,
            xAxisID: 'xThresholdAxis',
            yAxisID: 'y',
            dataAxis,
            labelAxis,
            pointRadius: 0,
            get meta() {
              return {
                imageExportLegend: {
                  color: this.color,
                  label: this.label,
                  y: threshold,
                },
                operator,
              };
            },
          };

          acc[operator.name_mapped].push(thresholdLine);

          return acc;
        },
        {} as Record<string, DataTransformerReturnedItem[]>,
      );

      const labels = bars[mainOperator.name_mapped].slice(0, -1).map((bar) => bar.label);

      return {
        datasets: bars[mainOperator.name_mapped],
        labels,
      };
    };
  };
};

export default transformScorePerformanceDrivers;
