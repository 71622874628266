<script lang="ts" setup>
import useMetrics from '@/components/onx/composables/useMetrics';
import useSpotlightQueryParams from '@/components/specialized/useSpotlightQueryParams';
import ChartWrapperExtended from '@/components/visual/ChartWrapperExtended.vue';
import LineChart from '@/components/visual/chart/LineChart.vue';
import useCurrentDashboardName from '@/composables/useCurrentDashboardName';
import useGeocodingConfig from '@/composables/useGeocodingConfig';
import { AGGREGATIONS, METRIC_TYPE_NAMES, OS_GEOCODINGS, USER_GROUPS } from '@/constants/constants';
import { API_LONG_DATE_FORMAT } from '@/constants/dateFormats';
import { MetricDescriptor } from '@/types/MetricDescriptor';
import type { MetricStructuresEnum } from '@/types/MetricStructures';
import { MetricTypeNamesIndex } from '@/types/MetricTypeNamesIndex';
import { Operator } from '@/types/Operator';
import { fixHexColor } from '@/utils/helpers';
import { getDatumValue } from '@/utils/viewHelpers';
import { format, subDays } from 'date-fns';
import { computed, ref, toRefs, unref } from 'vue';
import { useStore } from 'vuex';

type Props = {
  aggregation: string;
  endDate: Date;
  metric: string;
  userGroup?: string;
};

const props = defineProps<Props>();
const { aggregation, endDate, metric, userGroup } = toRefs(props);

const { metricsByIdentifier } = useMetrics();
const dashboard = useCurrentDashboardName();
const store = useStore();
const nbDays = computed(() => {
  return store.getters['competitive/timeframeDays'];
});
const geocodingConfig = useGeocodingConfig<MetricStructuresEnum.RankedSimple>(dashboard.value, {
  aggregation,
  metric,
  endDate,
  operatorInfo: true,
  enabled: ref(true),
  nbDays: nbDays,
  geocoding: ref(OS_GEOCODINGS.countries),
});

type LineChartDatum = {
  x: string;
  date: string;
  y: number;
  lci: number;
  uci: number;
  rank?: number;
};

type DataSeries = {
  label: string;
  color: string;
  backgroundColor: string;
  operator: Operator;
  data: LineChartDatum[];
};

const trendSeries = computed(() => {
  if (!geocodingConfig.data.value?.data) {
    return undefined;
  }

  const data = geocodingConfig.data.value.data;
  const trendSeries: Record<string, DataSeries> = data.results.reduce(
    // network ID => DataSeries
    (accumulator, currentDatum) => {
      const networkIdString = `${currentDatum.canonical_network_id}`;
      const formattedDatum: LineChartDatum = {
        x: currentDatum.date,
        date: currentDatum.date,
        y: getDatumValue(currentDatum),
        lci: currentDatum.lci,
        uci: currentDatum.uci,
        rank: currentDatum.rank,
      };

      if (accumulator.hasOwnProperty(networkIdString)) {
        // if we need to add this to an existing series
        accumulator[networkIdString].data.push(formattedDatum);
      } else {
        // or start a new series for this operator, by network id
        const operator = (data.operators as Record<number, Operator>)[currentDatum.canonical_network_id];
        if (operator) {
          const color = fixHexColor(operator.hex_color);
          accumulator[networkIdString] = {
            label: operator.name_mapped,
            color,
            backgroundColor: color,
            operator,
            data: [formattedDatum],
          };
        }
      }

      return accumulator;
    },
    {} as Record<string, DataSeries>,
  );

  for (const key in trendSeries) {
    trendSeries[key].data.sort((a, b) => a.date.localeCompare(b.date));
  }

  return trendSeries;
});

const titleLabels = computed(() => {
  const _endDate = unref(endDate);
  const _metric = unref(metric);
  const _metricsByIdentifier = unref(metricsByIdentifier);
  const _nbDays = unref(nbDays);
  const _userGroup = unref(userGroup);

  const matchedMetric = _metricsByIdentifier[_metric] as MetricDescriptor;
  const startDateLabel = format(subDays(_endDate, _nbDays), API_LONG_DATE_FORMAT);
  const endDateLabel = format(endDate.value, API_LONG_DATE_FORMAT);
  const foundAggregation = AGGREGATIONS.find((_agg) => aggregation.value === _agg.value);
  const aggregationLabel = foundAggregation ? foundAggregation.label : aggregation.value;

  const userGroupObj = _userGroup !== undefined ? USER_GROUPS.find((group) => group.value === _userGroup) : undefined;
  const fullTitle =
    userGroupObj !== undefined
      ? `${METRIC_TYPE_NAMES[matchedMetric.type as MetricTypeNamesIndex]} ${matchedMetric.name} - ${userGroupObj.label}`
      : `${METRIC_TYPE_NAMES[matchedMetric.type as MetricTypeNamesIndex]} ${matchedMetric.name}`;
  const geography = 'Multi-Country';

  return {
    aggregation: aggregationLabel,
    chartTitle: {
      aggregation: aggregationLabel,
      baseTitle: matchedMetric.name,
      endDate: endDateLabel,
      fullTitle,
      geography,
      lastUpdated: 0,
      metricName: matchedMetric.name,
      metricType: matchedMetric.type,
      metricUnit: matchedMetric.units.short,
      startDate: startDateLabel,
    },
    endDate: endDateLabel,
    fullTitle,
    geography,
    name: matchedMetric.name,
    product: 'competitive',
    startDate: startDateLabel,
    warning: false,
  };
});

// for changing the end date via a callback in the trendchart
const { endDate: endDateQueryParam } = useSpotlightQueryParams();
const onClickLineChart = (pointDate: string) => {
  if (endDate.value.toISOString() !== pointDate) {
    endDateQueryParam.onChange(pointDate);
  }
};
</script>
<template>
  <ChartWrapperExtended
    :loading="geocodingConfig.isLoading.value"
    :empty="false"
    :title="titleLabels.fullTitle"
    :show-confidence-rate="true"
    :picture-meta="titleLabels"
    :chart-data="trendSeries"
    :title-labels="titleLabels"
  >
    <LineChart
      v-if="trendSeries"
      :data-set="Object.values(trendSeries)"
      :date="endDate.toISOString()"
      :title="titleLabels.fullTitle"
      :end-date="titleLabels.endDate"
      :show-confidence-rate="true"
      :show-line-icons="true"
      :height="350"
      :show-compare="false"
      :span-gaps="true"
      chart-id="trend"
      class="Details__chartView"
      :show-rank-gaps="true"
      @point="onClickLineChart"
    />
  </ChartWrapperExtended>
</template>
<style lang="scss"></style>
