export enum ChartTypesEnum {
  BarChart = 'barChart',
  CdnBar = 'cdnBar',
  CdnBinned = 'cdnBinned',
  CdnIpStackedBar = 'cdnIpStackedBar',
  CdnTrend = 'cdnTrend',
  CoverageMap = 'coverageMap',
  DatasetTable = 'datasetTable',
  Distribution = 'dist',
  FailureStackedBar = 'failureStackedBar',
  Gauge = 'gauge',
  OperatorBar = 'operatorBar',
  Table = 'table',
  ThresholdTrend = 'thresholdTrend',
  Trend = 'trend',
  VideoResStackedBar = 'videoResStackedBar',
  HourlyTrend = 'hourlyTrend',
  ScorePerformanceDriver = 'scorePerformanceDriver',
}

export type ChartTrendSeriesInputData<T> = {
  backgroundColor: string;
  color: string;
  data: T[];
  label: string;
};
