import simpleStructureSubmetricByOperatorTooltip from '../tooltips/simpleStructureSubmetricByOperatorTooltip';
import { getCountsBreakdownMinMax, getCountsSimpleMinMax, getZeroToOneMinMax } from '../data-transformers/getMinMax';
import exportSiteOvershootingScoreByENodeBIDToCsv from '@/360/csv-export-overrides/exportSiteOvershootingScoreByENodeBIDToCsv';
import { ChartMetricDefinition, InitialSortDirection } from '@/chart-metric-definitions/MetricDefinition';
import transformBandCountsPerOperator from '@/chart-metric-definitions/data-transformers/transformBandCountsByOperator';
import transformCountsBreakdownStructureByOperator from '@/chart-metric-definitions/data-transformers/transformCountsBreakdownStructureByOperator';
import transformOvershootingScoreByENodeBId from '@/chart-metric-definitions/data-transformers/transformOvershootingScoreByENodeBId';
import transformSimpleStructureSubmetricsByOperator from '@/chart-metric-definitions/data-transformers/transformSimpleStructureSubmetricsByOperator';
import basicBarChartTooltip from '@/chart-metric-definitions/tooltips/basicBarChartTooltip';
import gradientLegendPlugin from '@/components/visual/chart/plugins/gradientLegendPlugin';
import { METRIC_TYPES } from '@/constants/constants';
import { ChartTypesEnum } from '@/types/Charts';
import { MetricSubtypes } from '@/types/MetricSubtypes';
import barChartAxesFromOrientation from '@/utils/barChartAxesFromOrientation';
import exportDatasetToCsv from '@/utils/exportDatasetToCsv';

import { overshootingScoreColumns } from '@/360/base-station/table-columns/overshootingScoreColumns';
import { prepareOvershootingScoreForTable } from '@/chart-metric-definitions/data-transformers/prepareOvershootingScoreForTable';
import exportOvershootingScoreTable from '@/360/csv-export-overrides/exportOvershootingScoreTable';

const overshootingScoreByENodeBIdHorizontal = true;
const overshootingScoreByENodeBIdAxes = barChartAxesFromOrientation(overshootingScoreByENodeBIdHorizontal);

const onx360BaseStationSiteMapperChartMetricDefinitions: Record<string, ChartMetricDefinition[]> = {
  byOperator: [
    {
      metricSubtype: MetricSubtypes.OvershootingLikelihoodENodeBCount,
      chartType: ChartTypesEnum.BarChart,
      connectionCategory: METRIC_TYPES.Overall,
      chartAttrs: {
        horizontal: true,
        nbDays: 0,
        max: 100,
        chartTitlePlaceholders: 'eNodeB Overshooting Likelihood (%)',
        chartConfig: {
          options: {
            scales: {
              x: {
                title: {
                  display: true,
                  text: 'Avg Adjusted Limited Overshooting Score',
                },
              },
            },
          },
        },
      },
      transformData: transformCountsBreakdownStructureByOperator('percentage'),
      get tooltipPlugin() {
        return basicBarChartTooltip({ horizontal: !!this.chartAttrs?.horizontal });
      },
      onExportCsv: exportDatasetToCsv({
        headers: ['Operator', 'Overshooting Likelihood', 'Percentage', 'Count', 'Date'],
        getRow: (datum, dataset, date) => {
          return [datum[dataset.labelAxis], dataset.label, `${datum.percentage}%`, datum.count, date];
        },
      }),
    },
    {
      metricSubtype: MetricSubtypes.OvershootingLikelihoodENodeBCount,
      chartType: ChartTypesEnum.BarChart,
      connectionCategory: METRIC_TYPES.Overall,
      chartAttrs: {
        horizontal: true,
        nbDays: 0,
        chartTitlePlaceholders: 'eNodeB Overshooting Likelihood Records',
        stacked: false,
        barChartAttrs: {
          showLegend: true,
        },
      },
      transformData: transformCountsBreakdownStructureByOperator('counts'),
      calculateBounds: getCountsBreakdownMinMax,
      get tooltipPlugin() {
        return basicBarChartTooltip({ horizontal: !!this.chartAttrs?.horizontal });
      },
      onExportCsv: exportDatasetToCsv({
        headers: ['Operator', 'Overshooting Likelihood', 'Count', 'Date'],
        getRow: (datum, dataset, date) => {
          return [datum[dataset.labelAxis], dataset.label, datum.count, date];
        },
      }),
    },
    {
      metricSubtype: MetricSubtypes.OvershootingENodeBCount,
      chartType: ChartTypesEnum.BarChart,
      connectionCategory: METRIC_TYPES.Overall,
      chartAttrs: {
        horizontal: true,
        nbDays: 0,
        chartTitlePlaceholders: 'eNodeB Count',
      },
      transformData: transformSimpleStructureSubmetricsByOperator,
      calculateBounds: getCountsSimpleMinMax,
      get tooltipPlugin() {
        return simpleStructureSubmetricByOperatorTooltip({ horizontal: !!this.chartAttrs?.horizontal });
      },
      onExportCsv: exportDatasetToCsv({
        headers: ['Operator', 'Count', 'Date'],
        getRow: (datum, dataset, date) => {
          return [dataset.label, datum[dataset.dataAxis], date];
        },
      }),
    },
    {
      metricSubtype: MetricSubtypes.OvershootingRecordCount,
      chartType: ChartTypesEnum.BarChart,
      connectionCategory: METRIC_TYPES.Overall,
      chartAttrs: {
        horizontal: true,
        nbDays: 0,
        chartTitlePlaceholders: 'Number of Samples by Operator',
      },
      transformData: transformSimpleStructureSubmetricsByOperator,
      calculateBounds: getCountsSimpleMinMax,
      get tooltipPlugin() {
        return simpleStructureSubmetricByOperatorTooltip({ horizontal: !!this.chartAttrs?.horizontal });
      },
      onExportCsv: exportDatasetToCsv({
        headers: ['Operator', 'Samples', 'Date'],
        getRow: (datum, dataset, date) => {
          return [dataset.label, datum[dataset.dataAxis], date];
        },
      }),
    },
    {
      chartType: ChartTypesEnum.DatasetTable,
      dataset: 'onx360_overshooting_score_table',
      columns: overshootingScoreColumns,
      connectionCategory: METRIC_TYPES.Overall,
      initialSortColumnKey: 'site_overshooting_score',
      initialSortDirection: InitialSortDirection.Desc,
      chartAttrs: {
        chartTitlePlaceholders: 'Overshooting Scores per Operator and Band',
      },
      transformData: prepareOvershootingScoreForTable,
      onExportCsv: exportOvershootingScoreTable,
    },
  ],
  forEachOperator: [
    {
      metricSubtype: MetricSubtypes.OvershootingScoreByENodeBIdPartial,
      chartType: ChartTypesEnum.BarChart,
      connectionCategory: METRIC_TYPES.Overall,
      chartAttrs: {
        chartTitlePlaceholders: 'Site Overshooting Score by eNodeB ID for :operator',
        horizontal: true,
        nbDays: 0,
        dynamicHeight: true,
        barChartAttrs: {
          plugins: [gradientLegendPlugin({ afterText: 'Samples' })],
        },
        chartConfig: {
          options: {
            scales: {
              [overshootingScoreByENodeBIdAxes.dataAxis]: {
                title: {
                  display: true,
                  text: 'Avg Adjusted Limited Overshooting Score',
                },
              },
              [overshootingScoreByENodeBIdAxes.labelAxis]: {
                title: {
                  display: true,
                  text: 'Connection ENodeB ID',
                },
              },
            },
          },
        },
      },
      transformData: transformOvershootingScoreByENodeBId,
      calculateBounds: getZeroToOneMinMax,
      get tooltipPlugin() {
        return simpleStructureSubmetricByOperatorTooltip({ horizontal: !!this.chartAttrs?.horizontal });
      },
      onExportCsv: exportSiteOvershootingScoreByENodeBIDToCsv,
    },
    {
      metricSubtype: MetricSubtypes.ConnectionBandOvershootingRecordCount,
      chartType: ChartTypesEnum.BarChart,
      connectionCategory: METRIC_TYPES.Overall,
      chartAttrs: {
        chartTitlePlaceholders: 'Number of Samples by Connection Band for :operator',
        horizontal: true,
        nbDays: 0,
        dynamicHeight: true,
      },
      transformData: transformBandCountsPerOperator,
      calculateBounds: getCountsBreakdownMinMax,
      onExportCsv: exportDatasetToCsv({
        headers: ['Band', 'Overshooting Record Count', 'Date'],
        getRow: (datum, dataset, date) => {
          return [datum[dataset.labelAxis], datum[dataset.dataAxis], date];
        },
      }),
    },
  ],
};

export default onx360BaseStationSiteMapperChartMetricDefinitions;
