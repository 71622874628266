import { commonCoreEndpointDatasetColumns, CoreEndpointDatasetRow } from './commonColumns';
import type { Column } from '@/components/onx/table';

export const videoTtffColumns: Column<
  CoreEndpointDatasetRow & {
    ttff: number;
  }
>[] = [
  ...commonCoreEndpointDatasetColumns,
  {
    key: 'ttff',
    header: 'Time to First Frame (s)',
    value(row) {
      return row.ttff;
    },
    cell(row) {
      return `${row.ttff.toFixed(2)}s`;
    },
    sort(a, b) {
      return a - b;
    },
  },
];

export const videoStallingTimeColumns: Column<
  CoreEndpointDatasetRow & {
    stalltime: number;
  }
>[] = [
  ...commonCoreEndpointDatasetColumns,
  {
    key: 'stalltime',
    header: 'Stalling Time (ms)',
    value(row) {
      return row.stalltime;
    },
    cell(row) {
      return `${row.stalltime.toFixed(2)}ms`;
    },
    sort(a, b) {
      return a - b;
    },
  },
];

export const videoStallingOccurrenceColumns: Column<
  CoreEndpointDatasetRow & {
    mean: number;
  }
>[] = [
  ...commonCoreEndpointDatasetColumns,
  {
    key: 'mean',
    header: 'Stalling Occurrence (%)',
    value(row) {
      return row.mean;
    },
    cell(row) {
      return `${row.mean.toFixed(2)}%`;
    },
    sort(a, b) {
      return a - b;
    },
  },
];

export const videoThroughputColumns: Column<
  CoreEndpointDatasetRow & {
    throughput: number;
  }
>[] = [
  ...commonCoreEndpointDatasetColumns,
  {
    key: 'throughput',
    header: 'Throughput Rate (Mbps)',
    value(row) {
      return row.throughput;
    },
    cell(row) {
      return `${row.throughput.toFixed(2)}Mbps`;
    },
    sort(a, b) {
      return a - b;
    },
  },
];

export const videoTimeOnResColumns: Column<
  CoreEndpointDatasetRow & {
    resolution: number;
    restime: number;
  }
>[] = [
  ...commonCoreEndpointDatasetColumns,
  {
    key: 'resolution',
    header: 'Resolution',
    value(row) {
      switch (row.resolution) {
        case 2160:
          return '2160p';
        case 1440:
          return '1440p';
        case 1080:
          return '1080p';
        case 720:
          return '720p';
        case 480:
          return '480p';
        case 360:
          return '360p';
        case 240:
          return '240p';
        case 144:
          return '144p';
        default:
          return row.resolution;
      }
    },
    sort(a, b) {
      return a.localeCompare(b);
    },
  },
  {
    key: 'restime',
    header: 'Time on Resolution (%)',
    value(row) {
      return row.restime;
    },
    cell(row) {
      return `${row.restime.toFixed(2)}%`;
    },
    sort(a, b) {
      return a - b;
    },
  },
];
