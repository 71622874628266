<script setup lang="ts">
import { watchEffect } from 'vue';

import type { AvailableConnectionCategory } from '@/constants/allPossibleConnectionCategories';

import { FieldGroup } from '@/components/menu';
import OnxRadioButton from '@/components/onx/OnxRadioButton.vue';
import { METRIC_TYPES } from '@/constants/constants';
import useSpotlightQueryParams from '@/components/specialized/useSpotlightQueryParams';
import useAnalytics from '@/composables/useAnalytics';

type Props = {
  connectionCategories: AvailableConnectionCategory[];
};

const props = defineProps<Props>();

const { track } = useAnalytics();
const queryParams = useSpotlightQueryParams({
  connectionCategory: METRIC_TYPES.Overall,
});

const onConnectionCategoryChange = (value: string) => {
  queryParams.connectionCategory.onChange(value);
  track('connection category change', { newConnectionCategory: value });
};

watchEffect(() => {
  const connectionCategory = queryParams.connectionCategory.toValue();

  if (
    props.connectionCategories.length > 0 &&
    !props.connectionCategories.map((c) => c.categoryValue).includes(connectionCategory)
  ) {
    queryParams.connectionCategory.onChange(props.connectionCategories[0].categoryValue);
  }
});
</script>

<template>
  <FieldGroup label="Connection category">
    <OnxRadioButton
      v-for="category in connectionCategories"
      name="connection-category-radio"
      :key="category.categoryValue"
      :model-value="queryParams.connectionCategory.selectedValue.value"
      @update:model-value="onConnectionCategoryChange"
      :value="category.categoryValue"
      :label="category.categoryLabel"
    />
  </FieldGroup>
</template>
