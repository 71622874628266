import { computed, unref, MaybeRef } from 'vue';

import type { MetricDescriptor } from '@/types/MetricDescriptor';
import getMetricUnitLabel from '@/utils/getMetricUnitLabel';

const useMetricUnitLabel = (metric: MaybeRef<MetricDescriptor>) => {
  const label = computed(() => {
    const _metric = unref(metric);
    return getMetricUnitLabel(_metric);
  });

  return {
    metricUnitLabel: label,
  };
};

export default useMetricUnitLabel;
