<script setup lang="ts">
import { computed } from 'vue';

import { MenuTitle } from '@/components/menu';

import SpotlightMenuConnectionCategory from '@/intl-spotlight/SpotlightMenuConnectionCategory.vue';
import SpotlightMenuEndDate from '@/intl-spotlight/SpotlightMenuEndDate.vue';
import SpotlightMenuAggregation from '@/intl-spotlight/SpotlightMenuAggregation.vue';
import SpotlightMenuUserGroup from '@/intl-spotlight/SpotlightMenuUserGroup.vue';
import OnxMetricsSelector from '@/components/onx/OnxMetricsSelector.vue';
import FieldGroup from '@/components/menu/FieldGroup.vue';

import useMetrics from '@/components/onx/composables/useMetrics';
import useSpotlightQueryParams from '@/components/specialized/useSpotlightQueryParams';
import useSpotlightByGeographyConnectionCategories from '@/components/specialized/useSpotlightByGeographyConnectionCategories';
import useSpotlightPrimaryMetric from '@/components/specialized/useSpotlightPrimaryMetric';
import useSpotlightMetricsSelector from '@/spotlight/useSpotlightMetricsSelector';

import createMetricKey from '@/utils/createMetricKey';

type Props = {
  openUserGroupInfoModal: () => void;
};

defineProps<Props>();

const queryParams = useSpotlightQueryParams();

const { metricsByIdentifier, metricsBySubtype } = useMetrics();
const selectedMetric = computed(() => {
  const metricKey = createMetricKey(
    queryParams.metric.toValue(),
    queryParams.userGroup.toValue(),
    queryParams.connectionCategory.toValue(),
  );

  return metricsByIdentifier.value[metricKey];
});

const { connectionCategories } = useSpotlightByGeographyConnectionCategories(
  selectedMetric,
  queryParams.userGroup.selectedValue,
);

const primaryMetric = useSpotlightPrimaryMetric();
const { menuItems, onMetricSelect, selectedMetricLabel } = useSpotlightMetricsSelector(primaryMetric);

const metricsRelatedToSelectedMetric = computed(() => {
  return metricsBySubtype.value[queryParams.metric.toValue()] || [];
});
</script>
<template>
  <div class="CompetitiveMenu__content">
    <MenuTitle label="Parameters" theme="ci" />

    <SpotlightMenuEndDate />
    <SpotlightMenuAggregation />

    <FieldGroup label="Metrics">
      <OnxMetricsSelector
        :selected-metric-label="selectedMetricLabel"
        :list-dropdown-item-categories="['overall', 'consistency', 'other']"
        :list-dropdown-items="menuItems"
        @list-item-click="onMetricSelect"
      />
    </FieldGroup>

    <div class="ParametersLayout">
      <div class="ParametersLayout__grid">
        <SpotlightMenuConnectionCategory :connection-categories="connectionCategories" />
        <SpotlightMenuUserGroup
          :open-user-group-info-modal="openUserGroupInfoModal"
          :connection-category="queryParams.connectionCategory.selectedValue"
          :metrics="metricsRelatedToSelectedMetric"
        />
      </div>
    </div>
  </div>
</template>
