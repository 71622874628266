import { computed } from 'vue';

import { useStore } from 'vuex';
import { useDatasetDescriptors } from '@/composables/useDatasetDescriptors';

const useCheckIfMetricSubtypeOrDatasetExists = () => {
  const store = useStore();
  const { datasets, isLoading } = useDatasetDescriptors();

  const checkIfMetricSubtypeOrDatasetExists = (metricSubtypeOrDataset: string) => {
    const existingMetricSubtypes = store.getters['metrics/subtypes'];
    return (
      Boolean(existingMetricSubtypes[metricSubtypeOrDataset]) || Boolean(datasets.value?.byKey[metricSubtypeOrDataset])
    );
  };

  const ready = computed(() => {
    const existingMetricSubtypes = store.getters['metrics/subtypes'];
    return Object.keys(existingMetricSubtypes).length > 0 && !isLoading.value;
  });

  return { checkIfMetricSubtypeOrDatasetExists, ready };
};

export default useCheckIfMetricSubtypeOrDatasetExists;
