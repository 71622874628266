<script setup lang="ts">
import { computed } from 'vue';
import useModel from '@/components/onx/composables/useModel';

export type OnxRadioButtonProps = {
  name: string;
  label: string;
  value: string | number | object;
  modelValue?: string | number | object;
  checked?: boolean;
  inheritColor?: boolean;
  disabled?: boolean;
};

const props = withDefaults(defineProps<OnxRadioButtonProps>(), {
  modelValue: undefined,
  checked: undefined,
  inheritColor: false,
  disabled: false,
});

const computedChecked = computed(() => {
  if (props.modelValue !== undefined) {
    return props.modelValue === props.value;
  }

  return props.checked;
});

const emit = defineEmits(['update:modelValue']);
const vModel = useModel(props, emit);
</script>

<template>
  <label class="onx-radio-button">
    <input
      v-model="vModel"
      :value="value"
      type="radio"
      class="onx-radio-button__input"
      :class="{ 'onx-radio-button__input--inherit-color': inheritColor, 'onx-radio-button__input--disabled': disabled }"
      :checked="computedChecked"
      :disabled="disabled"
    />
    <span class="onx-radio-button__label" :class="{ 'onx-radio-button__label--inherit-color': inheritColor }">
      {{ label }}
    </span>
  </label>
</template>

<style scoped lang="scss">
@use 'scss/variables.module' as *;

.onx-radio-button {
  display: inline-flex;
  align-items: center;
  height: 24px;
  padding-top: 2px;
}

.onx-radio-button__input {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  color: var(--onx-radio-input-color);
  width: 12px;
  height: 12px;
  margin-left: 4px;
  border: 1px solid var(--onx-radio-input-color);
  border-radius: 50%;
  appearance: none;
  cursor: pointer;

  &::after {
    content: '';
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: var(--onx-radio-input-color);
    transform: scale(0);
    transition: transition();
  }

  &:checked {
    border-color: var(--onx-radio-input-active-color);

    &::after {
      background-color: var(--onx-radio-input-active-color);
      transform: scale(1);
    }

    ~ .onx-radio-button__label {
      color: var(--onx-radio-label-active-color);
    }
  }

  &--disabled {
    border: 1px solid var(--onx-radio-input-disabled-border-color);
    background-color: var(--onx-radio-input-disabled-bg-color);

    ~ .onx-radio-button__label {
      color: var(--onx-radio-label-disabled-color);
    }

    &:checked {
      border-color: var(--onx-radio-input-disabled-border-color);

      &::after {
        background-color: var(--onx-radio-input-disabled-border-color);
      }

      ~ .onx-radio-button__label {
        color: var(--onx-radio-label-disabled-color);
      }
    }
  }
}

.onx-radio-button__label {
  font-size: pxToRem(12);
  line-height: pxToRem(14);
  color: var(--onx-radio-label-color);
  cursor: pointer;
  white-space: nowrap;

  &--inherit-color {
    color: inherit !important;
  }
}
</style>
