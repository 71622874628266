import type { Ref } from 'vue';

/**
 * Type guard to resolve Ref<T | null | undefined> to Ref<T> if value is not null or undefined
 *
 * Solves a problem where checking for !ref.value when value can be T | null | undefined is not enough
 * The check would be enough if the function expected T
 * This check is insufficient when a function expects Ref<T>, not Ref<T | null | undefined>
 */
export const refValueIsPresent = <T>(ref: Ref<T | undefined | null>): ref is Ref<T> => ref.value != undefined;
