import osApi from '@/api/osApi';
import { MetricSubtypes } from '@/types/MetricSubtypes';
import { MetricResponse } from '@/types/MetricResponse';
import { MetricStructuresEnum } from '@/types/MetricStructures';
import { Column } from '@/components/onx/table';

import { Dashboards } from '@/constants/dashboards';
import { type TableExportArgs } from '@/components/onx/table/OnxTable.vue';

import { exportToCsv } from '@/utils/files';

type CreateExportArgs<RowType = any> = {
  metricSubtype: MetricSubtypes;
  columns: Column<RowType>[];
};

const createExportSimpleTableFullMetricToCsv = <T extends MetricStructuresEnum, U = any>({
  columns,
  metricSubtype,
}: CreateExportArgs<U>) => {
  return async (_: any, title: string, exportArgs: TableExportArgs) => {
    const url = `/${Dashboards.ThreeSixty}/metrics/${metricSubtype}_overall/${location}/90days/`;

    const response = await osApi.get<MetricResponse<T>>(url);

    const transformedData = exportArgs.transform(response.data) as U[];
    const rows = transformedData.map((row) => columns.map((column) => column.value(row)));
    const headers = columns.map((column) => column.header);

    exportToCsv(`${title}.csv`, [headers, ...rows]);
  };
};

export default createExportSimpleTableFullMetricToCsv;
