<script setup>
import { computed } from 'vue';

const props = defineProps({
  variant: {
    type: String,
    default: 'primary',
    validator(value) {
      return ['primary', 'secondary', 'tertiary'].includes(value);
    },
  },
  size: {
    type: String,
    default: 'md',
    validator(value) {
      return ['sm', 'md'].includes(value);
    },
  },
  disabled: {
    type: Boolean,
    default: false,
    optional: true,
  },
});

const classObject = computed(() => ['onx-button', `onx-button--${props.variant}`, `onx-button--${props.size}`]);
</script>

<template>
  <button type="button" :disabled="disabled" :class="classObject">
    <slot />
  </button>
</template>
