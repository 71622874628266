<script setup lang="ts">
import { computed } from 'vue';

import type { CompareToKeys } from '@/types/MetricStructures';

import IntlSpotlightBreadcrumbs from '@/intl-spotlight/IntlSpotlightBreadcrumbs.vue';
import OnxSpotlightHeaderNav from '@/components/onx/spotlight-header/OnxSpotlightHeaderNav.vue';

import useIntlSpotlightOverviewMetrics from '@/intl-spotlight/useIntlSpotlightOverviewMetrics';
import useSpotlightQueryParams from '@/components/specialized/useSpotlightQueryParams';

const { data } = useIntlSpotlightOverviewMetrics();
const queryParams = useSpotlightQueryParams();

const previousDate = computed(() => {
  if (!data.value || !('previous' in data.value.data.results[0])) {
    return '';
  }

  const compareTo = queryParams.compareTo.toValue();

  return data.value.data.results[0].previous?.[compareTo as CompareToKeys]?.date;
});
</script>

<template>
  <IntlSpotlightBreadcrumbs />
  <OnxSpotlightHeaderNav :previous-date="previousDate" />
</template>
