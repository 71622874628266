import { computed } from 'vue';
import { useStore } from 'vuex';

import type { MetricDescriptor } from '@/types/MetricDescriptor';

const useMetrics = () => {
  const store = useStore();
  const metrics = computed(() => (store.getters['metrics'] || []) as MetricDescriptor[]);
  const metricsByIdentifier = computed(() => store.getters['metrics/byIdentifier'] as Record<string, MetricDescriptor>);
  const metricsBySubtype = computed(() => store.getters['metrics/subtypes'] as Record<string, MetricDescriptor[]>);

  return {
    metrics,
    metricsByIdentifier,
    metricsBySubtype,
  };
};

export default useMetrics;
