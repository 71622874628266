<template>
  <div v-if="dashboardInfo?.data_last_updated">
    <UserGroupInfoModal v-model:displayModal="displayModal" />

    <div class="CompetitiveMenu__content">
      <MenuTitle label="Parameters" theme="ci" />

      <div class="ParametersLayout">
        <div class="ParametersLayout__grid">
          <div class="ParametersLayout__geographies">
            <SidebarLocationSearch
              :on-location-change="selectLocation"
              :on-reset-location="resetLocationToCurrentCountry"
            />
          </div>

          <FieldGroup label="End Date">
            <flatpickr
              :min="parsedFirstDateAvailable"
              :max="parsedLastDateAvailable"
              :date="parsedCurrentDate"
              theme="ci"
              @selected="chooseNewDate"
            />
          </FieldGroup>

          <template v-if="$route.name !== ROUTES.CompetitiveOverview && !isAggregationDisabled">
            <FieldGroup :tooltip="aggregationTooltipMessage" label="Aggregation">
              <RadioButtons
                group-name="agg"
                :options="aggregationOptions"
                :selected-option="aggregation"
                @select="(agg) => update({ agg })"
              />
            </FieldGroup>
          </template>

          <template v-if="$route.name !== ROUTES.CompetitiveDetails">
            <FieldGroup :tooltip="compareToTooltipMessage" label="Compare to">
              <RadioButtons
                group-name="compareTo"
                :options="compareToOptions"
                :selected-option="queryParams.compareTo.selectedValue.value"
                @select="queryParams.compareTo.onChange"
              />
            </FieldGroup>
          </template>

          <template v-if="$route.name === ROUTES.CompetitiveDetails">
            <FieldGroup label="Metrics">
              <OnxMetricsSelector
                :selected-metric-label="byGeographyMetricsSelector.selectedMetricLabel.value"
                :list-dropdown-item-categories="['overall', 'consistency', 'other']"
                :list-dropdown-items="byGeographyMetricsSelector.menuItems.value"
                @list-item-click="byGeographyMetricsSelector.onMetricSelect"
              />
            </FieldGroup>
          </template>
          <template v-else-if="$route.name === ROUTES.CompetitiveConnectionCategory">
            <FieldGroup label="Metrics">
              <OnxMetricsSelector
                :selected-metric-label="byConnectionCategoryMetricsSelector.selectedMetricLabel.value"
                :list-dropdown-item-categories="['overall', 'consistency', 'other']"
                :list-dropdown-items="byConnectionCategoryMetricsSelector.menuItems.value"
                @list-item-click="byConnectionCategoryMetricsSelector.onMetricSelect"
              />
            </FieldGroup>
          </template>

          <template v-if="$route.name !== ROUTES.CompetitiveOverview">
            <OnxToggle
              v-model="confidenceIntervals"
              label="Show confidence intervals"
              class="onx-show-confidence-toggle"
            />
          </template>

          <FieldGroup label="Operators">
            <template v-if="$route.name === ROUTES.CompetitiveDetails">
              <OnxToggle v-model="singleOperatorMode" label="Single operator" class="onx-single-operator-toggle" />
            </template>
            <NetworksMenu :singleOperatorMode="singleOperatorMode" theme="ci" />
          </FieldGroup>

          <template v-if="$route.name === ROUTES.CompetitiveOverview">
            <SpotlightMenuConnectionCategory :connection-categories="getDefaultMetricTypeOptions" />

            <FieldGroup v-if="displayOverviewUserGroups" label="User Groups">
              <template #tooltip>
                <CustomTooltip :message="''" placement="bottom" @click="displayUserGroupModal">
                  <InfoIcon class="FieldGroup__tooltipIcon" />
                </CustomTooltip>
              </template>
              <RadioButtons
                group-name="userGroup"
                :options="userGroupOptions"
                :selected-option="queryParams.userGroup.selectedValue.value"
                @select="setSelectedUserGroup"
              />
            </FieldGroup>
          </template>
        </div>
      </div>

      <template v-if="$route.name === ROUTES.CompetitiveDetails">
        <SpotlightMenuConnectionCategory :connection-categories="detailsConnectionCategories" />
      </template>

      <FieldGroup v-if="$route.name !== ROUTES.CompetitiveOverview && displayDetailUserGroups" label="User Groups">
        <template #tooltip>
          <CustomTooltip :message="''" placement="bottom" @click="displayUserGroupModal">
            <InfoIcon class="FieldGroup__tooltipIcon" />
          </CustomTooltip>
        </template>
        <RadioButtons
          group-name="userGroup"
          :options="userGroupOptions"
          :selected-option="queryParams.userGroup.selectedValue.value"
          @select="selectUserGroup"
        />
      </FieldGroup>
    </div>
  </div>
</template>

<script>
import { max } from 'date-fns';
import get from 'lodash/get';
import mp from 'mixpanel-browser';
import { mapGetters, mapActions } from 'vuex';
import UserGroupInfoModal from '@/components/competitive/UserGroupInfoModal';
import { Flatpickr, FieldGroup, MenuTitle } from '@/components/menu';
import RadioButtons from '@/components/menu/RadioButtons';
import useBreakpoints from '@/components/onx/composables/responsive/useBreakpoints';
import InfoIcon from '@/components/onx/icons/InfoIcon';
import OnxToggle from '@/components/onx/OnxToggle';
import OnxMetricsSelector from '@/components/onx/OnxMetricsSelector';
import SidebarLocationSearch from '@/components/SidebarLocationSearch.vue';
import SpotlightMenuConnectionCategory from '@/intl-spotlight/SpotlightMenuConnectionCategory.vue';
import { CustomTooltip } from '@/components/tooltip';
import {
  AGGREGATIONS,
  COMPARE_TO,
  METRIC_TYPES,
  DEFAULT_USER_GROUP,
  UNSUPPORTED_TYPES_FOR_GROUPS,
  OS_GEOCODINGS,
} from '@/constants/constants';
import ROUTES from '@/constants/routes';
import { TOOLTIP_MESSAGES } from '@/constants/tooltips';
import router from '@/router';
import { geocodingListItems } from '@/utils/menu';
import useLocations from '@/composables/useLocations';
import useCurrentDashboardName from '@/composables/useCurrentDashboardName';
import useSpotlightQueryParams from '@/components/specialized/useSpotlightQueryParams';
import useSpotlightPrimaryMetric from '@/components/specialized/useSpotlightPrimaryMetric';
import useSpotlightByGeographyConnectionCategories from '@/components/specialized/useSpotlightByGeographyConnectionCategories';
import useSpotlightMetricsSelector from '@/spotlight/useSpotlightMetricsSelector';
import useSpotlightMetricsSelectorForConnectionCategory from '@/spotlight/useSpotlightMetricsSelectorForConnectionCategory';
import getSpotlightOverviewConnectionCategories from '@/components/specialized/getSpotlightOverviewConnectionCategories';
import getSpotlightUserGroups from '@/components/specialized/getSpotlightUserGroups';

export default {
  name: 'CompetitiveMenu',
  components: {
    Flatpickr,
    FieldGroup,
    MenuTitle,
    InfoIcon,
    CustomTooltip,
    UserGroupInfoModal,
    RadioButtons,
    OnxToggle,
    OnxMetricsSelector,
    SidebarLocationSearch,
    SpotlightMenuConnectionCategory,
  },
  setup() {
    const matches = useBreakpoints();
    const currentDashboardName = useCurrentDashboardName();
    const locationsInfo = useLocations(currentDashboardName.value);

    const mainMetric = useSpotlightPrimaryMetric();
    const queryParams = useSpotlightQueryParams({
      compareTo: '90days',
    });

    const { connectionCategories: detailsConnectionCategories } = useSpotlightByGeographyConnectionCategories(
      mainMetric,
      queryParams.userGroup.selectedValue,
    );

    const byGeographyMetricsSelector = useSpotlightMetricsSelector(mainMetric);
    const byConnectionCategoryMetricsSelector = useSpotlightMetricsSelectorForConnectionCategory(mainMetric);

    return {
      byConnectionCategoryMetricsSelector,
      byGeographyMetricsSelector,
      currentDashboardName,
      detailsConnectionCategories,
      locationsInfo,
      mainMetric,
      matches,
      queryParams,
    };
  },
  data() {
    return {
      aggregationTooltipMessage: TOOLTIP_MESSAGES.aggregation,
      compareToTooltipMessage: TOOLTIP_MESSAGES.compareTo,
      datePickerTooltipMessage: TOOLTIP_MESSAGES.datePicker,
      setCategory: null,
      options: {
        dashboard: 'competitive',
      },
      ROUTES: ROUTES,
      displayModal: false,
    };
  },
  computed: {
    ...mapGetters({
      availableNetworks: 'charts/ci_networks',
      category: 'metrics/category',
      country: 'location/currentCountry',
      location: 'location/currentLocation',
      locationsByIdentifier: 'locations/byIdentifier',
      userGeocodings: 'location/groupings',
      metricsGroupedBySubtypes: 'metrics/subtypes',
      confidenceState: 'dashboard/getConfidenceState',
      minDateOrganization: 'charts/organizationStartDate',
      selected: 'charts/selectedNetworkOperators',
      networkOperators: 'charts/networks',
      isMobile: 'page/isMobile',
    }),
    ...mapGetters(['metrics', 'dashboardInfo', 'locations', 'polygons', 'user']),
    showResetButton() {
      return parseInt(this.location.granularityId, 10) !== 1;
    },
    compareToOptions() {
      return Object.values(COMPARE_TO);
    },
    singleOperatorMode: {
      get() {
        return (
          router.currentRoute.value.query.network !== 'all' &&
          router.currentRoute.value.name === ROUTES.CompetitiveDetails
        ); // only applies to by-geography details page
      },
      set(isInSingleOperatorMode) {
        this.$router.push({
          name: router.currentRoute.value.name,
          query: {
            ...router.currentRoute.value.query,
            network: isInSingleOperatorMode ? `${this.selected[0].canonical_network_id}` : 'all',
          },
        });
      },
    },
    confidenceIntervals: {
      get() {
        return this.confidenceState;
      },
      set() {
        this.toggleConfidence();
      },
    },
    metricUserGroup() {
      return this.mainMetric.subcategory;
    },
    userGroupOptions() {
      const isDetailsPage = router.currentRoute.value.name === ROUTES.CompetitiveDetails;
      const isConnectionCategoryPage = router.currentRoute.value.name === ROUTES.CompetitiveConnectionCategory;

      const metricType = this.queryParams.connectionCategory.toValue();
      const allMetricVariants =
        isDetailsPage || isConnectionCategoryPage
          ? this.metrics.filter((m) => m.subtype === this.mainMetric.subtype)
          : this.metrics;

      return getSpotlightUserGroups(allMetricVariants, metricType, this.mainMetric);
    },
    geocodingsConfig() {
      return geocodingListItems(this.polygons, this.queryParams.geocoding.selectedValue.value);
    },
    parsedFirstDateAvailable() {
      const HARD_FIRST_DATE_AVAILABLE = '2018-05-31T00:00:00.000Z';
      const firstDateAvailable = this.dashboardInfo.first_date_available || HARD_FIRST_DATE_AVAILABLE;

      return max([new Date(HARD_FIRST_DATE_AVAILABLE), new Date(firstDateAvailable)]);
    },
    parsedLastDateAvailable() {
      const lastDateAvalailable = this.dashboardInfo.last_date_available;
      return lastDateAvalailable && new Date(lastDateAvalailable);
    },
    parsedCurrentDate() {
      return new Date(router.currentRoute.value.query.endDate);
    },
    aggregation: {
      get() {
        return this.queryParams.agg.toValue();
      },
      set(agg) {
        this.queryParams.agg.onChange(agg);
      },
    },
    aggregationOptions() {
      let aggregationTypesAvailable = AGGREGATIONS;

      if (this.queryParams.metric.selectedValue.value.includes('coverage')) {
        aggregationTypesAvailable = AGGREGATIONS.filter((agg) => agg.value === '90days');
      }
      const aggregationTypesEnabled = this.dashboardInfo.aggregation_types_enabled ?? [];

      return aggregationTypesAvailable.filter((agg) => aggregationTypesEnabled.includes(agg.value));
    },
    isAggregationDisabled() {
      return this.aggregationOptions.length < 2;
    },
    getDefaultMetricTypeOptions() {
      return getSpotlightOverviewConnectionCategories(this.metrics, this.queryParams.userGroup.toValue());
    },
    displayDetailUserGroups() {
      return (
        !UNSUPPORTED_TYPES_FOR_GROUPS.includes(this.mainMetric.kind) &&
        this.queryParams.connectionCategory.toValue() !== METRIC_TYPES.Mmwave &&
        this.userGroupOptions.length > 0
      );
    },
    displayOverviewUserGroups() {
      return this.queryParams.connectionCategory.toValue() !== METRIC_TYPES.Mmwave;
    },
  },
  created() {
    this.ensureValidConnectionCategory();
  },
  methods: {
    ...mapActions(['navigateToDashboard', 'resetUser', 'setAsCurrentLocation']),
    ...mapActions({
      toggleConfidence: 'dashboard/toggleConfidenceState',
      toggleMenu: 'dashboard/toggleMenu',
    }),
    ensureValidConnectionCategory() {
      const connectionCategory = this.queryParams.connectionCategory.toValue();
      const allTypeOptions = this.getDefaultMetricTypeOptions.map((option) => option.categoryValue);

      if (allTypeOptions.length && !allTypeOptions.includes(connectionCategory)) {
        this.queryParams.connectionCategory.onChange('overall', true);
      }
    },
    selectConnectionCategory(connectionCategory) {
      this.queryParams.connectionCategory.onChange(connectionCategory);
    },
    selectUserGroup(userGroup) {
      let metric;

      if (router.currentRoute.value.name === ROUTES.CompetitiveConnectionCategory) {
        metric = this.metrics.find((m) => m.subcategory === userGroup && m.subtype === this.mainMetric.subtype);
      } else {
        metric = this.metrics.find(
          (m) =>
            m.subcategory === userGroup && m.subtype === this.mainMetric.subtype && m.type === this.mainMetric.type,
        );
      }

      if (!metric) {
        return;
      }

      this.update({ metric: metric.subtype, userGroup });
    },
    selectLayer(type) {
      this.$router.push({
        query: {
          ...this.$router.currentRoute.value.query,
          connectionCategory: type,
          userGroup: DEFAULT_USER_GROUP,
        },
      });

      if (this.isMobile) {
        this.toggleMenu();
      }

      mp.track('Spotlight overview layer change', {
        layer: type,
      });
    },
    setSelectedUserGroup(group) {
      this.queryParams.userGroup.onChange(group);

      if (this.isMobile) {
        this.toggleMenu();
      }
    },
    chooseNewDate(endDate) {
      this.update({ endDate });
    },
    getGeocodingForLocation(location) {
      const granularityId = parseInt(location.granularityId, 10);
      if (granularityId !== 1) {
        return granularityId;
      }

      const countryHasRegionsDefined = Boolean(this.locationsByIdentifier[location.key].opensignal_regions?.length);
      const geocoding =
        countryHasRegionsDefined && router.currentRoute.value.name === ROUTES.CompetitiveDetails
          ? OS_GEOCODINGS.regions
          : OS_GEOCODINGS.countries;
      return geocoding;
    },
    selectLocation(location) {
      this.update({ locationId: location.key, geocoding: this.getGeocodingForLocation(location) });
    },
    resetLocationToCurrentCountry() {
      const currentCountryId = this.locationsInfo.currentCountry.value.key;
      this.update({
        locationId: currentCountryId,
        geocoding: this.getGeocodingForLocation(currentCountryId),
      });
    },
    update({
      agg = this.queryParams.agg.selectedValue.value,
      compareTo = this.queryParams.compareTo.selectedValue.value,
      connectionCategory = this.queryParams.connectionCategory.selectedValue.value,
      endDate = this.queryParams.endDate.selectedValue.value,
      geocoding = this.queryParams.geocoding.selectedValue.value,
      locationId = this.queryParams.location.selectedValue.value,
      metric = this.queryParams.metric.selectedValue.value,
      userGroup = this.queryParams.userGroup.selectedValue.value,
    }) {
      const nextLocation = this.locations.find((l) => l.key === locationId);
      const nextCountry = this.locations.find((l) => {
        if (!nextLocation) {
          return null;
        }

        return nextLocation.iso3 === l.iso3 && parseInt(l.granularityId, 10) === OS_GEOCODINGS.countries;
      });

      const currentCountry = this.locationsInfo.currentCountry;
      const network =
        get(nextLocation, 'iso3') !== currentCountry.iso3 ? 'all' : this.queryParams.network.selectedValue.value;

      let query;
      if (router.currentRoute.value.name === ROUTES.CompetitiveConnectionCategory) {
        metric = metric.split('_')[0];

        query = {
          location: nextLocation.key,
          agg: metric.includes('coverage') ? '90days' : agg,
          compareTo,
          endDate,
          metric,
          countryid: nextCountry?.key,
          geocoding: geocoding || nextLocation.granularityId,
          network,
          userGroup,
        };
      } else if (router.currentRoute.value.name === ROUTES.CompetitiveOverview) {
        query = {
          location: nextLocation.key,
          compareTo,
          endDate,
          userGroup,
          connectionCategory,
        };
      } else if (router.currentRoute.value.name === ROUTES.CompetitiveDetails) {
        query = {
          location: nextLocation.key,
          agg: metric.includes('coverage') ? '90days' : agg,
          endDate,
          metric,
          countryid: nextCountry?.key,
          geocoding: geocoding || nextLocation.granularityId,
          network,
          userGroup,
          connectionCategory,
        };
      }

      this.$router.push({
        name: router.currentRoute.value.name,
        query,
      });

      if (this.isMobile) {
        this.toggleMenu();
      }
    },
    displayUserGroupModal() {
      this.displayModal = true;
    },
  },
  watch: {
    '$router.currentRoute.value.name'() {
      this.ensureValidConnectionCategory();
    },
    metrics() {
      this.ensureValidConnectionCategory();
    },
  },
};
</script>

<style scoped lang="scss">
@use 'foundation-sites/scss/foundation' as *;
@use 'scss/variables.module' as *;
@import 'scss/components';
@import 'scss/onx-breakpoints.module';

.CompetitiveMenu {
  background-color: var(--onx-sidebar-background);
  min-height: 100%;
  width: 100%;
  padding: $sidebar-padding;
  box-shadow:
    0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  position: relative;
  height: calc(100vh - var(--onx-navbar-height));
  overflow: auto;

  @include laptop {
    height: auto;
  }

  &__content {
    @media screen and (max-width: $ci-breakpoint-tablet) {
      flex: 1;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }

  &__nav-group {
    width: 100%;
    background: $color-white;
    border-radius: 3px;
    margin-top: 7px;
  }

  &__nav-item {
    float: left;
    width: 33.33%;
    height: 50px;

    a {
      color: $color-blue-primary;
      text-decoration: none;
      display: block;
      width: 100%;
      height: 100%;
      font-size: 12px;
      text-align: center;
    }

    &--active {
      background: #a389d4;
      border-radius: 3px;

      a {
        color: $color-white;
      }
    }
  }

  &__navItem--back {
    width: 100%;
    background: $winner-gold;
    text-align: center;
    border-radius: $border-radius-small;
    padding: 5px 0;
    cursor: pointer;
  }

  &__nav-icon {
    font-size: 18px;
    padding: 7px 0 0;
  }

  &__nav-group:after {
    display: block;
    content: '';
    clear: both;
  }

  .CompetitiveMenu__content {
    transition: $sidebar-transition;
  }
}

.ParametersLayout {
  @include xy-grid-container($padding: 0);

  &__grid {
    @include xy-grid($direction: horizontal);
    justify-content: space-between;
  }

  &__geographies {
    @include xy-cell($gutters: 0);
  }

  &__end-date {
    @include xy-cell($gutters: 0);
  }

  &__aggregation {
    @include xy-cell($gutters: 0);
  }
}

.FieldGroup__tooltipIcon {
  color: var(--charcoal-200);
  display: flex;
  align-items: center;
}

.metrics-button {
  width: 100%;
  padding: 6px 8px;
  border-radius: 4px;

  &__label {
    text-align: left;
  }

  &__icon {
    margin-left: 4px;
    transition: transition();
    transform: rotate(90deg);

    [data-popper-shown] & {
      transform: rotate(-90deg);
    }

    @include laptop {
      transform: rotate(0) !important;
    }
  }
}

.onx-single-operator-toggle {
  width: 100%;
  height: 24px;
  color: var(--charcoal-200);
}

.onx-show-confidence-toggle {
  width: 100%;
  height: 32px;
  color: $color-white;
}
</style>
