import { ComputedRef } from 'vue';

export type SharedBoundsType = {
  min: number;
  max: number;
  key: string;
};
export type SharedBoundsInjectType = ComputedRef<{
  min?: number;
  max?: number;
  addBounds: (bounds: SharedBoundsType) => void;
  removeBounds: (key: string) => void;
}>;
export const sharedBoundsKey = Symbol();
