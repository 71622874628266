<script setup lang="ts">
import { useRouter } from 'vue-router';

import RouteMatches from '@/components/RouteMatches.vue';
import OnxBreadcrumb from '@/components/onx/OnxBreadcrumbs/OnxBreadcrumb.vue';
import OnxBreadcrumbs from '@/components/onx/OnxBreadcrumbs/OnxBreadcrumbs.vue';
import HomeIcon from '@/components/onx/icons/HomeIcon.vue';

import Routes from '@/constants/routes';

const router = useRouter();

const intlSpotlightOverviewRoute = router.resolve({ name: Routes.IntlSpotlightOverview });
const intlSpotlightConnectionCategoryRoute = router.resolve({ name: Routes.IntlSpotlightByConnectionCategory });
const intlSpotlightDetailsRoute = router.resolve({ name: Routes.IntlSpotlightByGeography });
</script>

<template>
  <OnxBreadcrumbs>
    <OnxBreadcrumb :first="true" :to="intlSpotlightOverviewRoute.fullPath">
      <HomeIcon />
      <span>Overview</span>
    </OnxBreadcrumb>
    <RouteMatches :match="intlSpotlightConnectionCategoryRoute.fullPath">
      <OnxBreadcrumb>
        <span>By Connection Category</span>
      </OnxBreadcrumb>
    </RouteMatches>
    <RouteMatches :match="intlSpotlightDetailsRoute.fullPath">
      <OnxBreadcrumb>
        <span>By Geography</span>
      </OnxBreadcrumb>
    </RouteMatches>
  </OnxBreadcrumbs>
</template>
