import { DatasetTransformerFactory, DatasetTransformFilterArgs } from './datasetTransformFilterArgs';

export type OvershootingScoreTableRow = {
  operatorName: string;
  enodebid: number;
  connection_band: string;
  max_score: number;
  site_overshooting_score: number;
  site_samples: number;
};

export const prepareOvershootingScoreForTable: DatasetTransformerFactory<OvershootingScoreTableRow> = (
  filters: DatasetTransformFilterArgs,
) => {
  return (rows: any[]): OvershootingScoreTableRow[] => {
    const { operators } = filters;
    const canonical_network_ids = operators.map((operator) => operator.canonical_network_id);
    return rows
      .filter((row: any) => canonical_network_ids.includes(row.canonical_network_id))
      .map((row: any) => ({
        operatorName: operators.find((operator) => operator.canonical_network_id === row.canonical_network_id)!
          .name_mapped,
        enodebid: row.enodebid,
        connection_band: row.connection_band,
        max_score: row.max_score,
        site_overshooting_score: row.site_overshooting_score,
        site_samples: row.site_samples,
      }));
  };
};
