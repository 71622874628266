<script setup lang="ts">
import { computed } from 'vue';

import BarChartElement from '@/components/comparison/BarChartElement.vue';
import OperatorAvatar from '@/components/OperatorAvatar.vue';
import { sortByMetricMeanAndRank } from '@/utils/data';
import type { IntlSpotlightOverviewDatum } from '@/intl-spotlight/types';

import iso3to2 from '@/constants/iso3to2';

import 'flag-icons/css/flag-icons.min.css';

export type Props = {
  data: IntlSpotlightOverviewDatum[];
  min: number;
  max: number;
  biggerBetter: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  biggerBetter: true,
});

const rows = computed<Props['data']>(() => {
  if (!props.data.length) {
    return [];
  }

  return sortByMetricMeanAndRank(props.data, props.biggerBetter, 'value');
});

const chartSteps = computed(() => {
  const max = Math.ceil(props.max);
  const step = max / 4;
  return [0, step, step * 2, step * 3, max];
});
</script>

<template>
  <div class="IntlRankingTable">
    <template v-for="(row, index) in rows" :key="row.operator.name_mapped">
      <div class="IntlRankingTable__item__flag" :style="`grid-row: ${index + 1} / span 1`">
        <span class="IntlRankingTable__item__flag__label">
          <i
            :class="`fi fi-${iso3to2[row.location.iso3 as keyof typeof iso3to2].toLowerCase()}`"
            :title="row.location.name"
          >
          </i>
        </span>
      </div>
      <OperatorAvatar
        :style="`grid-row: ${index + 1} / span 1`"
        :background-color="row.operator.hex_color"
        :background-style="row.operator.is_mvno ? 'outline' : 'fill'"
        :name="row.operator.name_mapped"
      />

      <BarChartElement
        :style="`grid-row: ${index + 1} / span 1`"
        :value="row.value || 0"
        :lci="row.lci"
        :uci="row.uci"
        :min="min"
        :max="max"
        :color="row.location.country_color"
        class="IntlRankingTable__item__bar-chart"
      />

      <span class="IntlRankingTable__item__value" :style="`grid-row: ${index + 1} / span 1`">
        {{ row.value || 0 }}
      </span>
    </template>

    <div class="IntlRankingTable__chart-steps" :style="`grid-row: 1 / span ${data.length}`">
      <div
        v-for="(step, index) in chartSteps"
        :key="step"
        class="IntlRankingTable__chart-steps__step"
        :style="`left: ${index * 25}%`"
      >
        <span class="IntlRankingTable__chart-steps__step__label">
          {{ step }}
        </span>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@use 'scss/variables.module' as *;
@import 'scss/mixins';

$row-height: 28px;

.IntlRankingTable {
  display: grid;
  grid-template-columns: [flag] 24px [operator] 28px [barchart] 1fr [value] fit-content(100%);
  grid-template-rows: auto;
  grid-auto-rows: 28px;
  grid-gap: 8px;
  row-gap: 20px;
  align-items: center;

  width: 100%;
}

.IntlRankingTable__item__flag {
  width: 24px;
  color: var(--dark-grey);

  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  grid-column: flag / operator;
}

.IntlRankingTable__item__flag--draw {
  height: 100%;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 65%;
    height: calc(100% - $row-height);
    border-style: solid;
    border-color: var(--dark-grey);
    border-width: 1px 0 1px 1px;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;

    left: 50%;
  }
}

.IntlRankingTable__item__flag__label {
  // z-index and background color hide the border of IntlRankingTable__item__flag--draw:after
  // height cuts into the border to provide spacing between the border and the label
  // line-height centers the label vertically
  z-index: 1;
  background-color: var(--onx-paper-bg-color);
  height: 24px;
  line-height: 24px;
}

.IntlRankingTable__item__operator {
  grid-column: operator / barchart;
}

.IntlRankingTable__item__bar-chart {
  grid-column: barchart / value;
  z-index: 1;
}

.IntlRankingTable__item__value {
  grid-column: value;
  font-size: pxToRem(14);
}

.IntlRankingTable__chart-steps {
  grid-column: barchart / value;
  height: 100%;
  position: relative;
}

.IntlRankingTable__chart-steps__step {
  position: absolute;
  height: 100%;
  width: 1px;
  border-left: 1px dashed var(--charcoal-100);
}

.IntlRankingTable__chart-steps__step__label {
  position: absolute;
  bottom: -28px;
  transform: translateX(-50%);
  font-size: pxToRem(10);
  color: var(--charcoal-200);
}
</style>
