import { watchEffect, computed, ComputedRef } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { createNamespacedHelpers, useActions } from 'vuex-composition-helpers';

import { Dashboards } from '@/constants/dashboards';
import useDashboardInfo from '@/composables/useDashboardInfo';
import type { Country, Location } from '@/types/Location';

const useLocations = (dashboard: Dashboards) => {
  const store = useStore();
  const locationsByID = store.getters['locations/byIdentifier'] as Record<string, Location>;

  const { setAsCurrentLocation } = useActions(['setAsCurrentLocation']);
  const { useGetters: useLocationGetters } = createNamespacedHelpers('location');

  const { currentCountry, currentLocation } = useLocationGetters(['currentLocation', 'currentCountry']) as {
    currentCountry: ComputedRef<Country>;
    currentLocation: ComputedRef<Location>;
  };
  const { data: dashboardInfoResponse, isSuccess } = useDashboardInfo(dashboard);

  const route = useRoute();

  watchEffect(() => {
    if (route.query.location) {
      setAsCurrentLocation(route.query.location);
    } else if (isSuccess.value) {
      const dashboardInfoData = dashboardInfoResponse.value?.data;
      setAsCurrentLocation(dashboardInfoData?.default_country ?? dashboardInfoData?.countries_visible_full[0].id);
    }
  });

  const locationId = computed(() => {
    return parseInt(currentLocation.value?.key || (route.query.location as string), 10);
  });

  const geocoding = computed(() => {
    return parseInt(currentLocation.value?.granularityId, 10);
  });

  const countryIso3 = computed(() => {
    return currentCountry.value?.iso3;
  });

  return {
    countryIso3,
    currentCountry,
    currentLocation,
    geocoding,
    locationId,
    locationsByID,
    setAsCurrentLocation,
  };
};

export default useLocations;
