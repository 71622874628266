import { computed } from 'vue';

import useQueryParamSingleSelect from '@/composables/useQueryParamSingleSelect';
import { DeploymentTypes } from '@/constants/constants';
import {
  availableDeploymentTypes,
  availableDeploymentTypesList,
} from '@/focus/deployment-type-selector/availableDeploymentTypes';

const useDeploymentTypes = (queryParam: string) => {
  const { onChange, selectedValue } = useQueryParamSingleSelect<DeploymentTypes>(queryParam, DeploymentTypes.All);

  const selectedDeploymentType = computed(() => {
    return availableDeploymentTypes[selectedValue.value];
  });

  return {
    selectedDeploymentType,
    onDeploymentTypeChange: onChange,
    availableDeploymentTypes,
    availableDeploymentTypesList,
  };
};

export default useDeploymentTypes;
