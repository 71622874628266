import { computed, watchEffect } from 'vue';
import { useStore } from 'vuex';

import type { MetricDescriptor } from '@/types/MetricDescriptor';

import useSpotlightQueryParams from '@/components/specialized/useSpotlightQueryParams';
import useMetricConnectionCategories from '@/components/onx/composables/useMetricConnectionCategories';
import { getMetric } from '@/utils/metrics';
import metricsModule from '@/store/modules/metrics';
import createMetricKey from '@/utils/createMetricKey';

const useSpotlightPrimaryMetric = () => {
  const store = useStore();
  const metrics = computed(() => store.getters.metrics);

  const { connectionCategoriesBySubtypeAndUserGroup } = useMetricConnectionCategories();

  const queryParams = useSpotlightQueryParams();

  const primaryMetric = computed(() => {
    const metricSubtype = queryParams.metric.toValue();
    const userGroup = queryParams.userGroup.toValue();
    let connectionCategory = queryParams.connectionCategory.toValue();

    const userGroupSuffix = userGroup === 'main' ? '' : userGroup;
    const metricSubtypeAndUserGroup = metricSubtype + userGroupSuffix;
    const availableConnectionCategories =
      connectionCategoriesBySubtypeAndUserGroup.value[metricSubtypeAndUserGroup] || [];

    if (!availableConnectionCategories.includes(connectionCategory)) {
      connectionCategory = availableConnectionCategories[0];
    }

    return getMetric(metrics.value, createMetricKey(metricSubtype, userGroup, connectionCategory)) as MetricDescriptor;
  });

  // This is here to limit refactoring taking part in DCI-3805
  watchEffect(() => {
    store.commit(metricsModule.types.SET_PRIMARY, primaryMetric.value.key);
  });

  return primaryMetric;
};

export default useSpotlightPrimaryMetric;
