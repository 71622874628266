import { DatasetTransformerFactory, DatasetTransformFilterArgs } from './datasetTransformFilterArgs';
import type { SitemapperTableRow } from '@/360/base-station/table-columns/sitemapperDetailColumns';

export const prepareSitemapperDetailsForTable: DatasetTransformerFactory<SitemapperTableRow> = (
  filters: DatasetTransformFilterArgs,
) => {
  return (rows: any[]): SitemapperTableRow[] => {
    const { operators } = filters;
    const canonical_network_ids = operators.map((operator) => operator.canonical_network_id);
    return rows
      .filter((row: any) => canonical_network_ids.includes(row.canonical_network_id))
      .map((row: any) => ({
        operator: operators.find((operator) => operator.canonical_network_id === row.canonical_network_id)!,
        cell_technology: row.cell_technology,
        cell_enodebid: row.cell_enodebid,
        cell_estimatedfirstrecord: row.cell_estimatedfirstrecord,
        cell_estimatedlastrecord: row.cell_estimatedlastrecord,
        cell_estimatedlatitude: row.cell_estimatedlatitude,
        cell_estimatedlongitude: row.cell_estimatedlongitude,
        cell_estimatedband: row.cell_estimatedband,
        cell_quantifiedestimatedlocationconfidence: row.cell_quantifiedestimatedlocationconfidence,
      }));
  };
};
