import { computed, MaybeRef, unref } from 'vue';
import { useRouter, useRoute } from 'vue-router';

import { menuListItems } from '@/utils/menu';
import { metricToFullTitle } from '@/utils/titles';

import type { MetricDescriptor } from '@/types/MetricDescriptor';

import useMetrics from '@/components/onx/composables/useMetrics';

const useSpotlightMetricsSelector = (
  selectedMetric: MaybeRef<MetricDescriptor>,
  options?: {
    processMetricsFn?: (metricsBySubtype: Record<string, MetricDescriptor[]>) => void;
    exclude?: string[];
    excludeConnectionCategoryFromLabel?: boolean;
  },
) => {
  const route = useRoute();
  const router = useRouter();

  const { metricsBySubtype } = useMetrics();

  const menuItems = computed(() => {
    const _selectedMetric = unref(selectedMetric);

    const metricsGroupedBySubtypes = Object.assign({}, metricsBySubtype.value);

    Object.keys(metricsGroupedBySubtypes).forEach((groupKey) => {
      metricsGroupedBySubtypes[groupKey] = metricsGroupedBySubtypes[groupKey].filter((metric) => {
        return !_selectedMetric.subcategory || metric.subcategory === _selectedMetric.subcategory;
      });

      if (metricsGroupedBySubtypes[groupKey].length === 0) {
        delete metricsGroupedBySubtypes[groupKey];
      }
    });

    if (typeof options?.processMetricsFn === 'function') {
      options.processMetricsFn(metricsGroupedBySubtypes);
    }

    return menuListItems(metricsGroupedBySubtypes, _selectedMetric, options?.exclude);
  });

  const selectedMetricLabel = computed(() => {
    return metricToFullTitle(unref(selectedMetric), { includeTypeLabel: !options?.excludeConnectionCategoryFromLabel });
  });

  const onMetricSelect = (metric: MetricDescriptor) => {
    router.push({
      query: {
        ...route.query,
        metric: metric.subtype,
        connectionCategory: metric.type,
      },
    });
  };

  return { menuItems, onMetricSelect, selectedMetricLabel };
};

export default useSpotlightMetricsSelector;
