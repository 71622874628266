<template>
  <div class="FieldGroup">
    <div class="FieldGroup__title-container">
      <div class="FieldGroup__title-row">
        <slot name="title">
          <span>{{ label }}<span v-if="disabled"> (no other options available)</span></span>
        </slot>
        <CustomTooltip v-if="tooltip" :message="tooltip" placement="bottom">
          <InfoIcon class="FieldGroup__tooltipIcon" />
        </CustomTooltip>
      </div>
      <slot name="title-sibling" />
      <slot name="tooltip" />
    </div>
    <div class="FieldGroup__content">
      <slot />
    </div>
  </div>
</template>

<script>
import InfoIcon from '@/components/onx/icons/InfoIcon.vue';
import CustomTooltip from '@/components/tooltip/CustomTooltip.vue';

export default {
  name: 'FieldGroup',
  components: {
    CustomTooltip,
    InfoIcon,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped lang="scss">
@use 'scss/variables.module' as *;

.FieldGroup {
  $label-height: pxToRem(24px);
  position: relative;
  padding-bottom: pxToRem(12);
  width: 100%;

  &__title-container {
    display: flex;
    align-items: center;

    font-size: $font-size-12;
    font-weight: $font-weight-roboto-medium;
    color: var(--white);
    height: $label-height;
  }

  &__title-row {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &--mg {
    .FieldGroup__label {
      color: rgba($color-white, 0.7);
      margin-bottom: pxToRem(14);
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
  }
}
</style>

<style lang="scss">
.onx-icon.FieldGroup__tooltipIcon {
  color: var(--charcoal-200);

  svg {
    width: 12px;
    height: 12px;
  }
}
</style>
