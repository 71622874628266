import Base from './base';
import queryClient from '@/api/queryClient';
import osApi from '@/api/osApi';

export class Config extends Base {
  constructor(token) {
    super();
    this.token = `Bearer ${token}`;
    this.info = 'info/';
    this.metrics = 'metrics/';
    this.locations = 'locations/';
    this.user = 'user/';
    this.check = 'auth-check/';
    this.userGuide = 'user_guide/';
  }

  getDashboardInfo(dashboard = this.defaultDashboard) {
    return queryClient.fetchQuery({
      queryKey: ['dashboardInfo', dashboard],
      queryFn: () => osApi.get(`/${dashboard}/info/`),
      staleTime: 24 * 60 * 60 * 1000, // 24 hours
    });
  }

  getMetrics(dashboard = this.defaultDashboard) {
    return this.get(`${this.getApiVersion(dashboard)}${dashboard}/${this.metrics}`, this.token);
  }

  getLocations(dashboard = this.defaultDashboard, parent) {
    let url = `${this.getApiVersion(dashboard)}${dashboard}/${this.locations}`;

    if (parent) {
      url += `?parent=${parent}`;
    }

    return this.get(url, this.token)
      .then((r) => r.results)
      .then((r) =>
        r.map((location) => {
          return {
            key: `${location.id}`,
            name: location.name,
            granularity: getGroup(location),
            granularityId: `${location.geocoding_config.id}`,
            iso3: location.iso3,
            parent_id: `${location.parent_id}`,
            population_size: location.hasOwnProperty('population_size') ? `${location.population_size}` : null,
            country_color: location.country_color,
          };
        }),
      );
  }

  getUser() {
    return this.get(`${this.v1}${this.user}`, this.token);
  }

  getUserGuide(dashboard = this.defaultDashboard) {
    return this.getBlob(`${this.v1}${dashboard}/${this.userGuide}`, this.token);
  }
}

function getGroup(location) {
  return location.geocoding_config.client + '_' + location.geocoding_config.granularity;
}
