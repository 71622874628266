<script setup lang="ts">
import { computed } from 'vue';
import { useRoute, RouterLink } from 'vue-router';
import groupBy from 'lodash/groupBy';

import useGeocodingConfig from '@/composables/useGeocodingConfig';
import useCurrentDashboardName from '@/composables/useCurrentDashboardName';
import useSpotlightQueryParams from '@/components/specialized/useSpotlightQueryParams';
import useEndDate from '@/composables/useEndDate';
import useMetrics from '@/components/onx/composables/useMetrics';
import useLocations from '@/composables/useLocations';
import useDashboardInfo from '@/composables/useDashboardInfo';

import type { MetricDescriptor } from '@/types/MetricDescriptor';
import type { MetricStructuresEnum } from '@/types/MetricStructures';
import type { IntlSpotlightOverviewDatum } from '@/intl-spotlight/types';

import {
  OS_GEOCODINGS,
  SPOTLIGHT_OVERVIEW_TILE_ORDER_BY_CONNECTION_CATEGORY,
  SpotlightOverviewTileOrderIndexType,
} from '@/constants/constants';
import { TOOLTIP_MESSAGES } from '@/constants/tooltips';
import ROUTES from '@/constants/routes';

import { metricToFullTitle } from '@/utils/titles';
import { getDatumValue } from '@/utils/viewHelpers';
import { fixHexColor } from '@/utils/helpers';
import getMetricUnitLabel from '@/utils/getMetricUnitLabel';

import IntlSpotlightOverviewTile from '@/intl-spotlight/overview-tiles/IntlSpotlightOverviewTile.vue';
import OnxMetricIcon from '@/components/onx/icons/metrics/OnxMetricIcon.vue';
import OnxHeadline from '@/components/onx/typography/OnxHeadline.vue';
import CustomTooltip from '@/components/tooltip/CustomTooltip.vue';
import ChevronRightIcon from '@/components/onx/icons/ChevronRightIcon.vue';
import LoaderGrid from '@/components/LoaderGrid.vue';

const dashboard = useCurrentDashboardName();
const { agg, metric, userGroup } = useSpotlightQueryParams({
  agg: '90days',
});

const { metrics, metricsByIdentifier } = useMetrics();

const availableConnectionCategories = computed(() => {
  if (!metrics.value?.length) {
    return [];
  }

  return metrics.value.filter((m) => m.key.startsWith(metric.toValue())).map((m) => m.type);
});
const { homeNetworks } = useDashboardInfo(dashboard.value);

const { locationsByID } = useLocations(dashboard.value);

const { currentEndDate } = useEndDate(dashboard.value);

const route = useRoute();

const parsedUserGroup = computed(() => {
  if (userGroup.toValue() === 'main') {
    return '';
  }

  return userGroup.toValue();
});

// Create a list of metric keys to request from the API
const metricsToRequest = computed(() => {
  const metrics = availableConnectionCategories.value.map((connectionCategory) => {
    return `${metric.toValue()}${parsedUserGroup.value}_${connectionCategory}`;
  });

  return metrics.join(',');
});

const {
  data: geocodingConfigResponse,
  isLoading,
  isPending,
  isRefetching,
} = useGeocodingConfig<MetricStructuresEnum.RankedSimple>(dashboard.value, {
  metric: metricsToRequest,
  geocoding: OS_GEOCODINGS.countries,
  aggregation: agg.selectedValue,
  endDate: currentEndDate,
  operatorInfo: true,
});

const countryDataByMetric = computed(() => {
  if (!geocodingConfigResponse.value) {
    return null;
  }

  const homeNetworkIds = computed(() => homeNetworks.value.map((n) => n.canonical_network_id));
  const homeNetworkData = geocodingConfigResponse.value.data.results.filter((d) =>
    homeNetworkIds.value.includes(d.canonical_network_id),
  );

  return groupBy(homeNetworkData, 'metric');
});

const tiles = computed(() => {
  if (!countryDataByMetric.value || !metricsByIdentifier.value) {
    return [];
  }

  return Object.entries(countryDataByMetric.value)
    .map(([metric, data]) => {
      const metricDescriptor = metricsByIdentifier.value[metric] as MetricDescriptor;
      const dataWithOperatorAndLocationInfo = data
        .map((d) => {
          const operator = geocodingConfigResponse.value?.data.operators?.[d.canonical_network_id];

          if (!operator) {
            return null;
          }

          return {
            ...d,
            value: getDatumValue(d),
            operator: {
              ...operator,
              hex_color: fixHexColor(operator.hex_color),
            },
            location: locationsByID[d.location.toString()],
          };
        })
        .filter((datum): datum is IntlSpotlightOverviewDatum => Boolean(datum));

      return {
        metric: metricDescriptor,
        data: dataWithOperatorAndLocationInfo,
        title: metricToFullTitle(metricDescriptor),
        tooltipMessage: getTooltipMessage(metricDescriptor),
      };
    })
    .sort((a, b) => {
      return (
        SPOTLIGHT_OVERVIEW_TILE_ORDER_BY_CONNECTION_CATEGORY[a.metric.type as SpotlightOverviewTileOrderIndexType] -
        SPOTLIGHT_OVERVIEW_TILE_ORDER_BY_CONNECTION_CATEGORY[b.metric.type as SpotlightOverviewTileOrderIndexType]
      );
    });
});

const getTooltipMessage = (metric: MetricDescriptor) => {
  return TOOLTIP_MESSAGES[`spotlight_${metric.kind}_${metric.type}` as keyof typeof TOOLTIP_MESSAGES];
};
</script>

<template>
  <div class="intl-spotlight-by-connection-category onx-grid fluid" v-if="!isLoading && !isRefetching && !isPending">
    <IntlSpotlightOverviewTile v-for="tile in tiles" :key="tile.metric.key" :metric="tile.metric" :data="tile.data">
      <template #header>
        <div class="onx-metric-box__header__title-container">
          <CustomTooltip :message="tile.tooltipMessage" placement="top">
            <OnxMetricIcon :metric-kind="tile.metric.kind" class="Card__header__metric-icon" />
          </CustomTooltip>

          <div class="Card__headerTitle">
            <OnxHeadline as="h4">
              {{ tile.title }}
            </OnxHeadline>
          </div>
        </div>

        <div class="onx-metric-box__unit">
          {{ getMetricUnitLabel(tile.metric) }}
        </div>
      </template>

      <template #footer>
        <RouterLink
          :to="{
            name: ROUTES.IntlSpotlightByGeography,
            query: { ...route.query, metric: tile.metric.subtype, connectionCategory: tile.metric.type },
          }"
          :data-test-id="`intl-spotlight-overview_${tile.metric.kind}-box_by-geography-btn`"
          class="onx-button onx-button--tertiary onx-button--sm"
        >
          <span>By geography</span>
          <ChevronRightIcon small />
        </RouterLink>
      </template>
    </IntlSpotlightOverviewTile>
  </div>
  <LoaderGrid v-else />
</template>

<style lang="scss">
@use 'scss/onx-breakpoints.module' as breakpoints;
.intl-spotlight-by-connection-category {
  padding: 8px;

  @include breakpoints.tablet {
    padding: 16px;
  }
  .onx-metric-box__unit {
    text-align: right;
  }
}
</style>
