<script setup lang="ts">
import { computed, provide, ref } from 'vue';
import { SharedBoundsInjectType, sharedBoundsKey, SharedBoundsType } from '.';

type Props = {
  defaultMin?: number;
  defaultMax?: number;
};

const props = defineProps<Props>();
const sharedBounds = ref<SharedBoundsType[]>([]);
const addBounds = (bounds: SharedBoundsType) => {
  const existingBounds = sharedBounds.value.filter((b) => b.key !== bounds.key);
  sharedBounds.value = [...existingBounds, bounds];
};
const removeBounds = (key: string) => {
  sharedBounds.value = sharedBounds.value.filter((b) => b.key !== key);
};

provide<SharedBoundsInjectType>(
  sharedBoundsKey,
  computed(() => {
    const minBound = Math.min(
      ...sharedBounds.value
        .filter((b) => {
          return !isNaN(b.min);
        })
        .map((b) => b.min),
    );
    const maxBound = Math.max(
      ...sharedBounds.value
        .filter((b) => {
          return !isNaN(b.max);
        })
        .map((b) => b.max),
    );

    return {
      min: isNaN(minBound) || !isFinite(minBound) ? props.defaultMin : minBound,
      max: isNaN(maxBound) || !isFinite(maxBound) ? props.defaultMax : maxBound,
      addBounds,
      removeBounds,
    };
  }),
);
</script>
<template>
  <slot></slot>
</template>
