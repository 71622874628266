import { add } from 'date-fns';

import type {
  MetricsAPIDataTransformerConstructor,
  DataTransformerFnOptions,
  DataTransformerReturnedItem,
} from '@/chart-metric-definitions/data-transformers/DataTransformerFnType';
import type { MetricStructuresEnum } from '@/types/MetricStructures';

const formatDate = (dateString: string, hour: number) => {
  const date = add(new Date(dateString), { hours: hour });
  const isoDate = date.toISOString();

  return `${isoDate.substring(0, 10)} ${isoDate.substring(11, 19)}`;
};

const transformHourlyMetricForEachOperator: MetricsAPIDataTransformerConstructor<
  MetricStructuresEnum.Breakdown,
  Required<Pick<DataTransformerFnOptions, 'operators'>>
> =
  ({ operators }) =>
  (response, horizontal?: boolean) => {
    const dataAxis = horizontal ? 'x' : 'y';
    const labelAxis = horizontal ? 'y' : 'x';

    const metricsFilteredByOperator = response.results.filter((datum) => {
      return operators.some((operator) => operator.canonical_network_id === datum.canonical_network_id);
    });

    if (metricsFilteredByOperator.length === 0) {
      return [];
    }

    const hours = [...Array(24).keys()]; // [0, 1, ... 23]

    const datasets = operators.reduce(
      (acc, operator) => {
        const color = `#${operator.hex_color}`;

        acc[operator.canonical_network_id] = {
          type: 'line',
          label: operator.name_mapped,
          backgroundColor: color,
          color,
          data: [],
          dataAxis,
          labelAxis,
        };

        return acc;
      },
      {} as Record<string, DataTransformerReturnedItem>,
    );

    metricsFilteredByOperator.forEach((datum) => {
      const operator = operators.find((operator) => operator.canonical_network_id === datum.canonical_network_id);

      if (!operator) {
        return;
      }

      datasets[operator.canonical_network_id].data = hours.map((hour) => {
        return {
          ...datum,
          [dataAxis]: datum.mean[hour],
          [labelAxis]: formatDate(datum.date, hour),
          lci: datum.lci?.[hour],
          uci: datum.uci?.[hour],
        };
      });
    });

    return Object.values(datasets);
  };

export default transformHourlyMetricForEachOperator;
