import chroma from 'chroma-js';

import type {
  DataTransformerFnOptions,
  MetricsAPIDataTransformerConstructor,
} from '@/chart-metric-definitions/data-transformers/DataTransformerFnType';
import { cdnColorMap } from '@/constants/colorScales';
import { CDN_LABELS } from '@/constants/constants';
import type { CdnIpMetricData, MetricStructuresEnum } from '@/types/MetricStructures';
import { stringToColour } from '@/utils/colors';
import { getDateFormatter } from '@/utils/date';

const transformCdnIP360WeeklyStackPerOperator =
  (
    valueAccessor: keyof CdnIpMetricData,
    secondaryValueAccessor?: keyof CdnIpMetricData,
  ): MetricsAPIDataTransformerConstructor<
    MetricStructuresEnum.CdnIp,
    Required<Pick<DataTransformerFnOptions, 'mainOperator'>>
  > =>
  ({ mainOperator }) =>
  (response) => {
    const dataFilteredByMainOperator = response.results.filter((datum) => {
      return datum.canonical_network_id === mainOperator.canonical_network_id;
    });

    const dateFormatter = getDateFormatter('MMM d, yyyy');

    const data = dataFilteredByMainOperator.flatMap((datum) => {
      const cdns = Object.keys(datum.percentage);

      const results: any[] = [];

      cdns.map((cdn) => {
        const ips = Object.keys(datum.percentage[cdn] || []);
        ips.map((ip) => {
          results.push({
            ...datum,
            x: dateFormatter(datum.date),
            y: datum[valueAccessor][cdn][ip],
            cdn,
            label: `${ip} (${CDN_LABELS[cdn as keyof typeof CDN_LABELS]})`,
            ip,
            secondaryValue: secondaryValueAccessor ? datum[secondaryValueAccessor][cdn][ip] : undefined,
          });
        });
      });

      return results;
    });

    const uniqueCdnColors = [...new Set(data.map((datum) => cdnColorMap[datum.cdn as keyof typeof cdnColorMap]))];
    const uniqueLabels = [...new Set(data.map((datum) => datum.label))];
    const colors = chroma.scale(uniqueCdnColors).colors(uniqueLabels.length);

    const dataGroupedByIp = data.reduce(
      (acc, item) => {
        if (!acc[item.label]) {
          const color = colors.shift();

          acc[item.label] = {
            label: item.label,
            color,
            backgroundColor: 'white',
            data: [],
          };
        }

        acc[item.label].data.push(item);
        acc[item.label].backgroundColor = stringToColour(item.label);
        return acc;
      },
      {} as { [ip: string]: any },
    );

    return Object.values(dataGroupedByIp);
  };

export default transformCdnIP360WeeklyStackPerOperator;
