import { MetricDescriptor } from '@/types/MetricDescriptor';

const getMetricUnitLabel = (metric: MetricDescriptor) => {
  if (['ccq', 'ecq', 'consistentquality'].includes(metric.kind)) {
    return '% of tests';
  }

  if (!metric.units?.short) return '';

  switch (metric.units?.short) {
    case '0 - 100':
      return 'Points (0 - 100)';
    case '0 - 10':
      return 'Points (0 - 10)';
    case '100 - 1000':
      return 'Points (100 - 1000)';
    case '%':
      return '% of time';
    default:
      return metric.units?.short;
  }
};

export default getMetricUnitLabel;
