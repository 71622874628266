<template>
  <div
    :style="componentStyle"
    class="onx-operator-avatar"
    :class="{ 'onx-operator-avatar--sm': size === 'sm' }"
    :title="name"
  >
    {{ name[0] }}
  </div>
</template>

<script>
export default {
  name: 'OperatorAvatar',
  props: {
    name: { type: String, required: true },
    backgroundColor: { type: String, required: true },
    backgroundStyle: {
      type: String,
      default: 'fill',
      validator(value) {
        return ['fill', 'outline'].includes(value);
      },
    },
    size: {
      type: String,
      default: null,
      validator(value) {
        return ['sm'].includes(value);
      },
    },
  },
  computed: {
    componentStyle() {
      if (this.backgroundStyle === 'outline') {
        return {
          border: `4px solid ${this.backgroundColor}`,
          color: 'black',
        };
      }

      return {
        backgroundColor: this.backgroundColor,
        color: 'white',
      };
    },
  },
};
</script>

<style scoped lang="scss">
.onx-operator-avatar {
  border-radius: 50%;

  height: 28px;
  width: 28px;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  font-weight: 700;
  font-size: 14px;
}

.onx-operator-avatar--sm {
  width: 16px;
  height: 16px;
  line-height: 16px;
  font-size: 10px;
  margin: 0 4px;
}
</style>
